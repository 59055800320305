import { FetchResult, OperationVariables, useMutation } from '@apollo/client';
import CreateIssueButton from 'components/CreateIssueButton';
import { SelectButtonOption } from 'components/SelectButton';
import {
  CREATE_NEW_ISSUE,
  INTERNAL_UPLOAD_PUBLIC_POLICY_FILE,
} from 'graphql/publicHealth/internal/mutations';
import { GET_PUBLIC_POLICY } from 'graphql/publicHealth/internal/queries';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import { ArchivePolicy } from 'pages/policies/template/ArchivePolicy';
import {
  ARCHIVE_POLICY,
  UNARCHIVE_POLICY,
} from 'pages/policies/template/mutations';
import { mapInternalPublicHealthStatus } from 'shared/mapInternalPublicHealthStatus';
import { alertBanner } from 'shared/reactiveVariables';

import ChangeStatus from '../ChangeStatus';
import * as styles from './styles';

interface PolicyActionsProps {
  policy?: PublicHealthPolicy;
}

const PolicyActions = ({ policy }: PolicyActionsProps) => {
  const status = mapInternalPublicHealthStatus(policy?.publicStatus ?? '');

  const onArchiveOrUnarchiveComplete = (isArchived: boolean) => {
    const message = isArchived
      ? 'Policy successfully archived.'
      : 'Policy successfully unarchived.';
    alertBanner({ type: 'SUCCESS', message });
  };
  const onArchiveOrUnarchiveError = () => {
    alertBanner({
      type: 'WARNING',
      message: 'Something went wrong. Please try again.',
    });
  };

  const [archivePolicy] = useMutation(ARCHIVE_POLICY, {
    variables: {
      insuranceType: 'PUBLIC_HEALTH',
      id: policy?.id,
    },
    refetchQueries: [GET_PUBLIC_POLICY],
    errorPolicy: 'none',
    onCompleted: () => onArchiveOrUnarchiveComplete(true),
    onError: onArchiveOrUnarchiveError,
  });

  const [unarchivePolicy] = useMutation(UNARCHIVE_POLICY, {
    variables: {
      insuranceType: 'PUBLIC_HEALTH',
      id: policy?.id,
    },
    refetchQueries: [GET_PUBLIC_POLICY],
    errorPolicy: 'none',
    onCompleted: () => onArchiveOrUnarchiveComplete(false),
    onError: onArchiveOrUnarchiveError,
  });

  const issueTypeOptions: SelectButtonOption[] = [
    {
      title: 'Unicheck',
      label: 'Unicheck',
      id: 'unicheck',
    },
    {
      title: 'Missing information',
      label: 'Missing information',
      id: 'missinginfo',
    },
    {
      title: 'Canceled',
      label: 'Canceled',
      id: 'canceled',
    },
    {
      title: 'Not eligible',
      label: 'Not eligible',
      id: 'noteligible',
    },
    {
      title: 'Processing',
      label: 'Processing',
      id: 'processing',
    },
    {
      title: 'Other',
      label: 'Other',
      id: 'other',
    },
  ];

  const [createIssue, { loading, error, data }] = useMutation(
    CREATE_NEW_ISSUE,
    {
      refetchQueries: [GET_PUBLIC_POLICY],
    }
  );
  const [uploadFile, { loading: loadingFile, error: errorFileUpload }] =
    useMutation(INTERNAL_UPLOAD_PUBLIC_POLICY_FILE);

  const createIssueWithDocument = async (
    variables: OperationVariables
  ): Promise<FetchResult<any>> => {
    if (variables.file) {
      return uploadFile({
        variables: {
          policyId: variables.userPolicyId,
          ...variables,
        },
      }).then((result) => {
        return createIssue({
          variables: {
            ...variables,
            documentId: result.data.uploadPublicHealthPolicyFile.id,
          },
        });
      });
    }
    return createIssue({
      variables: {
        ...variables,
      },
    });
  };

  if (!policy) return <></>;

  return (
    <div className="flex flex-col items-start space-y-2 md:flex-row md:items-center md:space-x-2 md:space-y-0">
      <div className={styles.status}>
        <p className={styles.statusText}>Status</p>
        <ChangeStatus
          id={policy.id}
          status={status}
          defaultStartDate={policy.startDate ?? ''}
          confirmationOfCoverage={policy.confirmationOfCoverageUrl}
          openIssues={policy.openIssues}
        />
      </div>
      <div>
        <CreateIssueButton
          issueTypeOptions={issueTypeOptions}
          policyId={policy.id}
          providerView={false}
          loading={loading || loadingFile}
          error={!!error || !!errorFileUpload}
          data={!!data}
          createIssue={createIssueWithDocument}
        />
      </div>
      <ArchivePolicy
        onArchive={archivePolicy}
        onUnarchive={unarchivePolicy}
        shouldArchivePolicy={Boolean(!policy?.archivedAt)}
        collapsedButton
      />
    </div>
  );
};

export default PolicyActions;
