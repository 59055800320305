import { GenericClaimType } from './models';

export const claimTypeNameMapper: Record<GenericClaimType, string> = {
  AMBULANCE: 'Ambulance',
  HOSPITAL_OVERNIGHT: 'Hospital overnight',
  MEDICATION: 'Medication',
  DOCTORS_VISIT: "Doctor's visit",
  SURGERY: 'Surgery',
  PREVENTATIVE_TREATMENT: 'Preventative treatment',
  VETERINARY_VISITS: 'Veterinary visits',
  OTHER: 'Other',
};
