import { useMutation } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import { SelectMenuOption } from 'components/SelectMenu';
import { DocumentNode } from 'graphql';
import { useState } from 'react';
import { alertBanner } from 'shared/reactiveVariables';

import FileUploadForm from './FileUploadForm';

interface FileUploadProps {
  fileTypeOptions: SelectMenuOption[];
  mutation: DocumentNode;
  policyId?: string;
  getPolicyMutation: DocumentNode;
  extraVariables?: Record<string, string>;
}

// TODO: [KONG] Refactor collecting variables
const FileUpload = ({
  fileTypeOptions,
  mutation,
  policyId,
  getPolicyMutation,
  extraVariables,
}: FileUploadProps) => {
  const { t } = useTranslation();

  const handleCompletedUploadFile = () => {
    setOpenForm(false);
    alertBanner({
      type: 'SUCCESS',
      message: t(
        'admin.alert.documentstatus.success.uploaded.header',
        'Document successfully uploaded.'
      ),
    });
  };
  const [uploadFile, { loading, error, reset }] = useMutation(mutation, {
    refetchQueries: [getPolicyMutation],
    onCompleted: handleCompletedUploadFile,
  });

  const [openForm, setOpenForm] = useState(false);

  const handleUploadFile = (variables: {
    type: string;
    newFilename?: string;
    file: File;
  }) => {
    uploadFile({ variables: { policyId, ...variables, ...extraVariables } });
  };

  return (
    <>
      <Button
        buttonType="secondary"
        onClick={() => setOpenForm(true)}
        className="w-[88px]"
      >
        {t('admin.documents.upload', 'Upload')}
      </Button>
      {openForm && (
        <FileUploadForm
          open={openForm}
          setOpen={setOpenForm}
          fileTypeOptions={fileTypeOptions}
          uploadFile={handleUploadFile}
          loading={loading}
          error={error}
          reset={reset}
        />
      )}
    </>
  );
};

export default FileUpload;
