import bikeIcon from './icons/bike-icon.svg';
import carIcon from './icons/car-icon.svg';
import dentalIcon from './icons/dental-icon.svg';
import disabilityIcon from './icons/disability-icon.svg';
import dogLiabilityIcon from './icons/dog-liability-icon.svg';
import expatHealthIcon from './icons/expat-health-icon.svg';
// TODO: [KONG] Replace with generic icon
import healthIcon from './icons/health-icon.svg';
import householdIcon from './icons/household-icon.svg';
import legalIcon from './icons/legal-icon.svg';
import lifeIcon from './icons/life-icon.svg';
import pensionIcon from './icons/pension-icon.svg';
import personalLiabilityIcon from './icons/personal-liability-icon.svg';
import petHealthIcon from './icons/pet-health-icon.svg';
import privateHealthIcon from './icons/private-health-icon.svg';
import professionalITIcon from './icons/professional-it-icon.svg';
import publicHealthIcon from './icons/public-health-icon.svg';
import travelIcon from './icons/travel-icon.svg';
import { InsuranceType } from './types';

export const insuranceIconMapper: Record<InsuranceType, string> = {
  PUBLIC_HEALTH: publicHealthIcon,
  PRIVATE_HEALTH: privateHealthIcon,
  INCOMING: expatHealthIcon,
  BIKE: bikeIcon,
  CAR: carIcon,
  DENTAL: dentalIcon,
  DISABILITY: disabilityIcon,
  DOG_LIABILITY: dogLiabilityIcon,
  HOUSEHOLD: householdIcon,
  LEGAL: legalIcon,
  LIFE: lifeIcon,
  LIABILITY: personalLiabilityIcon,
  PET_HEALTH: petHealthIcon,
  TRAVEL: travelIcon,
  TRAVEL_FR: travelIcon,
  JOB: professionalITIcon,
  GENERIC: healthIcon,
  COMPANY_HEALTH: healthIcon,
  COMPANY_LIFE: healthIcon,
  PENSION: pensionIcon,
  BASIS_PENSION: pensionIcon,
  INCOMING_ES: expatHealthIcon,
  INCOMING_EU: expatHealthIcon,
  COMPANY_PENSION: pensionIcon,
};
