import {
  DocumentNode,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { BadgeColor } from 'components/Badge/Badge';
import SelectButton, { SelectButtonOption } from 'components/SelectButton';
import { StatusUpdateModal } from 'components/StatusUpdateModal';
import {
  StatusUpdateFormDependencies,
  StatusUpdateFormQuestion,
} from 'components/StatusUpdateModal/models';
import { retrieveValidationByType } from 'components/StatusUpdateModal/utils';
import { Issue } from 'models/issue';
import { useState } from 'react';
import { alertBanner } from 'shared/reactiveVariables';
import { z } from 'zod';

interface ChangeStatusProps {
  status: SelectButtonOption<string>;
  openIssues?: Issue[];
  policyId: string;
  statusOptions: SelectButtonOption<string>[];
  modals: { [key: string]: StatusChangeModalData };
  mutation: DocumentNode;
  mutationOperationVariables: MutationHookOptions;
  statusChangeOptions: { [key: string]: string[] };
  mutationVariables?: OperationVariables;
  extendedValidatorObject?: { [key: string]: z.ZodLiteral<any> };
  retrieveModalId?: (currentStatus: string, newStatus: string) => string;
}

export interface StatusChangeModalData {
  showOpenIssues: boolean;
  formFields: StatusUpdateFormQuestion[];
  bottomDescription?: string;
  bottomDescriptionDependencies?: StatusUpdateFormDependencies[];
}

const ChangeStatus = ({
  status,
  openIssues,
  policyId,
  statusOptions,
  modals,
  mutation,
  mutationOperationVariables,
  statusChangeOptions,
  mutationVariables,
  extendedValidatorObject,
  retrieveModalId,
}: ChangeStatusProps) => {
  const [form, setForm] = useState(false);
  const [newStatus, setNewStatus] = useState<
    SelectButtonOption<string> | undefined
  >(undefined);
  const [modalData, setModalData] = useState<StatusChangeModalData | null>(
    null
  );

  const [updateStatus, { loading }] = useMutation(mutation, {
    onCompleted: () => {
      setForm(false);
      alertBanner({
        type: 'SUCCESS',
        message: 'Status successfully updated.',
      });
    },
    onError: () => {
      alertBanner({
        type: 'WARNING',
        message: 'Something went wrong. Please try again.',
      });
    },
    ...mutationOperationVariables,
  });

  const currentStatus =
    statusOptions.find((element) => element.id === status.id) ??
    statusOptions[0];

  const currentStatusChanges = statusChangeOptions[
    status.id.toUpperCase()
  ]?.map(
    (statusOption) =>
      statusOptions.find((option) => option.id === statusOption) ??
      statusOptions[0]
  );

  const handleStatusChange = (option: SelectButtonOption<string>) => {
    if (option.id === status.id) {
      return;
    }

    setNewStatus(option);

    const modalKey = modals[option.id]
      ? option.id
      : retrieveModalId
      ? retrieveModalId(option.id, status.id)
      : null;

    if (modalKey && modals[modalKey]) {
      setModalData(modals[modalKey]);
    } else {
      setModalData({
        showOpenIssues: false,
        formFields: [],
        bottomDescription:
          'We are not collecting any additional information for this status change.',
      });
    }

    setForm(true);
  };

  const handleUpdateStatus = (form?: Record<string, any>) => {
    let issueIds;
    if (modalData?.showOpenIssues && openIssues && openIssues.length !== 0) {
      issueIds = openIssues.map((issue) => issue.id);
    }
    updateStatus({
      variables: {
        ...mutationVariables,
        ...form,
        status: newStatus?.id,
        id: policyId,
        issueIds,
      },
    });
  };

  const validatorObject = modalData?.formFields.reduce(
    (acc, { id, required, data: { type } }) => {
      const validation = retrieveValidationByType(type);
      return {
        ...acc,
        ...(validation && {
          [id]: required ? validation : validation?.optional(),
        }),
      };
    },
    {}
  );

  const validationSchema = z.object({
    ...validatorObject,
    ...extendedValidatorObject,
  });

  return (
    <>
      <h4 className="mr-[8px] text-gray-500">Status</h4>
      {form && (
        <StatusUpdateModal
          isOpen={form}
          setOpen={setForm}
          onSubmit={handleUpdateStatus}
          loading={loading}
          showIssuesSection={modalData?.showOpenIssues ?? false}
          form={modalData?.formFields ?? []}
          openIssues={openIssues}
          bottomText={modalData?.bottomDescription}
          bottomTextDependencies={modalData?.bottomDescriptionDependencies}
          status={newStatus}
          validationSchema={validationSchema}
        />
      )}
      <SelectButton
        withDot={true}
        options={currentStatusChanges}
        selected={currentStatus}
        label="Status"
        handleOnChange={handleStatusChange}
        disabled={currentStatusChanges.length === 0}
      />
    </>
  );
};

export default ChangeStatus;
