const auth = {
  path: '/',
  logOut: {
    path: '/logout',
  },
  loginSuccess: {
    path: '/login/redirect/success',
  },
  google: {
    loginFail: {
      path: '/google/redirect/failure',
    },
  },
};

const customers = {
  path: '/internal/customers',
  details: {
    path: '/internal/customers/:id',
  },
};

const policies = {
  publicHealth: {
    internal: {
      path: '/de/internal/public-health/policies',
      details: {
        path: '/de/internal/public-health/policies/:id',
      },
      issues: {
        path: '/de/internal/public-health/issues',
      },
    },
    provider: {
      path: '/de/:provider/public-health/policies',
      details: {
        path: '/de/:provider/public-health/policies/:id',
      },
    },
  },
  privateHealth: {
    internal: {
      path: '/de/internal/private-health/policies',
      details: {
        path: '/de/internal/private-health/policies/:id',
      },
    },
  },
  expatHealth: {
    internal: {
      path: '/de/internal/expat-health/policies',
      details: {
        path: '/de/internal/expat-health/policies/:id',
      },
    },
  },
  internal: {
    path: '/:country/internal/:insuranceType/policies',
    details: {
      path: '/:country/internal/:insuranceType/policies/:id',
    },
  },
  hr: {
    path: '/:country/hr/:company/:insuranceType/policies',
    details: {
      path: '/:country/hr/:company/:insuranceType/policies/:id',
    },
  },
};

const hr = {
  publicHealth: {
    provider: {
      policies: {
        path: '/:company/hr/policies',
      },
    },
  },
};

const claims = {
  internal: {
    path: '/:country/internal/:insurancePathName/claims',
    details: {
      path: '/:country/internal/:insurancePathName/claims/:id',
    },
  },
};

const companies = {
  internal: {
    path: '/internal/companies',
    details: {
      path: '/internal/companies/:id',
    },
  },
};

const adminUsers = {
  internal: {
    path: '/internal/admin-users',
    details: {
      path: '/internal/admin-users/:id',
    },
  },
};

const scannedDocuments = {
  path: '/internal/scanned-documents',
  details: {
    path: '/internal/scanned-documents/:id',
  },
  categories: {
    path: '/internal/sd-categories',
    details: {
      path: '/internal/sd-categories/:id',
    },
  },
};

const payments = {
  path: '/internal/:insuranceType/payment-transactions',
};

const taskTemplates = {
  path: '/internal/task-templates',
};

const questionnaires = {
  path: '/internal/questionnaires',
  details: {
    path: '/internal/questionnaires/:id',
  },
};

const tkApiRequests = {
  path: '/internal/tk-api-requests',
  details: { path: '/internal/tk-api-requests/:id' },
};

const dataRemovalRequests = {
  path: '/internal/data-removal-requests',
};

export const paths = {
  auth,
  customers,
  policies,
  claims,
  hr,
  companies,
  adminUsers,
  scannedDocuments,
  payments,
  taskTemplates,
  questionnaires,
  tkApiRequests,
  dataRemovalRequests,
};
