import { TransactionInsuranceType } from '../models/TransactionInsuranceType';

export const getInsuranceTypePathName = (
  insuranceType: TransactionInsuranceType
) => {
  switch (insuranceType) {
    case 'DENTAL':
      return 'dental';
    case 'INCOMING':
      return 'expat-health';
  }
};
