import { getFixedT } from '@getpopsure/i18n-react';
import { OtherIncome } from 'models/OtherIcomeType';

export const mapOtherIncomeType = (type: OtherIncome): string => {
  const t = getFixedT();
  switch (type) {
    case 'CAPITAL_GAIN':
      return t(
        'admin.provider.policy.public.questionaire.otherincome.capitalgain.label',
        'Capital gain'
      );
    case 'RENTING':
      return t(
        'admin.provider.policy.public.questionaire.otherincome.renting.label',
        'Renting'
      );
    case 'OTHER_EMPLOYMENT':
      return t(
        'admin.provider.policy.public.questionaire.otherincome.otheremployment.label',
        'Other employment'
      );
    case 'PENSION':
      return t(
        'admin.provider.policy.public.questionaire.otherincome.pension.label',
        'Pension'
      );
    case 'OTHER':
      return t(
        'admin.provider.policy.public.questionaire.otherincome.other.label',
        'Other'
      );
  }
};
