import { gql } from '@apollo/client';

export const PUBLIC_QUESTIONNAIRE_FIELDS = gql`
  fragment PublicQuestionnaireAnswerFields on PublicHealthAnswers {
    firstName
    lastName
    email
    gender
    address {
      city
      street
      country
      postcode
      houseNumber
      additionalInformation
    }
    occupation
    university {
      name
      isListed
    }
    dateOfBirth
    nationality {
      name
      code
    }
    placeOfBirth {
      place
      country {
        name
        code
      }
    }
    lastNameAtBirth
    receivesPension
    hasAddressInGermany
    universityInGermany
    universityStartDate
    hasDependentChildren
    hasDependentsToCover
    worksMoreThanTwentyHours
    previousCountryOfInsurance {
      name
      code
    }
    salary
    jobTitle
    employerName
    jobStartDate
    companyAddress {
      city
      street
      country
      postcode
      houseNumber
      additionalInformation
    }
    firstTimeEmployedInGermany
    hasTaxNumber
    hasOtherIncome
    coverageStartDate
    selfEmploymentStartDate
    agenturFurArbeitBenefits
    monthlyIncomeForNextYear
    previousHealthInsuranceType
    exemptedFromPensionInsurance
    exemptedFromLongTermSocialInsurance
    unknownKeys
    typeOfPreviousGermanHealthInsurance
    previousInsurer
    otherIncomeMonthlyRevenue
    otherIncome
    otherSituation
  }
`;
