import { useMutation } from '@apollo/client';
import Button from 'components/Button';
import { CreateIssueModal } from 'components/Issues/IssuesTab/components/CreateIssueModal';
import { PrivateHealthPolicy } from 'models/privateHealthPolicy';
import Page404 from 'pages/errors/404';
import { GET_PRIVATE_POLICY } from 'pages/policies/privateHealth/graphql/queries';
import { ArchivePolicy } from 'pages/policies/template/ArchivePolicy';
import {
  ARCHIVE_POLICY,
  UNARCHIVE_POLICY,
} from 'pages/policies/template/mutations';
import { useState } from 'react';
import { PrivateHealthStatus } from 'shared/insurances/types';
import { privateHealthIssueCategoryMapper } from 'shared/issues/privateHealth/issueCategoryMapper';
import {
  privateHealthIssueCategories,
  PrivateHealthIssueCategory,
} from 'shared/issues/privateHealth/models';
import { alertBanner } from 'shared/reactiveVariables';

import { CREATE_PRIVATE_HEALTH_ISSUE } from '../../graphql/issues';
import { ChangeStatusV2 } from '../ChangeStatusV2';

interface PolicyActionProps {
  policy?: PrivateHealthPolicy;
}

const PolicyActions = ({ policy }: PolicyActionProps) => {
  const [openCreateIssueModal, setOpenCreateIssueModal] = useState(false);

  const onArchiveOrUnarchiveComplete = (isArchived: boolean) => {
    const message = isArchived
      ? 'Policy successfully archived.'
      : 'Policy successfully unarchived.';
    alertBanner({ type: 'SUCCESS', message });
  };
  const onArchiveOrUnarchiveError = () => {
    alertBanner({
      type: 'WARNING',
      message: 'Something went wrong. Please try again.',
    });
  };

  const [archivePolicy] = useMutation(ARCHIVE_POLICY, {
    variables: {
      insuranceType: 'PRIVATE_HEALTH',
      id: policy?.id,
    },
    refetchQueries: [GET_PRIVATE_POLICY],
    errorPolicy: 'none',
    onCompleted: () => onArchiveOrUnarchiveComplete(true),
    onError: onArchiveOrUnarchiveError,
  });

  const [unarchivePolicy] = useMutation(UNARCHIVE_POLICY, {
    variables: {
      insuranceType: 'PRIVATE_HEALTH',
      id: policy?.id,
    },
    refetchQueries: [GET_PRIVATE_POLICY],
    errorPolicy: 'none',
    onCompleted: () => onArchiveOrUnarchiveComplete(false),
    onError: onArchiveOrUnarchiveError,
  });

  if (!policy) {
    return <Page404 />;
  }

  return (
    <>
      {openCreateIssueModal && (
        <CreateIssueModal
          isOpen={openCreateIssueModal}
          setIsOpen={(isOpen: boolean) => setOpenCreateIssueModal(isOpen)}
          policyOrClaimId={policy.id}
          createIssueMutation={CREATE_PRIVATE_HEALTH_ISSUE}
          // TODO: [KONG] Remove type inference
          issueCategories={
            privateHealthIssueCategories as unknown as PrivateHealthIssueCategory[]
          }
          issueCategoryMapper={privateHealthIssueCategoryMapper}
          refetchQueries={[GET_PRIVATE_POLICY]}
        />
      )}
      <div className="flex flex-col items-start space-y-2 md:flex-row md:items-center md:space-x-2 md:space-y-0">
        <div className="relative flex items-center">
          <ChangeStatusV2
            status={policy.publicStatus as PrivateHealthStatus}
            issues={policy.issues}
            policyId={policy.id}
            policyNumber={policy.policyNumber}
          />
        </div>
        <div className="relative flex items-center">
          <Button
            buttonType="white"
            onClick={() => {
              setOpenCreateIssueModal(true);
            }}
          >
            Create issue
          </Button>
        </div>
        <ArchivePolicy
          onArchive={archivePolicy}
          onUnarchive={unarchivePolicy}
          shouldArchivePolicy={Boolean(!policy?.archivedAt)}
          collapsedButton
        />
      </div>
    </>
  );
};

export default PolicyActions;
