import {
  GenericClaimType,
  petHealthClaimTypes,
  standardGenericClaimTypes,
  travelClaimTypes,
} from 'shared/claims/generic/models';
import { InsuranceType } from 'shared/insurances/types';

export const getGenericClaimTypes = (
  insuranceType: InsuranceType
): GenericClaimType[] => {
  if (insuranceType === 'TRAVEL') {
    return travelClaimTypes as unknown as GenericClaimType[];
  }

  if (insuranceType === 'PET_HEALTH') {
    return petHealthClaimTypes as unknown as GenericClaimType[];
  }

  return standardGenericClaimTypes as unknown as GenericClaimType[];
};
