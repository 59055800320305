import classNames from 'classnames';

export const modalWrapper = classNames('fixed', 'z-30', 'inset-0');

export const modal = classNames(
  'flex',
  'items-center',
  'justify-center',
  'h-screen',
  'py-4',
  'px-4',
  'text-center',
  'min-h-full'
);

export const modalOverlay = classNames(
  'fixed',
  'inset-0',
  'bg-gray-900',
  'bg-opacity-50',
  'transition-opacity'
);

export const modalCard = classNames(
  'inline-block',
  'align-bottom',
  'bg-white',
  'rounded-[8px]',
  'px-[24px]',
  'pb-[24px]',
  'text-left',
  'transform',
  'transition-all',
  'w-[480px]',
  'min-h-fit',
  'h-auto',
  'max-h-[calc(100%-5px)]'
);

export const wide = classNames('w-[680px]');

export const overflowScroll = classNames('overflow-y-scroll');
export const overflowVisible = classNames('overflow-visible');

export const modalContent = classNames('text-left');

export const modalHeader = classNames(
  'flex',
  'justify-between',
  'sticky',
  'top-0',
  'bg-white',
  'z-30',
  'pt-[24px]',
  'pb-3'
);

export const title = classNames(
  'text-2xl',
  'leading-8',
  'font-bold',
  'text-gray-900'
);

export const xButton = classNames('outline-none');

export const xIcon = classNames('w-[24px]', 'text-gray-700');

export const modalFooter = classNames('flex', 'justify-end', 'mt-[24px]');

export const confirmButtonContent = classNames(
  'flex',
  'justify-center',
  'items-center'
);

export const loader = classNames(
  'animate-spin',
  'w-[20px]',
  'h-[20px]',
  'mr-[5px]'
);
export const cancelButton = classNames('mr-[8px]', '!px-[17px]');
