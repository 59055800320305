import { DocumentNode, useMutation } from '@apollo/client';
import Button from 'components/Button';
import FileDropzone from 'components/FileDropzone';
import FileErrorCard from 'components/FileErrorCard';
import { FormInputLabel } from 'components/FormInputLabel';
import Input from 'components/Input';
import Modal from 'components/Modal';
import RadioButton from 'components/RadioButton';
import TextArea from 'components/TextArea';
import { FormEvent, useState } from 'react';
import { retrieveZodErrorMessages } from 'shared/errorHandling/retrieveZodErrorMessages';
import { alertBanner } from 'shared/reactiveVariables';
import { z } from 'zod';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;

  reOpenIssueMutation: DocumentNode;
  refetchQueries: DocumentNode[];

  issueId: string;

  policyOrClaimId?: string;
}

interface Answers {
  comment: string;
  document: File;
  documentName: string;
}

// TODO: [KONG] Add translations
export const ReOpenIssueModal = ({
  isOpen,
  setIsOpen,
  reOpenIssueMutation,
  refetchQueries,
  issueId,
  policyOrClaimId,
}: Props) => {
  const [answers, setAnswers] = useState<Partial<Answers>>({});
  const [shouldUploadDocument, setShouldUploadDocument] = useState<
    'YES' | 'NO'
  >('NO');
  const [errors, setErrors] = useState<Partial<Record<keyof Answers, string>>>(
    {}
  );

  const [reOpenIssue, { loading: isReOpeningIssue }] = useMutation(
    reOpenIssueMutation,
    {
      refetchQueries,

      onCompleted: () => {
        onReset();
        alertBanner({
          type: 'SUCCESS',
          message: 'Issue successfully re-opened.',
        });
      },

      onError: () => {
        alertBanner({
          type: 'WARNING',
          message: 'Something went wrong. Please try again.',
        });
      },
    }
  );

  const onReset = () => {
    setAnswers({});
    setErrors({});
    setIsOpen(false);
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setErrors({});

    const zodSchemaWithRequiredDocument = z.object({
      comment: z.string(),
      document: z.instanceof(File),
      documentName: z.string().optional(),
    });

    const zodSchemaWithOptionalDocument = zodSchemaWithRequiredDocument.extend({
      document: z.instanceof(File).optional(),
    });

    const zodSchema =
      shouldUploadDocument === 'YES'
        ? zodSchemaWithRequiredDocument
        : zodSchemaWithOptionalDocument;

    const validation = await zodSchema.safeParseAsync(answers);

    if (!validation.success) {
      const errors = retrieveZodErrorMessages(validation.error);
      setErrors(errors);

      return;
    }

    await reOpenIssue({
      variables: {
        ...validation.data,
        policyOrClaimId,
        issueId,
      },
    });
  };

  return (
    <Modal hideActions open={isOpen} title="Re-open issue" setOpen={setIsOpen}>
      <form onSubmit={onSubmit}>
        {/* Comment */}
        <div className="mt-[16px]">
          <FormInputLabel title="Comment" />
          <div className="mt-[8px]">
            <TextArea
              className="h-[60px] max-h-[120px] min-h-[60px]"
              value={answers?.comment ?? ''}
              onChange={(e) => {
                setAnswers({ ...answers, comment: e.target.value });
              }}
              color="gray"
              placeholder="Explain why you are re-opening the issue"
              error={!!errors.comment}
              errorMessage={errors.comment}
            />
          </div>
        </div>

        {/* Upload document question */}
        <div className="mt-[24px]">
          <FormInputLabel title="Upload a document?" />
          <div className="mt-[8px] flex items-center space-x-[32px]">
            <RadioButton
              value="YES"
              id="YES"
              name="YES"
              onChange={() => {
                setShouldUploadDocument('YES');
              }}
              checked={shouldUploadDocument === 'YES'}
              error={false}
            >
              Yes
            </RadioButton>
            <RadioButton
              value="NO"
              id="NO"
              name="NO"
              onChange={() => {
                setShouldUploadDocument('NO');
              }}
              checked={shouldUploadDocument === 'NO'}
              error={false}
            >
              No
            </RadioButton>
          </div>
        </div>

        {shouldUploadDocument === 'YES' && (
          <div className="mt-[24px]">
            <FormInputLabel title="Document name" optional />
            <div className="mt-[8px]">
              <Input
                value={answers?.documentName ?? ''}
                onChange={(e) => {
                  setAnswers({ ...answers, documentName: e.target.value });
                }}
                color="gray"
                placeholder="Enter document name"
                error={!!errors.documentName}
                errorMessage={errors.documentName}
              />
              <div className="mt-[8px]">
                <FileDropzone
                  uploadedFile={answers.document}
                  onFileSelect={(file) => {
                    setAnswers({ ...answers, document: file });
                  }}
                />
                {errors.document && (
                  <FileErrorCard
                    open={!!errors.document}
                    title="Upload failed. Please try again."
                    handleClose={() => {}}
                    errorType="ERROR"
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <div className="mt-[24px] flex justify-end">
          <Button
            className="w-[80px]"
            buttonType="secondary"
            type="button"
            onClick={onReset}
          >
            Cancel
          </Button>
          <Button
            className="ml-[8px] w-[140px]"
            buttonType="primary"
            type="submit"
            loading={isReOpeningIssue}
          >
            Re-open issue
          </Button>
        </div>
      </form>
    </Modal>
  );
};
