/* This example requires Tailwind CSS v2.0+ */
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import Badge from 'components/Badge';
import { Fragment } from 'react';

import * as styles from './styles';

export interface DropdownOption {
  id: string;
  label: string;
  show: boolean;
  dot?: boolean;
}

interface DropdownProps {
  options: DropdownOption[];
  selected: DropdownOption;
  setSelected: (newSelected: DropdownOption) => void;
  disabled: boolean;
  type?: 'grey' | 'transparent' | 'white';
  smallVersion?: boolean;
}

const Dropdown = ({
  options,
  selected,
  setSelected,
  disabled,
  type = 'grey',
  smallVersion = true,
}: DropdownProps) => {
  return (
    <Listbox value={selected} onChange={setSelected} disabled={disabled}>
      {({ open }) => (
        <>
          <div
            className={classNames(styles.selectWrapper, {
              [styles.disabledSelected]: disabled,
            })}
          >
            <Listbox.Button
              className={classNames(styles.selected, {
                [styles.selectedGrey]: type === 'grey',
                [styles.selectedWhite]: type === 'white',
                [styles.selectedTransparent]: type === 'transparent',
                [styles.selectedSmall]: smallVersion,
                [styles.selectedBig]: !smallVersion,
              })}
            >
              <span className="mr-[8px] w-max">{selected?.label}</span>
              <ChevronDownIcon
                className={classNames(styles.icon, {
                  [styles.disabledIcon]: disabled,
                })}
              />
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={classNames(styles.optionsList, {
                  [styles.optionsListSmall]: smallVersion,
                  [styles.optionsListBig]: !smallVersion,
                })}
              >
                {options.map(
                  (option) =>
                    option.show && (
                      <Listbox.Option
                        key={option.id}
                        className={classNames(styles.listOption)}
                        value={option}
                        disabled={selected === option}
                      >
                        {({ selected, active }) => (
                          <div className="flex items-center space-x-2">
                            {option.dot && (
                              <Badge color="yellow" badgeType="dot" />
                            )}
                            <span
                              className={classNames({
                                [styles.listOptionTextSelected]: selected,
                                [styles.listOptionText]: !selected,
                                [styles.listOptionTextHover]: active,
                              })}
                            >
                              {option.label}
                            </span>
                          </div>
                        )}
                      </Listbox.Option>
                    )
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default Dropdown;
