import classNames from 'classnames';

export const cell = classNames(
  'text-sm',
  'row-cell',
  'text-indigo-500',
  'hover:text-indigo-900'
);

export const sticky = classNames('sticky', 'bg-white');

export const borderRight = classNames(
  'border-r',
  'border-gray-300',
  'table-breakpoint:border-r-0'
);

export const borderLeft = classNames(
  'border-l',
  'border-gray-300',
  'table-breakpoint:border-l-0'
);

export const divider = classNames('border-t', 'border-t-gray-200');

export const button = classNames('p-[16px]', 'w-full', 'text-left');
