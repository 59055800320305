import DocumentsCard, { DocumentsSectionData } from 'components/DocumentsCard';
import { SelectMenuOption } from 'components/SelectMenu';
import {
  DELETE_PUBLIC_POLICY_FILE,
  INTERNAL_UPLOAD_PUBLIC_POLICY_FILE,
} from 'graphql/publicHealth/internal/mutations';
import { GET_PUBLIC_POLICY } from 'graphql/publicHealth/internal/queries';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';

interface DocumentsSectionProps {
  data: PublicHealthPolicy;
}

const DocumentsSection = ({ data }: DocumentsSectionProps) => {
  const documents: DocumentsSectionData<string>[] = [
    {
      title: 'Policy documents',
      documents: [
        {
          fileLink:
            data.mergedConfirmationOfCoverageUrl ??
            data.confirmationOfCoverageFromProviderUrl ??
            data.confirmationOfCoverageUrl,
          fileName: 'Final confirmation of coverage',
        },
        {
          fileLink:
            data.occupation === 'UNIVERSITY_STUDENT'
              ? data.studentChecklist?.proofOfConfirmationOfCoverage
              : undefined,
          fileName: 'Prelim. confirmation of coverage (Feather)',
        },
        {
          fileLink: data.powerOfAttorneyUrl,
          fileName: 'Power of attorney',
        },
      ],
    },
    {
      title: 'Customer uploads',
      documents: [
        {
          fileLink: data.healthCard?.photoUrl,
          fileName: 'Health card photo',
        },
        {
          fileLink:
            data.occupation === 'UNIVERSITY_STUDENT'
              ? data.studentChecklist?.uniEnrollmentCertificateUrl
              : undefined,
          fileName: 'Enrollment certificate',
        },
        {
          fileLink:
            data.occupation === 'UNIVERSITY_STUDENT'
              ? data.sepaMandateUrl
              : undefined,
          fileName: 'SEPA mandate',
        },
        {
          fileLink:
            data.occupation === 'UNIVERSITY_STUDENT'
              ? data.confirmationOfPermanentResidenceDocumentUrl
              : undefined,
          fileName: 'Confirmation of permanent residence',
        },
        {
          fileLink:
            data.occupation === 'UNIVERSITY_STUDENT'
              ? data.studentChecklist?.acceptanceLetterUrl
              : undefined,
          fileName: 'Acceptance letter',
        },
        {
          fileLink: data.applicationDocumentUrl,
          fileName: 'Application document',
        },
        {
          fileLink:
            data.occupation === 'SELF_EMPLOYED' || data.occupation === 'OTHER'
              ? data.signedTKApplicationUrl
              : undefined,
          fileName: 'Signed TK application',
        },
      ],
    },
    {
      title: 'Feather uploads',
      documents: [],
    },
    {
      title: 'Provider uploads',
      documents: [
        {
          fileLink: data.preliminaryConfirmationOfCoverage?.url,
          fileName: 'Prelim. confirmation of coverage (Provider)',
          blobName: data.preliminaryConfirmationOfCoverage?.blobName,
          id: data.preliminaryConfirmationOfCoverage?.id,
          canDelete: true,
          documentType: 'PRELIMINARY_CONFIRMATION_OF_COVERAGE',
        },
      ],
    },
  ];

  data.otherDocumentsFromProvider.forEach((document) => {
    documents[3].documents.push({
      fileLink: document.url,
      fileName: document?.name ?? 'Untitled document',
      blobName: document.blobName,
      id: document.id,
      canDelete: true,
      documentType: 'OTHER_INTERNAL',
    });
  });

  data.otherDocumentsFromFeather.forEach((document) => {
    documents[2].documents.push({
      fileLink: document.url,
      fileName: document?.name ?? 'Untitled document',
      blobName: document.blobName,
      id: document.id,
      canDelete: true,
      documentType: 'OTHER_INTERNAL',
    });
  });

  const fileTypeOptions: SelectMenuOption[] = [
    {
      id: 'CONFIRMATION_OF_COVERAGE',
      label: 'Final confirmation of coverage',
    },
    ...(data.occupation === 'UNIVERSITY_STUDENT'
      ? [
          {
            id: 'PRELIMINARY_CONFIRMATION_OF_COVERAGE',
            label: 'Student Prelim. confirmation of coverage',
          },
        ]
      : []),
    ...(data.occupation === 'UNIVERSITY_STUDENT'
      ? [
          {
            id: 'UNI_ENROLLMENT_CERTIFICATE',
            label: 'Enrollment certificate',
          },
        ]
      : []),
    ...(data.occupation === 'UNIVERSITY_STUDENT'
      ? [
          {
            id: 'ACCEPTANCE_LETTER',
            label: 'Acceptance letter',
          },
        ]
      : []),
    {
      id: 'OTHER',
      label: 'Other',
    },
  ];

  return (
    <>
      <DocumentsCard
        sections={documents}
        fileTypeOptions={fileTypeOptions}
        mutation={INTERNAL_UPLOAD_PUBLIC_POLICY_FILE}
        getPolicyMutation={GET_PUBLIC_POLICY}
        policyId={data.id}
        deleteMutation={DELETE_PUBLIC_POLICY_FILE}
      />
    </>
  );
};

export default DocumentsSection;
