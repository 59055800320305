import { gql } from '@apollo/client';

import { LIABILITY_POLICY_ACTIVITY } from './activity';

export const GET_LIABILITY_POLICY = gql`
  ${LIABILITY_POLICY_ACTIVITY}
  query GetLiabilityPolicy($id: String!) {
    liabilityPolicy(id: $id) {
      id
      policyNumber
      createdAt
      startDate
      archivedAt
      price
      paymentReceivedAt
      source
      campaign
      medium
      content
      term
      numberOfClaims
      publicStatus
      activeUntil
      inclusiveMembers

      # insured person
      insuredPerson {
        firstName
        lastName
        dateOfBirth
        email
        gender
      }

      # user
      user {
        id
        firstName
        lastName
        stripeToken
        intercomEUId
        email
        isDelinquent
        isFraudulent
        fraudulentAdditionalInfo
      }

      # documents
      policyDocuments {
        url
        id
        name
        blobName
        isRegeneratable
        resizedUrl
      }
      internalDocuments {
        url
        id
        name
        blobName
        isRegeneratable
        resizedUrl
      }
      liabilityPolicyDocuments {
        id
        url
        blobName
      }
      questionnaireDownloadDocuments {
        id
        url
        blobName
      }

      # policy cancelation request
      policyCancelationRequest {
        id
        cancelationConfirmedByProviderAt
        createdAt
        canceledOnStripeAt
        requestSentToProviderAt
      }

      # questionnaire
      questionnaire {
        id
        answers
      }

      pinnedNotes {
        id
        body
        createdAt
        adminUser {
          firstName
          lastName
        }
        updatedAt
      }

      # policy activity
      ...LiabilityPolicyActivity
    }
  }
`;
