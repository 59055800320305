import classNames from 'classnames';

import { TableCellProps } from '../TableCell';
import * as styles from './styles';

export const TableCellButton = ({
  header,
  divider,
  title,
  onClick,
}: TableCellProps) => {
  return (
    <td
      className={classNames(styles.cell, header?.minWidth, header?.width, {
        [`${header?.threshold} ${styles.sticky}`]: header?.sticky,
        [styles.borderRight]: header?.border === 'right',
        [styles.borderLeft]: header?.border === 'left',
        [styles.divider]: divider,
      })}
    >
      <button className={styles.button} type="button" onClick={onClick}>
        {title}
      </button>
    </td>
  );
};
