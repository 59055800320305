import { ExternalLinkIcon } from '@heroicons/react/solid';
import Badge from 'components/Badge';
import Link from 'components/Link';
import { Status } from 'models/statusColor';

interface TableCellStatusProps {
  href?: string;
  data: unknown;
  getStatus: (data: any) => Status | undefined;
}

export const TableCellStatus = ({
  getStatus,
  data,
  href,
}: TableCellStatusProps) => {
  const cellData = getStatus(data);

  return (
    <>
      {cellData && (
        <Badge color={cellData.color} badgeType="full">
          {cellData.text.text}
        </Badge>
      )}
      {href && (
        <Link
          color="primary"
          text=""
          IconComponent={ExternalLinkIcon}
          href={href}
          iconLeft={false}
          className="space-x-1"
          target="_blank"
        />
      )}
    </>
  );
};
