import { DocumentsSectionData } from 'components/DocumentsCard';
import { SelectMenuOption } from 'components/SelectMenu';
import { retrieveFileFriendlyName } from 'shared/utils/retrieveFileFriendlyName';

import { LifePolicyData } from '../models/LifePolicy';

export const fileTypeOptions: SelectMenuOption<'POLICY_DOCUMENTS'>[] = [
  {
    id: 'POLICY_DOCUMENTS',
    label: 'Policy documents',
  },
];

export const getDocumentSections = (
  data?: LifePolicyData
): DocumentsSectionData<string>[] => {
  const policyDocuments: DocumentsSectionData<string> = {
    title: 'Policy documents',
    documents: [],
  };

  data?.lifePolicy.policyDocuments.forEach(
    ({ url, name, resizedUrl, blobName, id }) => {
      policyDocuments.documents.push({
        fileLink: url,
        fileName: name,
        resizedUrl,
        id,
        blobName,
        canDelete: true,
        documentType: 'POLICY_DOCUMENT',
      });
    }
  );

  data?.lifePolicy.internalDocuments.forEach(
    ({ url, name, resizedUrl, id, blobName }) => {
      policyDocuments.documents.push({
        fileLink: url,
        fileName: name,
        resizedUrl,
        id,
        blobName,
        canDelete: true,
        documentType: 'INTERNAL_POLICY_DOCUMENT',
      });
    }
  );

  data?.lifePolicy.lifePolicyDocuments.forEach(({ url, blobName, id }) => {
    policyDocuments.documents.push({
      fileLink: url,
      fileName: retrieveFileFriendlyName(blobName),
      resizedUrl: url,
      id,
      blobName,
      canDelete: true,
      documentType: 'LIFE_DOCUMENT',
    });
  });

  data?.lifePolicy.questionnaireDownloadDocuments.forEach(
    ({ url, blobName }) => {
      policyDocuments.documents.push({
        fileLink: url,
        fileName: retrieveFileFriendlyName(blobName),
        resizedUrl: url,
      });
    }
  );

  return [policyDocuments];
};
