import classNames from 'classnames';

export const singleSelect = classNames('relative', 'h-[40px]');

export const singleSelectHeader = classNames(
  'relative',
  'inline-flex',
  'rounded-[6px]',
  'divide-x',
  'divide-gray-200'
);

export const selectOption = classNames(
  'relative',
  'inline-flex',
  'items-center',
  'bg-white',
  'py-[10px]',
  'px-[12px]',
  'rounded-l-[6px]'
);

export const textStatus = classNames(
  'text-sm',
  'font-normal',
  'text-gray-500',
  'mr-[8px]'
);

export const button = classNames(
  'relative',
  'inline-flex',
  'items-center',
  'bg-white',
  'p-2',
  'rounded-r-[6px]',
  'text-sm',
  'font-medium',
  'text-gray-500',
  'hover:bg-indigo-50',
  'hover:text-indigo-500',
  'focus:text-indigo-500',
  'focus:bg-indigo-50',
  'focus:outline-none',
  'focus:z-50',
  'focus:ring-2',
  'focus:ring-offset-white',
  'focus:ring-indigo-500',
  'disabled:bg-gray-50',
  'disabled:hover:text-gray-500'
);

export const icon = classNames('h-5', 'w-5');

export const listOptions = classNames(
  'origin-top-right',
  'absolute',
  'right-0',
  'z-[9999]',
  'mt-[2px]',
  'w-[200px]',
  'rounded-[6px]',
  'shadow-lg',
  'overflow-hidden',
  'bg-white',
  'focus:outline-none',
  'overflow-y-scroll'
);

export const maxHeight = classNames('max-h-[235px]');
export const maxHeightBottomLabel = classNames('max-h-[280px]');

export const listOptionHover = classNames('bg-indigo-500');

export const listOption = classNames(
  'select-none',
  'relative',
  'px-[12px]',
  'py-[16px]',
  'text-sm',
  'z-50'
);

export const hidden = classNames('hidden');

export const clearFilterContainer = classNames(
  'flex',
  'border-t',
  'justify-end',
  'p-[12px]',
  'text-sm',
  'sticky',
  'bottom-0',
  'z-50',
  'bg-white'
);

export const clearFilterButton = classNames(
  'text-gray-600',
  'font-bold',
  'disabled:text-gray-400'
);

export const listOptionDisabled = classNames('hidden');

export const border = classNames('border', 'border-gray-300');
