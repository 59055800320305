import { Switch } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { MouseEvent } from 'react';

import * as styles from './CheckBox.styles';

type CheckBoxProps = {
  label?: string;
  checked: boolean;
  setChecked: (enabled: true) => void;
  onShiftClick?: () => void;
  muted?: boolean;
  small?: boolean;
  className?: string;
};

export const CheckBox = ({
  label,
  checked,
  setChecked,
  onShiftClick,
  muted,
  small = false,
  className,
}: CheckBoxProps) => {
  return (
    <Switch.Group>
      <div className={classNames('flex items-center gap-1', className)}>
        <Switch
          checked={checked}
          onChange={setChecked}
          className={classNames(styles.checkbox, {
            [styles.mutedBorder]: muted,
            [styles.normalBorder]: !muted,
            [styles.selected]: checked,
            '!h-4 !w-4': small,
          })}
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            if (e.shiftKey && onShiftClick) {
              e.stopPropagation();
              onShiftClick();
            }
          }}
        >
          <CheckIcon
            className={classNames('text-white', {
              'opacity-0': !checked,
              '!h-4 !w-4': small,
            })}
          />
        </Switch>
        {label && <Switch.Label>{label}</Switch.Label>}
      </div>
    </Switch.Group>
  );
};
