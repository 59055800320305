import { useMutation } from '@apollo/client';
import Badge from 'components/Badge';
import Button from 'components/Button';
import CalendarDatePicker from 'components/CalendarDatePicker';
import { CheckBox } from 'components/CheckBox';
import { FormInputLabel } from 'components/FormInputLabel';
import Input from 'components/Input';
import { StatusChangeIssueCard } from 'components/Issues/StatusChangeIssueCard/StatusChangeIssueCard';
import Modal from 'components/Modal';
import RadioButton from 'components/RadioButton';
import SelectButton, { SelectButtonOption } from 'components/SelectButton';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import TextArea from 'components/TextArea';
import { IssueWithCorrectTypes } from 'models/issue';
import { UPDATE_PRIVATE_POLICY_STATUS } from 'pages/policies/privateHealth/graphql/mutations';
import { GET_PRIVATE_POLICY } from 'pages/policies/privateHealth/graphql/queries';
import { FormEvent, useEffect, useState } from 'react';
import { retrieveZodErrorMessages } from 'shared/errorHandling/retrieveZodErrorMessages';
import {
  privateHealthBadgeColorMapper,
  privateHealthStatusMapper,
} from 'shared/insurances/statusMappers/privateHealth';
import { PrivateHealthStatus } from 'shared/insurances/types';
import { combinedIssueCategoryMapper } from 'shared/issues/shared/issueCategoryMapper';
import { alertBanner } from 'shared/reactiveVariables';

import {
  CantBeCoveredType,
  cantBeCoveredTypeMapper,
  cantBeCoveredTypes,
} from '../../models';
import {
  getZodSchema,
  privateHealthStatusesWithoutDeprecatedStatus,
} from './utils/getZodSchema';

interface Form {
  newStatus: PrivateHealthStatus;
  issueIdsToResolve?: string[];

  messageToProvider?: string;
  isUrgentApplication?: boolean;

  startDate: string;
  policyNumber: string;

  endDate: string;

  issueTitle?: string;
  issueDescription: string;

  reason: CantBeCoveredType;
  additionalInfo?: string;
  messageToCustomer: string;
}

interface ChangeStatusProps {
  policyId: string;
  status: PrivateHealthStatus;
  issues: IssueWithCorrectTypes[];
  policyNumber?: string;
}

export const ChangeStatusV2 = ({
  policyId,
  status,
  issues,
  policyNumber,
}: ChangeStatusProps) => {
  const [openForm, setOpenForm] = useState(false);

  const [form, setForm] = useState<Partial<Form>>({});
  const [errors, setErrors] = useState<Partial<Record<keyof Form, string>>>({});

  const [shouldCreateAnIssue, setShouldCreateAnIssue] = useState(false);

  const isNewStatusApplicationSent = form.newStatus === 'APPLICATION_SENT';

  const isNewStatusActive = form.newStatus === 'ACTIVE';

  useEffect(() => {
    if (isNewStatusActive && policyNumber) {
      setForm({ ...form, policyNumber });
    }
  }, [isNewStatusActive, policyNumber]);

  const [updateStatus, { loading }] = useMutation(
    UPDATE_PRIVATE_POLICY_STATUS,
    {
      refetchQueries: [GET_PRIVATE_POLICY],
      awaitRefetchQueries: true,
      errorPolicy: 'none',

      onCompleted: () => {
        onReset();
        alertBanner({
          type: 'SUCCESS',
          message: 'Status successfully updated.',
        });
      },

      onError: () => {
        onReset();
        alertBanner({
          type: 'WARNING',
          message: 'Something went wrong. Please try again.',
        });
      },
    }
  );

  const onReset = () => {
    setForm({});
    setShouldCreateAnIssue(false);
    setErrors({});
    setOpenForm(false);
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!form?.newStatus) {
      throw new Error('[Private policies] New status not found for submission');
    }

    setErrors({});

    const taskIdsToCancel = issues.flatMap(
      ({ id: issueId, tasks }: IssueWithCorrectTypes) => {
        // Find which issue is selected and has tasks
        if (form.issueIdsToResolve?.includes(issueId) && tasks) {
          // Find which tasks have open statuses
          const taskIdsWithOpenStatuses = tasks.flatMap(
            ({ status, id: taskId }) => {
              if (status === 'OPEN') {
                return [taskId];
              }
              return [];
            }
          );

          return taskIdsWithOpenStatuses;
        }

        return [];
      }
    );

    const additionalInfoString = form.additionalInfo
      ? ` - ${form.additionalInfo}`
      : '';
    const reasonForDenial = form.reason
      ? `${cantBeCoveredTypeMapper[form.reason]}${additionalInfoString}`
      : null;

    const completedForm = {
      ...form,
      ...(taskIdsToCancel.length > 0 && { taskIdsToCancel }),
      ...(reasonForDenial && { reasonForDenial }),
    };

    const zodSchema = getZodSchema(form.newStatus, shouldCreateAnIssue);

    const validation = await zodSchema.safeParseAsync(completedForm);

    if (!validation.success) {
      const errors = retrieveZodErrorMessages(validation.error);
      setErrors(errors);

      return;
    }

    await updateStatus({
      variables: {
        ...validation.data,
        policyId,
      },
    });
  };

  const statusOptions: SelectButtonOption<PrivateHealthStatus>[] =
    privateHealthStatusesWithoutDeprecatedStatus.map((status) => ({
      id: status,
      label: privateHealthStatusMapper[status],
      title: privateHealthStatusMapper[status],
      color: privateHealthBadgeColorMapper[status],
    }));

  const currentStatus = statusOptions.find(({ id }) => id === status);

  const handleStatusChange = (
    option: SelectButtonOption<PrivateHealthStatus>
  ) => {
    if (option.id === status) {
      return;
    }

    setForm({ newStatus: option.id });
    setOpenForm(true);
  };

  const issuesToResolve = issues.filter(({ status }) => status === 'OPEN');

  const doIssueIdsToResolveHaveTasks =
    issues.filter(
      ({ tasks, id }) =>
        form.issueIdsToResolve?.includes(id) &&
        tasks &&
        tasks.filter(({ status }) => status === 'OPEN').length > 0
    )?.length > 0;

  const setChecked = (id: string) => {
    const isIdSelected = form.issueIdsToResolve?.includes(id);

    // Check whether id is already selected
    if (isIdSelected) {
      // Remove the id from list
      const newIssueIdsToResolve = form.issueIdsToResolve?.filter(
        (resolvableId) => resolvableId !== id
      );

      setForm({ ...form, issueIdsToResolve: newIssueIdsToResolve });
      return;
    }
    // Else add the id to list
    const newIssueIdsToResolve = [...(form.issueIdsToResolve ?? []), id];
    setForm({ ...form, issueIdsToResolve: newIssueIdsToResolve });
  };

  const onSendToProvider = () => {
    setForm({ newStatus: 'APPLICATION_SENT' });
    setOpenForm(true);
  };

  const modalTitle = isNewStatusApplicationSent
    ? 'Send to provider?'
    : 'Change status';

  const cantBeCoveredOptions = cantBeCoveredTypes.map((type) => ({
    id: type,
    label: cantBeCoveredTypeMapper[type],
  }));

  return (
    <>
      {form?.newStatus && (
        <Modal
          hideActions
          open={openForm}
          title={modalTitle}
          setOpen={onReset}
          scrollable={issuesToResolve.length > 1}
        >
          <form onSubmit={onSubmit}>
            {isNewStatusApplicationSent && (
              <p className="mt-[8px] text-sm text-gray-600">
                After you send the application to the provider, the status will
                change.
              </p>
            )}

            {/* New status */}
            <div
              className={`${
                isNewStatusApplicationSent ? 'mt-[24px]' : 'mt-[8px]'
              } flex`}
            >
              <FormInputLabel title="New status" />
              <Badge
                color={privateHealthBadgeColorMapper[form.newStatus]}
                badgeType="full"
                className="ml-[8px]"
              >
                {privateHealthStatusMapper[form.newStatus]}
              </Badge>
            </div>

            {isNewStatusApplicationSent && (
              <>
                <div className="mt-[24px]">
                  <FormInputLabel title="Message to provider" optional />
                  <p className="mt-[8px] text-sm text-gray-600">
                    This message will be included in the email sent to provider.
                  </p>
                  <TextArea
                    value={form.messageToProvider ?? ''}
                    onChange={(e) => {
                      setForm({ ...form, messageToProvider: e.target.value });
                    }}
                    color="gray"
                    error={Boolean(errors.messageToProvider)}
                    errorMessage={errors.messageToProvider}
                    placeholder="Start typing here..."
                    className="mt-[8px] max-h-[120px] min-h-[60px]"
                  />
                </div>

                <div className="mt-[24px]">
                  <div className="mt-[8px] flex">
                    <CheckBox
                      small
                      checked={form.isUrgentApplication ?? false}
                      setChecked={() => {
                        setForm({
                          ...form,
                          isUrgentApplication: !form.isUrgentApplication,
                        });
                      }}
                    />
                    <p className="ml-[8px] text-sm text-gray-900">
                      Mark as urgent application
                    </p>
                  </div>
                </div>
              </>
            )}

            {isNewStatusActive && (
              <>
                <div className="mt-[24px]">
                  <FormInputLabel title="Start date" />
                  <p className="mt-[2px] text-sm text-gray-600">
                    Unless modified, this is the date picked by the customer in
                    the questionnaire. You can also update this date later.
                  </p>
                  <CalendarDatePicker
                    className="mt-[8px] !w-[417px]"
                    onChange={(date) => {
                      if (!date || Array.isArray(date)) return;

                      const startDate = new Date(
                        Date.UTC(
                          date.getFullYear(),
                          date.getMonth(),
                          date.getDate()
                        )
                      ).toISOString();

                      setForm({ ...form, startDate });
                    }}
                    selectedDate={
                      form.startDate ? new Date(form.startDate) : undefined
                    }
                  />
                  {errors.startDate && (
                    <p className="mt-1 text-xs text-red-400">
                      {errors.startDate}
                    </p>
                  )}
                </div>

                <div className="mt-[24px]">
                  <FormInputLabel title="Policy number" />
                  <Input
                    className="mt-[8px]"
                    value={form.policyNumber ?? ''}
                    onChange={(e) => {
                      setForm({ ...form, policyNumber: e.target.value });
                    }}
                    color="gray"
                    error={Boolean(errors.policyNumber)}
                    errorMessage={errors.policyNumber}
                    placeholder="Enter policy number"
                  />
                </div>
              </>
            )}

            {form.newStatus === 'CANT_BE_COVERED' && (
              <>
                <div className="mt-[24px]">
                  <FormInputLabel title="Reason" />
                  <SelectMenu<CantBeCoveredType>
                    color="gray"
                    placeholder="Select a denial reason"
                    options={cantBeCoveredOptions}
                    selected={cantBeCoveredOptions.find(
                      ({ id }) => id === form.reason
                    )}
                    setSelected={(
                      option: SelectMenuOption<CantBeCoveredType>
                    ) => setForm({ ...form, reason: option.id })}
                    error={Boolean(errors.reason)}
                    errorMessage={errors.reason}
                  />
                </div>

                <div className="mt-[24px]">
                  <FormInputLabel title="Additional info" optional />
                  <p className="mt-[2px] text-sm text-gray-600">
                    You can add more details to the reason.
                  </p>
                  <TextArea
                    value={form.additionalInfo ?? ''}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        additionalInfo: e.target.value,
                      });
                    }}
                    color="gray"
                    error={Boolean(errors.additionalInfo)}
                    errorMessage={errors.additionalInfo}
                    placeholder="eg. specific diagnoses, etc."
                    className="mt-[8px] max-h-[120px] min-h-[60px]"
                  />
                </div>

                <div className="mt-[24px]">
                  <FormInputLabel title="Message to customer" />
                  <p className="mt-[2px] text-sm text-gray-600">
                    The following message will be included in the email sent to
                    customer.
                  </p>
                  <TextArea
                    value={form.messageToCustomer ?? ''}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        messageToCustomer: e.target.value,
                      });
                    }}
                    color="gray"
                    error={Boolean(errors.messageToCustomer)}
                    errorMessage={errors.messageToCustomer}
                    placeholder="Start typing here..."
                    className="mt-[8px] max-h-[120px] min-h-[60px]"
                  />
                </div>
              </>
            )}

            {form.newStatus === 'CANCELED' && (
              <div className="mt-[24px]">
                <FormInputLabel title="End date" />
                <CalendarDatePicker
                  className="mt-[8px] !w-[417px]"
                  onChange={(date) => {
                    if (!date || Array.isArray(date)) return;
                    const endDate = new Date(
                      Date.UTC(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate()
                      )
                    ).toISOString();
                    setForm({ ...form, endDate });
                  }}
                  selectedDate={
                    form.endDate ? new Date(form.endDate) : undefined
                  }
                />
                {errors.endDate && (
                  <p className="mt-1 text-xs text-red-400">{errors.endDate}</p>
                )}
              </div>
            )}

            {/* Resolving issues */}
            {issuesToResolve?.length > 0 && (
              <div className="mt-[24px]">
                <FormInputLabel title="Select issues to resolve" />
                {form?.issueIdsToResolve &&
                form?.issueIdsToResolve?.length > 0 ? (
                  <p className="mt-[2px] text-sm text-gray-600">
                    {/* Number of selected issues to resolve */}
                    {form.issueIdsToResolve.length} issue(s) will be resolved
                    {doIssueIdsToResolveHaveTasks && (
                      <> and open tasks will be canceled</>
                    )}
                  </p>
                ) : (
                  <p className="mt-[2px] text-sm text-gray-600">
                    No issue will be resolved
                  </p>
                )}
                {/* Only show open issues */}
                {issuesToResolve.map(
                  ({ id, description, title, category, tasks }) => {
                    const openTasks = tasks?.filter(
                      ({ status }) => status === 'OPEN'
                    );

                    return (
                      <div className="mt-[8px]">
                        <StatusChangeIssueCard
                          title={title ?? combinedIssueCategoryMapper[category]}
                          description={description}
                          numberOfTasks={openTasks?.length ?? 0}
                          checked={
                            form.issueIdsToResolve?.includes(id) ?? false
                          }
                          setChecked={() => setChecked(id)}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            )}

            {form.newStatus === 'ERR_MISSING_INFO' && (
              <>
                <div className="mb-[32px] mt-[32px] h-[1px] w-full bg-gray-300" />
                <FormInputLabel title="Create an issue?" />

                {shouldCreateAnIssue && (
                  <p className="mt-[4px] text-sm text-gray-600">
                    Once confirmed, you can create tasks for the customer.
                  </p>
                )}

                <div className="mt-[8px] flex items-center space-x-[32px]">
                  <RadioButton
                    value="YES"
                    id="YES"
                    name="YES"
                    onChange={() => {
                      setShouldCreateAnIssue(true);
                    }}
                    checked={shouldCreateAnIssue}
                  >
                    Yes
                  </RadioButton>
                  <RadioButton
                    value="NO"
                    id="NO"
                    name="NO"
                    onChange={() => {
                      setShouldCreateAnIssue(false);
                    }}
                    checked={!shouldCreateAnIssue}
                  >
                    No
                  </RadioButton>
                </div>

                {shouldCreateAnIssue && (
                  <>
                    <div className="mt-[24px]">
                      <FormInputLabel title="Issue title" optional />
                      <Input
                        className="mt-[8px]"
                        value={form.issueTitle ?? ''}
                        onChange={(e) => {
                          setForm({ ...form, issueTitle: e.target.value });
                        }}
                        color="gray"
                        error={Boolean(errors.issueTitle)}
                        errorMessage={errors.issueTitle}
                        placeholder="Enter issue title"
                      />
                    </div>
                    <div className="mt-[24px]">
                      <FormInputLabel title="Issue description" />
                      <TextArea
                        value={form.issueDescription ?? ''}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            issueDescription: e.target.value,
                          });
                        }}
                        color="gray"
                        error={Boolean(errors.issueDescription)}
                        errorMessage={errors.issueDescription}
                        placeholder="Start typing here..."
                        className="mt-[8px] max-h-[120px] min-h-[60px]"
                      />
                    </div>
                    <p className="mt-[16px] text-sm text-gray-600">
                      A resolved issue will be created and Feather will be
                      notified.
                    </p>{' '}
                  </>
                )}
              </>
            )}

            {isNewStatusActive && (
              <p className="mt-[16px] text-sm text-gray-600">
                The confirmation of coverage and letter for employer will be
                generated.
              </p>
            )}

            {/* Actions */}
            <div className="mt-[24px] flex justify-end">
              <Button
                className="w-[80px]"
                buttonType="secondary"
                type="button"
                onClick={onReset}
              >
                Cancel
              </Button>
              <Button
                className="ml-[8px] w-[140px]"
                buttonType="primary"
                type="submit"
                loading={loading}
              >
                {isNewStatusApplicationSent ? 'Send' : 'Confirm'}
              </Button>
            </div>
          </form>
        </Modal>
      )}

      {/* Status button */}
      <label className="text-md mr-[8px] text-gray-500">Status</label>
      <SelectButton<PrivateHealthStatus>
        withDot
        options={statusOptions}
        selected={currentStatus}
        disabled={loading}
        label="Status"
        handleOnChange={handleStatusChange}
      />

      {/* Send to provider button */}
      {status !== 'APPLICATION_SENT' && (
        <Button
          buttonType="primary"
          onClick={onSendToProvider}
          className="ml-4"
        >
          Send to provider
        </Button>
      )}
    </>
  );
};
