import { ExternalLinkIcon } from '@heroicons/react/solid';
import Badge from 'components/Badge';
import Link from 'components/Link';
import { PolicyStatus } from 'models/publicHealthStatus';

import TableCell, { TableCellProps } from '../TableCell';

const TableCellStatusWithLink = ({
  statusMapping,
  cell,
  header,
  textColor,
  divider,
  href,
}: TableCellProps) => {
  if (!statusMapping) {
    throw new Error('Missing status mapping function');
  }
  const { text, color } = statusMapping(cell as PolicyStatus);

  if (!cell) {
    return (
      <TableCell
        cell=""
        header={header}
        textColor={textColor}
        divider={divider}
      />
    );
  }

  const statusWithLink = (
    <div className="flex gap-1">
      <Badge color={color} badgeType="full">
        {text.text}
      </Badge>
      <Link
        color="primary"
        text=""
        IconComponent={ExternalLinkIcon}
        href={href}
        iconLeft={false}
        className="space-x-1"
        target="_blank"
      />
    </div>
  );
  return (
    <TableCell
      cell={statusWithLink}
      header={header}
      textColor={textColor}
      divider={divider}
    />
  );
};

export default TableCellStatusWithLink;
