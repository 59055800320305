import Badge from 'components/Badge';
import { PolicyStatus } from 'models/publicHealthStatus';

import TableCell, { TableCellProps } from '../TableCell';

const TableCellStatus = ({
  statusMapping,
  cell,
  header,
  textColor,
  divider,
  link,
  resourceOnClick,
}: TableCellProps) => {
  if (!statusMapping) {
    throw new Error('Missing status mapping function');
  }
  const { text, color } = statusMapping(cell as PolicyStatus);
  const status = (
    <Badge color={color} badgeType="full">
      {text.text}
    </Badge>
  );
  return (
    <TableCell
      cell={status}
      header={header}
      textColor={textColor}
      divider={divider}
      link={link}
      resourceOnClick={resourceOnClick}
    />
  );
};

export default TableCellStatus;
