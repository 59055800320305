import { CustomerPolicy } from '../models';

const statusOrder = [
  'APPLICATION_RECEIVED',
  'UNI_CHECK',
  'PENDING',
  'PROCESSING',
  'APPLICATION_SENT',
  'MISSING_INFO',
  'ERR_MISSING_INFO',
  'COVERED',
  'ACTIVE',
  'NOT_ELIGIBLE',
  'CANT_BE_COVERED',
  'DROPPED_OUT',
  'CANCELED',
];

export const orderPoliciesByStatus = (
  policies: CustomerPolicy[]
): CustomerPolicy[] => {
  const reversedStatusOrder = statusOrder.reverse();
  return reversedStatusOrder.reduce((currentArray, status) => {
    policies.forEach((policy) => {
      if (!policy.publicStatus && !currentArray.includes(policy)) {
        currentArray.push(policy);
      }

      if (status === policy.publicStatus) {
        currentArray.unshift(policy);
      }
    });
    return currentArray;
  }, [] as CustomerPolicy[]);
};
