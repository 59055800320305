import { ApolloError } from '@apollo/client';
import OverviewPage from 'components/OverviewPage/OverviewPage';
import { SelectButtonOption } from 'components/SelectButton';
import { EmptyStateMessage } from 'components/Table/TableEmptyState';
import ColumnHeader from 'components/TableRefactor/ColumnHeader';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import SortableColumnHeader from 'components/TableRefactor/SortableColumnHeader';
import Table from 'components/TableRefactor/Table';
import {
  HeaderConfig,
  RowConfig,
  ViewConfig,
} from 'components/TableRefactor/types';
import { Status } from 'models/statusColor';
import { useState } from 'react';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';
import { policyMappers } from 'shared/insurances';

import { GET_EXPAT_POLICIES } from '../graphql/policies';
import { ExpatHealthPolicy } from '../models/ExpatHealthPolicy';
import { mapPlan } from '../utils/mapPlan';

const NUMBER_OF_POLICIES_PER_PAGE = 12;

export interface ExpatHealthPoliciesData {
  expatPolicies: ExpatHealthPolicy[];
  totalExpatPolicies: number;
}

const filterStatusOptions: SelectButtonOption[] = [
  {
    title: 'Pending',
    label: 'Pending',
    id: 'pending',
  },
  {
    title: 'Covered',
    label: 'Covered',
    id: 'covered',
  },
  {
    title: 'Canceled',
    label: 'Canceled',
    id: 'canceled',
  },
];

const filterPlanOptions: SelectButtonOption[] = [
  {
    title: 'Basic',
    label: 'Basic',
    id: 'basic',
  },
  {
    title: 'Premium',
    label: 'Premium',
    id: 'premium',
  },
  {
    title: 'Advanced',
    label: 'Advanced',
    id: 'advanced',
  },
];

const filterViewOptions = [
  {
    id: 'all',
    label: 'All policies',
    title: 'All policies',
    show: true,
  },
  {
    id: 'expiring',
    label: 'Expiring policies',
    title: 'Expiring policies',
    show: true,
  },
];

const ExpatHealthPoliciesPage = () => {
  const defaultEmptyStateMessage: EmptyStateMessage = {
    title: 'There are no policies yet.',
    description: 'It looks like there are no private health policies yet.',
  };

  const tableFilters: FilterConfig[] = [
    {
      options: filterStatusOptions,
      filterType: 'MULTIPLE',
      label: 'Status',
      id: 'filterStatus',
      placeholder: 'Select status(es)',
    },
    {
      options: filterPlanOptions,
      filterType: 'MULTIPLE',
      label: 'Plan',
      id: 'filterPlan',
      placeholder: 'Select plan(s)',
    },
    {
      filterType: 'DATE_RANGE',
      label: 'End date',
      id: 'endDate',
    },
    {
      filterType: 'DATE_RANGE',
      label: 'Active until',
      id: 'activeUntil',
    },
    {
      filterType: 'DATE_RANGE',
      label: 'Created at',
      id: 'createdAt',
    },
  ];

  const viewConfig: ViewConfig = {
    id: 'filterView',
    options: filterViewOptions,
  };

  const tableHeaders: HeaderConfig[] = [
    {
      id: 'firstName',
      label: 'First name',
      width: 'w-none',
      minWidth: 'min-w-[116px]',
      sticky: true,
      threshold: 'left-0',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'lastName',
      label: 'Last name',
      width: 'w-none',
      minWidth: 'min-w-[116px]',
      sticky: true,
      threshold: 'left-[116px]',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'status',
      label: 'Status',
      width: 'w-none',
      minWidth: 'min-w-[116px]',
      sticky: true,
      threshold: 'left-[232px]',
      border: 'right',
      component: SortableColumnHeader,
    },
    {
      id: 'startDate',
      label: 'Start date',
      width: 'w-[116px]',
      minWidth: 'min-w-[116px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'endDate',
      label: 'End date',
      width: 'w-[116px]',
      minWidth: 'min-w-[116px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'activeUntilDate',
      label: 'Active until',
      width: 'w-[130px]',
      minWidth: 'min-w-[130px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'plan',
      label: 'Plan',
      width: 'w-[116px]',
      minWidth: 'min-w-[116px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'createdAt',
      label: 'Created on',
      width: 'w-[130px]',
      minWidth: 'min-w-[130px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'source',
      label: 'Source',
      width: 'w-[120px]',
      minWidth: 'min-w-[120px]',
      component: ColumnHeader,
    },
  ];

  const statusMapper = (status: string): Status => {
    const policyMapper = policyMappers.INCOMING;
    return {
      text: {
        id: status,
        text: policyMapper?.statusMapper[status] ?? '',
      },
      color: policyMapper?.badgeColorMapper[status] ?? 'gray',
    };
  };

  const rowConfig: RowConfig = {
    getResourceLink: (data: ExpatHealthPolicy) =>
      generatePath(paths.policies.expatHealth.internal.details.path, {
        id: data.id,
      }),
    cells: [
      {
        type: 'TEXT',
        textColor: 'dark',
        props: {
          getText: (data: ExpatHealthPolicy) =>
            data.insuredPerson
              ? data.insuredPerson.firstName ?? ''
              : data.user?.firstName ?? '',
        },
      },
      {
        type: 'TEXT',
        textColor: 'dark',
        props: {
          getText: (data: ExpatHealthPolicy) =>
            data.insuredPerson
              ? data.insuredPerson.lastName ?? ''
              : data.user?.lastName ?? '',
        },
      },
      {
        type: 'STATUS',
        textColor: 'dark',
        props: {
          getStatus: (data: ExpatHealthPolicy) => {
            return data.archivedAt
              ? {
                  text: {
                    id: 'ARCHIVED',
                    text: 'Archived',
                  },
                  color: 'gray',
                }
              : statusMapper(data.publicStatus);
          },
        },
      },
      {
        type: 'DATE',
        textColor: 'light',
        props: {
          getDate: (data: ExpatHealthPolicy) => data.startDate ?? '',
        },
      },
      {
        type: 'DATE',
        textColor: 'light',
        props: {
          getDate: (data: ExpatHealthPolicy) => data.endDate ?? '',
        },
      },
      {
        type: 'DATE',
        textColor: 'light',
        props: {
          getDate: (data: ExpatHealthPolicy) => data.activeUntilDate ?? '',
        },
      },
      {
        type: 'TEXT',
        textColor: 'light',
        props: {
          getText: (data: ExpatHealthPolicy) => mapPlan(data.plan ?? ''),
        },
      },
      {
        type: 'DATE',
        textColor: 'light',
        props: {
          getDate: (data: ExpatHealthPolicy) => data.createdAt ?? '',
        },
      },
      {
        type: 'TEXT',
        props: { getText: (data: ExpatHealthPolicy) => data.source ?? '' },
        textColor: 'light',
      },
    ],
  };

  const [error, setError] = useState<ApolloError | undefined>(undefined);

  return (
    <OverviewPage
      current="policies-expat"
      title="All policies - Feather Admin Panel"
      error={error}
    >
      <Table
        title="Expat health policies"
        itemsPerPage={NUMBER_OF_POLICIES_PER_PAGE}
        emptyStateLabel={defaultEmptyStateMessage}
        searchPlaceholder="Search by name, email, policy number, policy id or utm source"
        query={GET_EXPAT_POLICIES}
        headers={tableHeaders}
        rowConfig={rowConfig}
        filterConfig={tableFilters}
        viewConfig={viewConfig}
        onError={setError}
      />
    </OverviewPage>
  );
};

export default ExpatHealthPoliciesPage;
