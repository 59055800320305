import { useMutation } from '@apollo/client';
import Button from 'components/Button';
import EditQuestionnaireButton from 'components/EditQuestionnaireButton';
import {
  RUN_PREMODERATON,
  UPDATE_PUBLIC_QUESTIONNAIRE,
} from 'graphql/publicHealth/internal/mutations';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import QuestionnaireTab from 'pages/policies/publicHealth/components/QuestionnaireTab';
import CreateRequest from 'pages/tkApiRequests/components/CreateRequest/CreateRequest';
import { alertBanner } from 'shared/reactiveVariables';

import { getEditablePublicQuestionnaireAnswers } from '../../policy/getEditableQuestionnaireAnswers';
import PremoderationIssues from '../PremoderationIssues/PremoderationIssues';
import SideSection from '../SideSection';
import TkApiRequestErrors from '../TkApiRequestErrors';

const QuestionnaireTabPublicInternal = ({
  policy,
}: {
  policy: PublicHealthPolicy;
}) => {
  const [runPremoderation] = useMutation(RUN_PREMODERATON, {
    variables: { policyId: policy.id },
    onCompleted: () => {
      alertBanner({
        type: 'SUCCESS',
        message:
          'Premoderation running in the background. Refresh page after a few seconds.',
      });
    },
    onError: () => {
      alertBanner({
        type: 'WARNING',
        message: 'Something went wrong. Please try again.',
      });
    },
  });
  return (
    <>
      <div className="flex w-full flex-col">
        {policy.premoderationIssues && (
          <PremoderationIssues issues={policy.premoderationIssues} />
        )}
        {!policy.applicationSentAt && (
          <TkApiRequestErrors requests={policy.tkApiRequests} />
        )}
        <QuestionnaireTab
          data={policy}
          providerView={false}
          additionalAction={
            <>
              <Button buttonType="primary" onClick={() => runPremoderation()}>
                Rerun premoderation
              </Button>
              {policy.providerId === 'TK' && <CreateRequest policy={policy} />}
              {!['COVERED', 'CANCELED', 'CANT_BE_COVERED'].includes(
                policy.publicStatus
              ) && (
                <EditQuestionnaireButton
                  answers={policy.questionnaire?.answers}
                  questionnaireId={policy.questionnaire?.id ?? ''}
                  policyId={policy.id}
                  getEditableAnswers={getEditablePublicQuestionnaireAnswers}
                  mutation={UPDATE_PUBLIC_QUESTIONNAIRE}
                  description="After saving, new application document will be generated and
                resubmitted to the provider."
                />
              )}
            </>
          }
        />
      </div>
      <div className="w-full lg:ml-[20px] lg:w-auto">
        <SideSection policy={policy} />
      </div>
    </>
  );
};

export default QuestionnaireTabPublicInternal;
