import { getFixedT } from '@getpopsure/i18n-react';
import { OtherSituation } from 'models/OtherSituation';

export const mapOtherSituation = (type: OtherSituation | undefined): string => {
  const t = getFixedT();
  switch (type) {
    case 'RETIRED':
      return t(
        'admin.provider.policy.public.questionaire.othersituation.retired.label',
        'Retired'
      );
    case 'LANGUAGE_SCHOOL':
      return t(
        'admin.provider.policy.public.questionaire.othersituation.languageschool.label',
        'Language school'
      );
    case 'JOB_HUNTING':
      return t(
        'admin.provider.policy.public.questionaire.othersituation.jobhunting.label',
        'Job hunting'
      );
    case 'SPOUSE':
      return t(
        'admin.provider.policy.public.questionaire.othersituation.spouse.label',
        'Spouse'
      );
    case 'EMPLOYED_NOW_OR_IN_FUTURE':
      return t(
        'admin.provider.policy.public.questionaire.othersituation.employedNowOrInFuture.label',
        'Employed now or in the future'
      );
    case 'FAMILY_REUNION':
      return t(
        'admin.provider.policy.public.questionaire.othersituation.familyreunion.label',
        'Family reunion'
      );
    case 'OTHER':
      return t(
        'admin.provider.policy.public.questionaire.othersituation.other.label',
        'Other'
      );
    case 'APPRENTICE':
      return t(
        'admin.provider.policy.public.questionaire.othersituation.apprentice.label',
        'Apprentice'
      );
    case 'UNEMPLOYED':
      return t(
        'admin.provider.policy.public.questionaire.othersituation.unemployed.label',
        'Unemployed'
      );
    case 'FSJ':
      return t(
        'admin.provider.policy.public.questionaire.othersituation.fsj.label',
        'Voluntary Year'
      );
    case undefined:
      return '';
  }
};
