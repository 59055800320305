import { useMutation } from '@apollo/client';
import Button from 'components/Button';
import ComboBox from 'components/ComboBox';
import Modal from 'components/Modal';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import { CREATE_AND_SEND_TK_API_REQUEST } from 'pages/tkApiRequests/graphql/createRequest';
import { useState } from 'react';
import JSONInput from 'react-json-editor-ajrm';
import { jsonEditorLocale } from 'shared/jsonEditorLocate';
import { alertBanner } from 'shared/reactiveVariables';

import * as styles from './styles';

const CreateRequest = ({ policy }: { policy: PublicHealthPolicy }) => {
  const [open, setOpen] = useState(false);
  const latestFailedRequest = policy.tkApiRequests.find(
    (request) => request.status === 'FAIL' || request.status === 'PENDING'
  );
  const payloadPlaceholder = latestFailedRequest
    ? JSON.parse(latestFailedRequest?.payload ?? '')
    : {};
  const [payload, setPayload] = useState<object>(payloadPlaceholder);
  const [payloadError, setPayloadError] = useState(false);

  const onCompleted = () => {
    setOpen(false);
    alertBanner({
      type: 'SUCCESS',
      message: 'The request is being created and sent in the background.',
    });
  };
  const onError = () => {
    alertBanner({
      type: 'WARNING',
      message: 'Something went wrong. Please try again.',
    });
  };

  const [createRequest, { loading }] = useMutation(
    CREATE_AND_SEND_TK_API_REQUEST,
    { errorPolicy: 'none', onCompleted, onError }
  );

  const handlePayloadChange = ({
    jsObject,
    error,
  }: {
    jsObject: object;
    error: object | boolean;
  }) => {
    if (error) {
      setPayloadError(true);
      return;
    }
    setPayload(jsObject);
    setPayloadError(false);
  };

  const handleSubmitRequest = () => {
    createRequest({
      variables: {
        policyId: policy.id,
        payload: JSON.stringify(payload, null, 4),
      },
    });
  };

  return (
    <>
      <Button buttonType="primary" onClick={() => setOpen(true)}>
        Preview JSON and send to TK
      </Button>
      <Modal
        title="Create and send TK API Request"
        open={open}
        setOpen={setOpen}
        confirmButtonLabel="Confirm and send"
        textLoading="Sending..."
        disabled={payloadError}
        loading={loading}
        handleConfirm={handleSubmitRequest}
      >
        <div className="max-h-fit flex flex-col">
          <div className={styles.section}>
            <label className={styles.label} htmlFor="policy">
              User policy
            </label>
            <ComboBox
              id="policy"
              options={[]}
              selectedOption={{
                id: policy.id,
                label: `${policy.user.firstName} ${policy.user.lastName}, ${
                  policy.tkApiId ?? 'No external ID'
                }, ${policy.publicStatus}`,
              }}
              setSelectedOption={() => {}}
              placeholder=""
              color="gray"
              disabled
            />
          </div>
          <div className={styles.section}>
            <label className={`${styles.label} mb-2`} htmlFor="payload">
              Payload
            </label>
            <JSONInput
              id="payload"
              locale={jsonEditorLocale}
              width="432px"
              placeholder={payload}
              onChange={handlePayloadChange}
              confirmGood={false}
              colors={{
                default: '#26262E',
                background_warning: '#F5F5FA',
                background: '#F5F5FA',
                keys: '#26262E',
                keys_whiteSpace: '#26262E',
                /* @ts-expect-error: Ignoring because error property was not declared but is needed */
                error: '#F87171',
                colon: '#26262E',
                primitive: '#6160A2',
                string: '#6160A2',
                number: '#6160A2',
              }}
              style={{
                labels: { color: '#F5F5FA' },
                container: {
                  borderRadius: '8px',
                  maxHeight: 'fit-content',
                  height: '500px',
                },
                warningBox: { backgroundColor: '#F5F5FA' },
                errorMessage: { color: '#F87171' },
                outerBox: { maxHeight: 'fit-content', height: 'auto' },
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateRequest;
