import { DocumentsSectionData } from 'components/DocumentsCard';
import { SelectMenuOption } from 'components/SelectMenu';
import { retrieveFileFriendlyName } from 'shared/utils/retrieveFileFriendlyName';

import { LegalPolicyData } from '../models/LegalPolicy';

export const fileTypeOptions: SelectMenuOption<'POLICY_DOCUMENTS'>[] = [
  {
    id: 'POLICY_DOCUMENTS',
    label: 'Policy documents',
  },
];

export const getDocumentSections = (
  data?: LegalPolicyData
): DocumentsSectionData<string>[] => {
  const policyDocuments: DocumentsSectionData<string> = {
    title: 'Policy documents',
    documents: [],
  };

  data?.legalPolicy.policyDocuments.forEach(
    ({ url, name, resizedUrl, id, blobName }) => {
      policyDocuments.documents.push({
        fileLink: url,
        fileName: name,
        resizedUrl,
        id,
        blobName,
        canDelete: true,
        documentType: 'POLICY_DOCUMENT',
      });
    }
  );

  data?.legalPolicy.internalDocuments.forEach(
    ({ url, name, resizedUrl, id, blobName }) => {
      policyDocuments.documents.push({
        fileLink: url,
        fileName: name,
        resizedUrl,
        id,
        blobName,
        canDelete: true,
        documentType: 'INTERNAL_POLICY_DOCUMENT',
      });
    }
  );

  data?.legalPolicy.legalPolicyDocuments.forEach(({ url, blobName, id }) => {
    policyDocuments.documents.push({
      fileLink: url,
      fileName: retrieveFileFriendlyName(blobName),
      resizedUrl: url,
      id,
      blobName,
      canDelete: true,
      documentType: 'LEGAL_DOCUMENT',
    });
  });

  data?.legalPolicy.questionnaireDownloadDocuments.forEach(
    ({ url, blobName }) => {
      policyDocuments.documents.push({
        fileLink: url,
        fileName: retrieveFileFriendlyName(blobName),
        resizedUrl: url,
      });
    }
  );

  return [policyDocuments];
};
