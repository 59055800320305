import { useMutation, useQuery } from '@apollo/client';
import Button from 'components/Button';
import { ComboBoxOption } from 'components/ComboBox/ComboBox';
import { ModalForm } from 'components/ModalForm';
import { SelectMenuOption } from 'components/SelectMenu';
import { useDebounce } from 'hooks/useDebounce';
import { User } from 'models/User';
import { GET_CUSTOMERS } from 'pages/customers/graphql/queries';
import { useState } from 'react';
import { alertBanner } from 'shared/reactiveVariables';

import { CREATE_PRIVATE_HEALTH_POLICY } from '../../graphql/mutations';
import { GET_PRIVATE_POLICIES } from '../../graphql/queries';
import { getAddon, getAddonsForTariff } from '../../utils/mapAddons';
import { getDeductibleOptions } from '../../utils/mapDeductibleOptions';
import { tariffs } from '../../utils/mapTariffNames';
import { CreatePrivateHealthValidationSchema } from './validationSchema';

const CreatePolicy = () => {
  const [open, setOpen] = useState(false);
  const [customersSearchQuery, setCustomersSearchQuery] = useState('');
  const debouncedValue = useDebounce(customersSearchQuery, 1_000);
  const [tariff, setTariff] = useState<string | undefined>();

  const { loading: customersLoading, data: customersData } = useQuery<{
    customers: User[];
  }>(GET_CUSTOMERS, {
    variables: {
      searchString: debouncedValue,
      offset: 0,
      limit: 20,
      sortColumn: '',
      sortOrder: '',
    },
    notifyOnNetworkStatusChange: true,
  });

  const onSubmitSuccess = () => {
    setOpen(false);
    alertBanner({
      type: 'SUCCESS',
      message: 'New claim was successfully created.',
    });
  };

  const onSubmitError = () => {
    setOpen(false);
    alertBanner({
      type: 'WARNING',
      message: 'Something went wrong. Please try again.',
    });
  };

  const [createPolicy, { loading: createPolicyLoading }] = useMutation(
    CREATE_PRIVATE_HEALTH_POLICY,
    {
      refetchQueries: [GET_PRIVATE_POLICIES],
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'none',
      onCompleted: onSubmitSuccess,
      onError: onSubmitError,
    }
  );

  const customerOptions: ComboBoxOption[] =
    customersData?.customers.map(({ id, firstName, lastName, email }) => {
      return {
        id,
        label: `${firstName} ${lastName}, ${email}`,
      };
    }) ?? [];

  const statusOptions: SelectMenuOption<string>[] = [
    {
      label: 'Received',
      id: 'received',
    },
    {
      label: 'Sent',
      id: 'sent',
    },
    {
      label: 'Covered',
      id: 'covered',
    },
    {
      label: 'Missing info',
      id: 'missinginfo',
    },
    {
      label: 'Dropped out',
      id: 'droppedout',
    },
    {
      label: 'Canceled',
      id: 'canceled',
    },
    {
      label: 'Not eligible',
      id: 'noteligible',
    },
  ];

  const onSubmit = (answers: any) => {
    createPolicy({
      variables: {
        ...answers,
        ...(answers.price ? { price: Number(answers.price) } : {}),
      },
    });
  };

  return (
    <>
      <ModalForm
        isOpen={open}
        setIsOpen={setOpen}
        loading={createPolicyLoading}
        submitValidationSchema={CreatePrivateHealthValidationSchema}
        onSubmit={onSubmit}
        submitButtonLabel="Create"
        title="New policy"
        bottomElement={
          <p className="mt-[24px] text-sm text-gray-600">
            Once confirmed, a new policy will be created, and the customer will
            be able to view it on their policy dashboard.
          </p>
        }
        formData={[
          {
            id: 'customerId',
            label: 'Customer',
            renderDetails: {
              type: 'autoSuggest',
              options: customerOptions,
              placeholder: 'Enter name, email or policy number',
              setExternalQuery: (query: string) => {
                setCustomersSearchQuery(query);
              },
              useUnfilteredOptions: true,
            },
            color: 'gray',
            required: true,
            loading: customersLoading,
          },
          {
            id: 'newCustomer',
            label: '',
            renderDetails: {
              type: 'checkbox',
              description:
                'Insured person is different than customer or a new customer account needed',
            },
            color: 'gray',
            required: true,
          },
          {
            id: 'insuredPerson',
            label: 'Insured person',
            renderDetails: {
              type: 'description',
              text: 'If no customer is selected, a new customer account will be created under insured person’s name.',
            },
            required: true,
            fieldDependencies: [
              { fieldId: 'newCustomer', requiredValue: true },
            ],
          },
          {
            id: 'firstName',
            label: 'First name',
            renderDetails: {
              type: 'text',
              placeholder: "Insured person's first name",
            },
            color: 'gray',
            required: true,
            fieldDependencies: [
              { fieldId: 'newCustomer', requiredValue: true },
            ],
          },
          {
            id: 'lastName',
            label: 'Last name',
            renderDetails: {
              type: 'text',
              placeholder: "Insured person's last name",
            },
            color: 'gray',
            required: true,
            fieldDependencies: [
              { fieldId: 'newCustomer', requiredValue: true },
            ],
          },
          {
            id: 'dateOfBirth',
            label: 'Date of birth',
            renderDetails: {
              type: 'date',
              maxDate: new Date().toISOString(),
            },
            color: 'gray',
            required: true,
            fieldDependencies: [
              { fieldId: 'newCustomer', requiredValue: true },
            ],
          },
          {
            id: 'email',
            label: 'Email',
            renderDetails: {
              type: 'text',
              placeholder: 'Enter email of insured person',
            },
            color: 'gray',
            required: true,
            divider: true,
            fieldDependencies: [
              { fieldId: 'newCustomer', requiredValue: true },
            ],
          },
          {
            id: 'provider',
            label: 'Provider',
            renderDetails: {
              type: 'select',
              options: [
                {
                  id: 'HALLESCHE',
                  label: 'Hallesche',
                },
              ],
              placeholder: 'Select provider',
            },
            required: true,
            color: 'gray',
          },
          {
            id: 'status',
            label: 'Status',
            renderDetails: {
              type: 'select',
              options: statusOptions,
              placeholder: 'Select status',
            },
            required: true,
            color: 'gray',
          },
          {
            id: 'startDate',
            label: 'Start date',
            renderDetails: {
              type: 'date',
              maxDate: '',
            },
            color: 'gray',
            required: true,
          },
          {
            id: 'policyNumber',
            label: 'Policy Number',
            renderDetails: {
              type: 'text',
              placeholder: 'Enter policy number',
            },
            color: 'gray',
          },
          {
            id: 'tariff',
            label: 'Tariff',
            renderDetails: {
              type: 'select',
              placeholder: 'Select tariff',
              options: tariffs,
              notifyOnChange: setTariff,
            },
            color: 'gray',
            required: true,
          },
          {
            id: 'deductible',
            label: 'Deductible',
            required: true,
            renderDetails: {
              type: 'select',
              placeholder: 'Select deductible',
              options: getDeductibleOptions(tariff ?? 'NK'),
            },
            color: 'gray',
          },
          {
            id: 'addOns',
            label: 'Add-on',
            renderDetails: {
              type: 'multiSelect',
              placeholder: 'Select add-on(s)',
              options: getAddonsForTariff(tariff ?? 'NK', []).map((option) => {
                return {
                  id: option,
                  label: getAddon(option),
                };
              }),
            },
            color: 'gray',
          },
          {
            id: 'price',
            label: 'Price',
            renderDetails: {
              type: 'currency',
              placeholder: '0.00',
            },
            color: 'gray',
          },
          {
            id: 'file',
            label: 'Upload document',
            renderDetails: {
              type: 'upload',
            },
            color: 'gray',
          },
        ]}
      />

      <Button buttonType="primary" onClick={() => setOpen(true)}>
        New policy
      </Button>
    </>
  );
};

export default CreatePolicy;
