import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';

import * as styles from './styles';

type DropdownColor = 'white' | 'gray';

export interface ComboBoxOption {
  id: string;
  label: string;
}

interface ComboBoxProps {
  disabled?: boolean;
  options: ComboBoxOption[];
  selectedOption: ComboBoxOption | ComboBoxOption[] | undefined;
  setSelectedOption:
    | ((newOption: ComboBoxOption) => void)
    | ((newOptions: ComboBoxOption[]) => void);
  placeholder: string;
  label?: string;
  multiple?: boolean;
  className?: string;
  useExternalQuery?: boolean;
  setExternalQuery?: (query: string) => void;
  color?: DropdownColor;
  error?: string;
  useUnfilteredOptions?: boolean;
  id?: string;
  dataTestId?: string;
}

const ComboBox = ({
  disabled,
  options,
  selectedOption,
  setSelectedOption,
  placeholder,
  label,
  multiple = false,
  className,
  useExternalQuery = false,
  setExternalQuery,
  color = 'white',
  error,
  useUnfilteredOptions = false,
  id,
  dataTestId,
}: ComboBoxProps) => {
  const getQuery = (newOptions: ComboBoxOption[] | ComboBoxOption) => {
    if (useExternalQuery) {
      if (Array.isArray(newOptions)) {
        return newOptions.map((option) => option.label).join(', ');
      }
      return newOptions.label;
    }
    return '';
  };

  const [query, setQuery] = useState(
    selectedOption ? getQuery(selectedOption) : ''
  );

  const handleQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    if (useExternalQuery) {
      setExternalQuery?.(e.target.value);
    }
  };

  const handleOptionChange = (
    newOptions: ComboBoxOption[] & ComboBoxOption
  ) => {
    setSelectedOption(newOptions);
    if (useExternalQuery) {
      setQuery(getQuery(newOptions));
    }
  };

  useEffect(() => {
    if (useExternalQuery && selectedOption) {
      setQuery(getQuery(selectedOption));
    }
  }, [selectedOption]);

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
          return option.label
            .toLowerCase()
            .includes(query?.toLowerCase() ?? '');
        });

  const mappableOptions = useUnfilteredOptions ? options : filteredOptions;

  return (
    <Combobox
      value={selectedOption}
      onChange={handleOptionChange}
      disabled={disabled}
      multiple={multiple}
    >
      {({ open }) => (
        <>
          {label && (
            <Combobox.Label className={styles.label}>{label}</Combobox.Label>
          )}
          <div className={classNames(styles.selectWrapper)}>
            <Combobox.Input
              className={classNames(styles.selected, className, {
                [styles.noSelection]: selectedOption === undefined,
                [styles.disabledWhite]: disabled && color === 'white',
                [styles.disabledGray]: disabled && color === 'gray',
                [styles.selectedWhite]: color === 'white',
                [styles.selecteddGray]: color === 'gray',
                [styles.errorState]: error,
              })}
              onChange={handleQueryChange}
              id={id}
              displayValue={(option: ComboBoxOption | ComboBoxOption[]) => {
                if (useExternalQuery && query) {
                  return query;
                }
                if (!option) return '';
                if (Array.isArray(option)) {
                  return option.map((item) => item.label).join(', ');
                }
                return option.label;
              }}
              placeholder={placeholder}
            />
            <Combobox.Button
              className={styles.selectButton}
              data-testid={dataTestId}
            >
              <SelectorIcon className={styles.selectIcon} aria-hidden="true" />
            </Combobox.Button>
            {error && <p className={styles.errorMessage}>{error}</p>}

            {mappableOptions.length > 0 && (
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Combobox.Options
                  className={classNames(styles.optionsList, {
                    [styles.selectedWhite]: color === 'white',
                    [styles.selecteddGray]: color === 'gray',
                  })}
                >
                  {mappableOptions.map((option) => (
                    <Combobox.Option
                      key={option.id}
                      value={option}
                      className={({ active }) =>
                        classNames(styles.listOption, {
                          [styles.listOptionHover]: active,
                        })
                      }
                    >
                      {({ active, selected }) => (
                        <div>
                          <span
                            className={classNames({
                              [styles.listOptionTextSelected]: selected,
                              [styles.listOptionText]: !selected,
                              [styles.listOptionTextHover]: active,
                            })}
                          >
                            {option.label}
                          </span>

                          {selected && (
                            <span
                              className={classNames(
                                { [styles.checkIconHover]: active },
                                styles.checkIcon
                              )}
                            >
                              <CheckIcon
                                className={styles.icon}
                                aria-hidden="true"
                              />
                            </span>
                          )}
                        </div>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              </Transition>
            )}
          </div>
        </>
      )}
    </Combobox>
  );
};

export default ComboBox;
