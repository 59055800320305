import { useMutation } from '@apollo/client';
import { Trans, useTranslation } from '@getpopsure/i18n-react';
import FileErrorCard from 'components/FileErrorCard';
import { GET_PUBLIC_PROVIDER_POLICY } from 'graphql/publicHealth/provider/queries';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import { useState } from 'react';
import { alertBanner } from 'shared/reactiveVariables';

import { RESET_COMMISSION_ENTITLEMENT } from '../../../../../../graphql/publicHealth/internal/mutations';
import { ResetCommissionEntitlementModal } from '../ResetCommissionModal/ResetCommissionModal';

interface CommissionResetCardProps {
  policy: PublicHealthPolicy;
}

export const CommissionResetCard = ({ policy }: CommissionResetCardProps) => {
  const { t } = useTranslation();

  const [resetCommission, { loading }] = useMutation(
    RESET_COMMISSION_ENTITLEMENT,
    {
      refetchQueries: [GET_PUBLIC_PROVIDER_POLICY],
      onCompleted: () => {
        setOpenResetModal(false);
        alertBanner({
          type: 'SUCCESS',
          message: t(
            'admin.internal.policy.public.commissioncheck.approve.success.label',
            'Confirmation successful. Commission entitlement has been restarted successfully.'
          ),
        });
      },
      onError: () => {
        setOpenResetModal(false);
        alertBanner({
          type: 'WARNING',
          message: t(
            'admin.internal.policy.public.commissioncheck.error.label',
            'Commission could not be reset.'
          ),
        });
      },
    }
  );

  const [openResetModal, setOpenResetModal] = useState(false);

  const handleClickToReset = () => {
    setOpenResetModal(true);
  };

  const handleChangeCommission = () => {
    resetCommission({
      variables: {
        policyId: policy.id,
      },
    });
  };

  return (
    <>
      <FileErrorCard
        open={['ACCEPTED', 'DECLINED'].includes(policy.commissionEntitlement)}
        title={t(
          'admin.internal.policy.public.commissioncheck.title',
          'Reset the commission entitlement'
        )}
        errorType="WARNING"
        handleClose={() => {}}
        className="!mt-0 mb-4"
        description={
          <>
            <Trans i18nKey="admin.internal.policy.public.commissioncheck.description">
              Resetting the commission entitlement is needed in case it was
              ACCEPTED or DECLINED by mistake. This will allow the provider to
              set the correct value.
            </Trans>
          </>
        }
        hideCloseButton={true}
        showActionButtons={true}
        primaryButton={{
          text: t(
            'admin.internal.policy.public.commissioncheck.reset.label',
            'Reset'
          ),
          onClick: handleClickToReset,
        }}
      />
      <ResetCommissionEntitlementModal
        open={openResetModal}
        setOpen={setOpenResetModal}
        handleConfirm={handleChangeCommission}
        loading={loading}
      />
    </>
  );
};
