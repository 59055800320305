import dayjs from 'dayjs';
import { DATE_FORMAT } from 'models/date';

import TableCell, { TableCellProps } from '../TableCell';

const TableCellDate = ({
  link,
  cell,
  header,
  textColor,
  divider,
  resourceOnClick,
}: TableCellProps) => {
  const date = cell ? dayjs(cell.toString()).format(DATE_FORMAT) : '';
  return (
    <TableCell
      cell={date}
      header={header}
      textColor={textColor}
      divider={divider}
      link={link}
      resourceOnClick={resourceOnClick}
    />
  );
};

export default TableCellDate;
