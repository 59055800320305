import { useMutation } from '@apollo/client';
import Button from 'components/Button';
import { ADD_COMPANY } from 'graphql/admin/companies/mutations';
import { GET_COMPANIES } from 'graphql/admin/companies/queries';
import { useState } from 'react';
import { alertBanner } from 'shared/reactiveVariables';

import CreateCompanyForm from '../CreateCompanyForm';

const CreateCompanyButton = () => {
  const [createCompany, { loading, error, reset }] = useMutation(ADD_COMPANY, {
    refetchQueries: [GET_COMPANIES],
    onCompleted: () => {
      setForm(false);
      alertBanner({
        type: 'SUCCESS',
        message: 'A new company was successfully added.',
      });
    },
    onError: () => {
      setForm(false);
      alertBanner({
        type: 'WARNING',
        message: 'Something went wrong. Please try again.',
      });
    },
  });
  const [form, setForm] = useState(false);

  const handleCreateCompany = async (variables: {
    name: string;
    type: string;
    sources: string;
  }) => {
    const sourcesArray = variables.sources
      .split(',')
      .map((source) => source.trim());

    createCompany({
      variables: {
        ...variables,
        sources: sourcesArray,
      },
    });
  };

  const handleButtonClick = () => {
    openForm(true);
  };

  const openForm = (open: boolean) => {
    setForm(open);
    if (open) {
      reset();
    }
  };

  return (
    <>
      <Button buttonType="primary" onClick={handleButtonClick}>
        Add company
      </Button>
      {form && (
        <CreateCompanyForm
          open={form}
          setOpen={openForm}
          createCompany={handleCreateCompany}
          loading={loading}
          errorMessage={error?.message}
        />
      )}
    </>
  );
};

export default CreateCompanyButton;
