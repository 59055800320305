import { useMutation } from '@apollo/client';
import { GENERATE_CLAIM_NUMBER } from 'pages/claims/template/components/ClaimsOverview/graphql/mutations';
import { useState } from 'react';
import { alertBanner } from 'shared/reactiveVariables';

import { TableCellButton } from './TableCellButton';

interface TableCellClaimNumberProps {
  data: unknown;
  getClaimDetails: (data: any) => {
    id: string;
    claimNumber: string | null;
  };
}

export const TableCellClaimNumber = ({
  data,
  getClaimDetails,
}: TableCellClaimNumberProps) => {
  const [newClaimNumber, setNewClaimNumber] = useState<string | undefined>();
  const [generateClaimNumber] = useMutation(GENERATE_CLAIM_NUMBER, {
    onCompleted: (res) => {
      alertBanner({
        type: 'SUCCESS',
        message: 'Claim number successfully generated.',
      });
      setNewClaimNumber(res.generateClaimNumber);
    },
    onError: () =>
      alertBanner({
        type: 'WARNING',
        message: 'Failed to generate claim number. Please try again.',
      }),
  });

  const { id, claimNumber } = getClaimDetails(data);

  if (claimNumber) return <span>{claimNumber}</span>;
  if (newClaimNumber) return <span>{newClaimNumber}</span>;

  return (
    <TableCellButton
      data={{ id }}
      getButtonText={() => 'Generate claim number'}
      onClick={() => generateClaimNumber({ variables: { claimId: id } })}
    />
  );
};
