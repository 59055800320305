import { DocumentsSectionData } from 'components/DocumentsCard';
import { SelectMenuOption } from 'components/SelectMenu';
import { retrieveFileFriendlyName } from 'shared/utils/retrieveFileFriendlyName';

import {
  BikeDocumentType,
  BikeUploadDocumentType,
} from '../models/BikeDocumentType';
import { BikePolicyData } from '../models/BikePolicy';

export const fileTypeOptions: SelectMenuOption<BikeUploadDocumentType>[] = [
  {
    id: 'POLICY_DOCUMENTS',
    label: 'Policy documents',
  },
  {
    id: 'CUSTOMER_UPLOADS',
    label: 'Customer uploads',
  },
];

export const getDocumentSections = (
  data?: BikePolicyData
): DocumentsSectionData<BikeDocumentType>[] => {
  const policyDocuments: DocumentsSectionData<BikeDocumentType> = {
    title: 'Policy documents',
    documents: [],
  };

  const customerUploads: DocumentsSectionData<BikeDocumentType> = {
    title: 'Customer uploads',
    documents: [],
  };

  data?.bikePolicy.policyDocuments.forEach(
    ({ url, name, resizedUrl, blobName, id }) => {
      policyDocuments.documents.push({
        id,
        fileLink: url,
        fileName: name,
        resizedUrl,
        documentType: 'POLICY_DOCUMENT',
        canDelete: true,
        blobName,
      });
    }
  );

  data?.bikePolicy.internalDocuments.forEach(
    ({ url, name, resizedUrl, blobName, id }) => {
      policyDocuments.documents.push({
        id,
        fileLink: url,
        fileName: name,
        resizedUrl,
        documentType: 'INTERNAL_POLICY_DOCUMENT',
        canDelete: true,
        blobName,
      });
    }
  );

  data?.bikePolicy.bikePolicyDocuments.forEach(({ url, blobName, id }) => {
    policyDocuments.documents.push({
      id,
      fileLink: url,
      fileName: retrieveFileFriendlyName(blobName),
      resizedUrl: url,
      documentType: 'BIKE_DOCUMENT',
      canDelete: true,
      blobName,
    });
  });

  data?.bikePolicy.bikeCustomerUploads.forEach(({ url, blobName }) => {
    customerUploads.documents.push({
      fileLink: url,
      fileName: retrieveFileFriendlyName(blobName),
      resizedUrl: url,
    });
  });

  data?.bikePolicy.questionnaireDownloadDocuments.forEach(
    ({ url, blobName }) => {
      policyDocuments.documents.push({
        fileLink: url,
        fileName: retrieveFileFriendlyName(blobName),
        resizedUrl: url,
      });
    }
  );

  return [policyDocuments, customerUploads];
};
