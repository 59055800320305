import { useMutation } from '@apollo/client';
import Button from 'components/Button';
import { RESET_ADMIN_USER_PASSWORD } from 'graphql/admin/users/mutations';
import { alertBanner } from 'shared/reactiveVariables';

const ResetPasswordButton = ({ adminUserId }: { adminUserId: string }) => {
  const [resetPassword, { loading }] = useMutation(RESET_ADMIN_USER_PASSWORD, {
    variables: { id: adminUserId },
    onCompleted: () => {
      alertBanner({
        type: 'SUCCESS',
        message: 'The password was successfully reset.',
      });
    },
    onError: () => {
      alertBanner({
        type: 'WARNING',
        message: 'Something went wrong. Please try again.',
      });
    },
  });

  const handleResetPasswordClick = () => {
    resetPassword().catch(() => {});
  };

  return (
    <Button
      buttonType="white"
      loading={loading}
      onClick={handleResetPasswordClick}
    >
      Reset password
    </Button>
  );
};

export default ResetPasswordButton;
