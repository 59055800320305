import { TFunction } from '@getpopsure/i18n-react';
import { Country } from 'shared/countries/models';

import { getBikePoliciesTemplateData } from '../bike';
import { getDentalPoliciesTemplateData } from '../dental';
import { getDogLiabilityPoliciesTemplateData } from '../dogLiability';
import { getExpatSpainPoliciesTemplateData } from '../expatSpain';
import { getGenericPoliciesTemplateData } from '../generic/data/templateData';
import { getHouseholdPoliciesTemplateData } from '../household';
import { getCompanyHealthPoliciesHRTemplateData } from '../hr/companyHealth';
import { getCompanyLifePoliciesHRTemplateData } from '../hr/companyLife';
import { getCompanyPensionPoliciesHRTemplateData } from '../hr/companyPension';
import { getPublicHealthPoliciesHRTemplateData } from '../hr/publicHealth';
import { getLegalPoliciesTemplateData } from '../legal';
import { getLiabilityPoliciesTemplateData } from '../liability';
import { getLifePoliciesTemplateData } from '../life';
import {
  HRTemplateInsuranceType,
  TemplateInsuranceType,
} from './models/InsuranceTypes';
import { PolicyTemplateData } from './models/PolicyTemplateData';

export const getTemplateDataByInsuranceType = (
  currentRegion?: Country
): Record<TemplateInsuranceType, PolicyTemplateData<any, any, any>> => ({
  CAR: getGenericPoliciesTemplateData('car'),
  TRAVEL: getGenericPoliciesTemplateData('travel'),
  PET_HEALTH: getGenericPoliciesTemplateData('pet-health'),
  COMPANY_HEALTH: getGenericPoliciesTemplateData('company-health'),
  COMPANY_LIFE: getGenericPoliciesTemplateData('company-life'),
  DISABILITY: getGenericPoliciesTemplateData('disability'),
  PENSION: getGenericPoliciesTemplateData('pension'),
  BASIS_PENSION: getGenericPoliciesTemplateData('basis-pension'),
  INCOMING_ES: getExpatSpainPoliciesTemplateData(),
  TRAVEL_FR: getGenericPoliciesTemplateData('travel-fr'),
  DENTAL: getDentalPoliciesTemplateData(),
  HOUSEHOLD: getHouseholdPoliciesTemplateData(),
  LIABILITY: getLiabilityPoliciesTemplateData(),
  LEGAL: getLegalPoliciesTemplateData(),
  LIFE: getLifePoliciesTemplateData(),
  BIKE: getBikePoliciesTemplateData(),
  DOG_LIABILITY: getDogLiabilityPoliciesTemplateData(),
  GENERIC: getGenericPoliciesTemplateData('generic'),
  INCOMING_EU: getGenericPoliciesTemplateData('expat-health-eu', currentRegion),
  COMPANY_PENSION: getGenericPoliciesTemplateData('company-pension'),
});

export const getTemplateHRDataByInsuranceType = (
  t: TFunction,
  company?: string
): Record<HRTemplateInsuranceType, PolicyTemplateData<any, any, any>> => ({
  PUBLIC_HEALTH: getPublicHealthPoliciesHRTemplateData(t, company),
  COMPANY_PENSION: getCompanyPensionPoliciesHRTemplateData(t, company),
  COMPANY_HEALTH: getCompanyHealthPoliciesHRTemplateData(t, company),
  COMPANY_LIFE: getCompanyLifePoliciesHRTemplateData(t, company),
});
