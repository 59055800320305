import { gql } from '@apollo/client';

export const GET_GENERIC_CLAIM = gql`
  query GetGenericClaim($insuranceType: String!, $claimId: String!) {
    genericClaim(insuranceType: $insuranceType, claimId: $claimId) {
      id
      user {
        id
        firstName
        lastName
        email
        isDelinquent
        isFraudulent
        fraudulentAdditionalInfo
      }
      intercomEuUserId
      stripeCustomerToken
      status
      createdAt
      amount
      iban
      refundAmount
      featherPaidOn
      customerPaidOn
      thirdPartyPaidOn
      claimNumber
      dateOfEvent
      eventDescription
      claimType {
        id
        type
      }
      policy {
        id
        publicStatus
        startDate
        policyNumber
        activeUntil
        price
      }
      documents {
        attachments {
          id
          url
          blobName
          resizedUrl
        }
        providerAttachments {
          id
          url
          blobName
          resizedUrl
        }
        userUploads {
          id
          url
          blobName
          resizedUrl
        }
        scannedDocuments {
          id
          url
          blobName
        }
        internalDocuments {
          id
          url
          blobName
        }
      }

      issues {
        id
        category
        description
        status
        title
        createdAt
        updatedAt
        author {
          firstName
          lastName
        }

        tasks {
          id
          description
          status
          data
          createdAt
          finishedAt
        }
      }

      pinnedNotes {
        id
        body
        createdAt
        adminUser {
          firstName
          lastName
        }
        updatedAt
      }
    }
  }
`;
