import { ClaimPaymentTransaction } from '../models/ClaimPaymentTransaction';
import { TransactionInsuranceType } from '../models/TransactionInsuranceType';

export const useClaimAmountsValidation = (
  insuranceType: TransactionInsuranceType
) => {
  const validateExpatClaimAmounts = (
    claim?: { refundAmount?: number; amount?: number },
    transaction?: ClaimPaymentTransaction
  ) => {
    if (!claim || !transaction) return true;
    return claim.refundAmount === transaction.qontoTransaction?.amount;
  };

  const validateDentalClaimAmounts = (
    claim?: { refundAmount?: number; amount?: number },
    transaction?: ClaimPaymentTransaction
  ) => {
    if (!claim || !transaction) return true;
    return claim.amount === transaction.qontoTransaction?.amount;
  };

  switch (insuranceType) {
    case 'INCOMING':
      return {
        validateClaimAmounts: validateExpatClaimAmounts,
        description: "Received amount doesn't match with refunded amount",
        exportWarning:
          'transactions(s) have mismatch between received and refunded amount.',
      };
    case 'DENTAL':
      return {
        validateClaimAmounts: validateDentalClaimAmounts,
        description: "Received amount doesn't match with claimed amount",
        exportWarning:
          'transactions(s) have mismatch between received and claimed amount.',
      };
  }
};
