import { DocumentNode, useMutation } from '@apollo/client';
import { SelectMenuOption } from 'components/SelectMenu';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { alertBanner } from 'shared/reactiveVariables';

import InformationCard, { InformationRow } from '..';

export interface ConfirmationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleConfirm: () => void;
  loading: boolean;
  newFormData: DataForm;
  additionalData?: DataForm;
  oldValues?: { id: string; value: string | number }[];
}

interface EditableInformationCardProps {
  sections: {
    rows: InformationRow[];
    title: string;
  }[];
  editableData: DataForm;
  mutation: DocumentNode;
  resourceId: string;
  ConfirmationModal?: React.ElementType<ConfirmationModalProps>;
  oldConfirmationValue?: { id: string; value: string | number }[];
  refetchQueries?: DocumentNode[];
  onCompleted?: (data: unknown) => void;
  keepOpen?: boolean;
  saveButtonLabel?: string;
  discardButtonLabel?: string;
  confirmationModalAdditionalData?: DataForm;
  extraVariables?: Record<string, any>;
  notifyChangeId?: string;
  notifyChangeFunction?: (value: string) => void;
  successMessage: string;
  onDiscard?: () => void;
}

export interface DataForm {
  [key: string]:
    | string
    | undefined
    | null
    | boolean
    | string[]
    | number
    | Record<string, any>;
}

export interface DataErrors {
  [key: string]: boolean;
}

const EditableInformationCard = ({
  sections,
  editableData,
  mutation,
  resourceId,
  ConfirmationModal,
  oldConfirmationValue,
  refetchQueries,
  onCompleted,
  keepOpen = false,
  saveButtonLabel,
  discardButtonLabel,
  confirmationModalAdditionalData,
  extraVariables,
  notifyChangeFunction,
  notifyChangeId,
  successMessage,
  onDiscard,
}: EditableInformationCardProps) => {
  const getInitialErrorState = () => {
    const errors: DataErrors = {};
    Object.entries(editableData).forEach((field) => {
      errors[field[0]] = false;
    });
    return errors;
  };

  const [editMode, setEditMode] = useState(keepOpen);
  const [form, setForm] = useState<DataForm>(editableData);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [updateResource, { loading, error, data, reset }] = useMutation(
    mutation,
    {
      ...(refetchQueries && { refetchQueries }),
      onCompleted: (data) => {
        onCompleted?.(data);
        alertBanner({ type: 'SUCCESS', message: successMessage });
      },
      onError: () => {
        alertBanner({
          type: 'WARNING',
          message: 'Something went wrong. Please try again.',
        });
      },
      errorPolicy: 'none',
    }
  );
  const [errors, setErrors] = useState<DataErrors>(getInitialErrorState());

  useEffect(() => {
    if (!loading && !error && data) {
      setEditMode(keepOpen);
    }
  }, [loading, error, data]);

  useEffect(() => {
    setForm(editableData);
  }, [editableData]);

  useEffect(() => {
    setErrors(getInitialErrorState());
  }, [form]);

  useEffect(() => {
    notifyChangeFunction?.(form[notifyChangeId ?? '']?.toString() ?? '');
  }, [form[notifyChangeId ?? '']]);

  const handleChangeOnInput = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const handleChangeOnTextbox = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const handleMultipleLineChangeOnInput = (
    e: ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    setForm({
      ...form,
      [id]: {
        ...(form[id] as {}),
        [e.target.id]: e.target.value,
      },
    });
  };

  const handleNumberChangeOnInput = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value !== '' ? Number(e.target.value) : null,
    });
  };

  const handleDateChange = (date: Date, id: string) => {
    setForm({
      ...form,
      [id]: date.toISOString(),
    });
  };

  const handleDropdownChange = (option: SelectMenuOption, id: string) => {
    setForm({
      ...form,
      [id]: option.id,
    });
  };

  const handleMultipleDropdownChange = (
    options: SelectMenuOption[],
    id: string
  ) => {
    setForm({
      ...form,
      [id]: options.map((option) => option.id).join(', '),
    });
  };

  const handleToggleChange = (value: string, id: string) => {
    setForm({
      ...form,
      [id]: value,
    });
  };

  const handleArrayChange = (value: string[], id: string) => {
    setForm({
      ...form,
      [id]: value,
    });
  };

  const handleClickOnEdit = () => {
    setEditMode(true);
  };

  const handleClickOnDiscard = () => {
    onDiscard?.();
    reset();
    setEditMode(keepOpen);
    setForm(editableData);
  };

  const handleOpenConfirmationModal = (e: FormEvent) => {
    e.preventDefault();
    setConfirmationModalOpen(true);
  };

  const handleClickOnSave = (e: FormEvent) => {
    e.preventDefault();
    handleSaveData();
  };

  const checkRequiredFields = () => {
    let result = true;
    for (const section of sections) {
      for (const row of section.rows) {
        if (row.required && !form[row.id]) {
          setErrors((prev) => ({ ...prev, [row.id]: true }));
          result = false;
        }

        if (row.zodValidationSchema) {
          const validation = row.zodValidationSchema.safeParse({
            [row.id]: form[row.id],
          });

          if (!validation.success) {
            setErrors((prev) => ({ ...prev, [row.id]: true }));
            result = false;
          }
        }
      }
    }

    return result;
  };

  // TODO: [KONG] Refactor for array types to be controlled at higher level
  const handleSaveData = () => {
    if (!checkRequiredFields()) {
      return;
    }

    let sources: string[] = [];
    let roles: string[] = [];

    let claimType: string[] = [];
    if (form.sources) {
      sources = form.sources
        .toString()
        .split(',')
        .map((source) => source.trim());
    }
    if (form.roles) {
      roles = form.roles
        .toString()
        .split(',')
        .map((role) => role.trim());
    }
    if (form.claimType) {
      claimType = form.claimType
        .toString()
        .split(',')
        .map((type) => type.trim());
    }

    updateResource({
      variables: {
        ...form,
        ...extraVariables,
        // TODO: [KONG] Remove and replace with variables object
        id: resourceId,
        sources,
        roles,
        ...(form.claimType ? { claimType } : {}),
        ...(form.isFeather && {
          isFeather: form.isFeather.toString().toUpperCase() === 'YES',
        }),
        ...(form.hasAccessToNewAdminPanel && {
          hasAccessToNewAdminPanel:
            form.hasAccessToNewAdminPanel.toString().toUpperCase() === 'YES',
        }),
        ...(form.hasAccessToOldAdminPanel && {
          hasAccessToOldAdminPanel:
            form.hasAccessToOldAdminPanel.toString().toUpperCase() === 'YES',
        }),
        ...(form.active && {
          active: form.active.toString().toUpperCase() === 'YES',
        }),
        ...(form.isDelinquent && {
          isDelinquent: form.isDelinquent.toString().toUpperCase() === 'YES',
        }),
        ...(form.isFraudulent && {
          isFraudulent: form.isFraudulent.toString().toUpperCase() === 'YES',
        }),
        ...(form.isFraudulent &&
          form.isFraudulent.toString().toUpperCase() === 'NO' && {
            fraudulentAdditionalInfo: '-',
          }),
      },
    })
      .catch(() => {})
      .then(() => {
        setConfirmationModalOpen(false);
        notifyChangeFunction?.('');
      });
  };

  const checkIfEditableFields = (): boolean => {
    for (const section of sections) {
      if (section.rows.some((row) => row.editable)) return true;
    }

    return false;
  };

  const checkOpenConfirmationModal = (): boolean => {
    if (!ConfirmationModal) {
      return false;
    }

    if (oldConfirmationValue?.length === 0) {
      return true;
    }

    let valueChange = false;

    oldConfirmationValue?.forEach((oldVal) => {
      if (form[oldVal.id] !== oldVal.value) {
        valueChange = true;
      }
    });

    return valueChange;
  };

  return (
    <>
      {ConfirmationModal && (
        <ConfirmationModal
          open={confirmationModalOpen}
          setOpen={setConfirmationModalOpen}
          handleConfirm={handleSaveData}
          loading={loading}
          newFormData={form}
          additionalData={confirmationModalAdditionalData}
          oldValues={oldConfirmationValue}
        />
      )}
      <InformationCard
        sections={sections}
        editable={checkIfEditableFields()}
        editMode={editMode}
        handleChangeOnInput={handleChangeOnInput}
        handleChangeOnTextbox={handleChangeOnTextbox}
        handleNumberChangeOnInput={handleNumberChangeOnInput}
        handleClickOnDiscard={handleClickOnDiscard}
        handleClickOnEdit={handleClickOnEdit}
        handleClickOnSave={
          checkOpenConfirmationModal()
            ? handleOpenConfirmationModal
            : handleClickOnSave
        }
        handleDateChange={handleDateChange}
        handleDropdownChange={handleDropdownChange}
        handleMultipleDropdownChange={handleMultipleDropdownChange}
        handleToggleChange={handleToggleChange}
        handleArrayChange={handleArrayChange}
        handleMultipleLineChangeOnInput={handleMultipleLineChangeOnInput}
        form={form}
        errorMessage={error?.message}
        errors={errors}
        saveButtonLabel={saveButtonLabel}
        discardButtonLabel={discardButtonLabel}
      />
    </>
  );
};

export default EditableInformationCard;
