import dayjs from 'dayjs';
import { PublicHealthQuestionnaireAnswers } from 'models/publicHealthQuestionnaire';
import { QuestionnaireAnswers } from 'models/questionnaire';
import omitDeep from 'omit-deep-lodash';
import { filterNullKeys } from 'shared/filterNullKeys';

export const getEditablePublicQuestionnaireAnswers = (
  answers: QuestionnaireAnswers
) => {
  if (!answers) return answers;

  const publicAnswers = answers as PublicHealthQuestionnaireAnswers;

  const nonNullAnswers = filterNullKeys(answers);

  return {
    ...omitDeep(nonNullAnswers ?? {}, '__typename', 'unknownKeys'),
    ...(nonNullAnswers?.unknownKeys as object),
    ...(publicAnswers?.dateOfBirth && {
      dateOfBirth: dayjs(publicAnswers?.dateOfBirth).format('YYYY-MM-DD'),
    }),
    ...(publicAnswers?.coverageStartDate && {
      coverageStartDate: dayjs(publicAnswers?.coverageStartDate).format(
        'YYYY-MM-DD'
      ),
    }),
    ...(publicAnswers?.jobStartDate && {
      jobStartDate: dayjs(publicAnswers?.jobStartDate).format('YYYY-MM-DD'),
    }),
    ...(publicAnswers?.universityStartDate && {
      universityStartDate: dayjs(publicAnswers?.universityStartDate).format(
        'YYYY-MM-DD'
      ),
    }),
    ...(publicAnswers?.selfEmploymentStartDate && {
      selfEmploymentStartDate: dayjs(
        publicAnswers?.selfEmploymentStartDate
      ).format('YYYY-MM-DD'),
    }),

    employerName: publicAnswers?.employerName,
  };
};
