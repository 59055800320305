import { useMutation } from '@apollo/client';
import Button from 'components/Button';
import SelectButton, { SelectButtonOption } from 'components/SelectButton';
import { UPDATE_POLICY_STATUS } from 'graphql/publicHealth/internal/mutations';
import { GET_PUBLIC_POLICY } from 'graphql/publicHealth/internal/queries';
import { Issue } from 'models/issue';
import ChangeStatusWithFileUpload from 'pages/policies/publicHealth/components/ChangeStatusWithFileUpload';
import ChangeStatusWithIssue from 'pages/policies/publicHealth/components/ChangeStatusWithIssue';
import ChangeStatusWithOptionalIssue from 'pages/policies/publicHealth/components/ChangeStatusWithOptionalIssue';
import { useState } from 'react';
import { filterStatusChangeOptions } from 'shared/filterStatusChangeOptions';
import {
  InternalPublicHealthStatus,
  InternalPublicHealthStatusId,
} from 'shared/mapInternalPublicHealthStatus';
import { alertBanner } from 'shared/reactiveVariables';

interface ChangeStatusProps {
  status: InternalPublicHealthStatus;
  id: string;
  defaultStartDate: string;
  confirmationOfCoverage?: string;
  openIssues?: Issue[];
}

const ChangeStatus = ({
  status,
  id,
  defaultStartDate,
  confirmationOfCoverage,
  openIssues,
}: ChangeStatusProps) => {
  const statusOptions: SelectButtonOption[] = [
    {
      title: 'Uni check',
      label: 'Uni check',
      id: 'unicheck',
      color: 'red',
    },
    {
      title: 'Received',
      label: 'Received',
      id: 'received',
      color: 'purple',
    },
    {
      title: 'Sent',
      label: 'Sent',
      id: 'sent',
      color: 'blue',
    },
    {
      title: 'Processing',
      label: 'Processing',
      id: 'processing',
      color: 'blue',
    },
    {
      title: 'Covered',
      label: 'Covered',
      id: 'covered',
      color: 'green',
    },
    {
      title: 'Missing info',
      label: 'Missing info',
      id: 'missinginfo',
      color: 'pink',
    },
    {
      title: 'Not eligible',
      label: 'Not eligible',
      id: 'noteligible',
      color: 'yellow',
    },
    {
      title: 'Canceled',
      label: 'Canceled',
      id: 'canceled',
      color: 'gray',
    },
  ];

  const [updateStatus, { loading }] = useMutation(UPDATE_POLICY_STATUS, {
    refetchQueries: [GET_PUBLIC_POLICY],
    awaitRefetchQueries: true,
    onCompleted: () => {
      alertBanner({
        type: 'SUCCESS',
        message: 'Status successfully updated.',
      });
    },
    onError: () => {
      alertBanner({
        message: 'Something went wrong. Please try again.',
        type: 'WARNING',
      });
    },
  });

  const [formWithFile, setFormWithFile] = useState(false);
  const [formWithIssue, setFormWithIssue] = useState(false);
  const [formWithOptionalIssue, setFormWithOptionalIssue] = useState(false);
  const [newStatus, setNewStatus] = useState<InternalPublicHealthStatus | null>(
    null
  );

  const currentStatus =
    statusOptions.find((element) => element.id === status.id) ??
    statusOptions[0];

  let currentStatusOptions: SelectButtonOption[] = [];

  switch (currentStatus.id) {
    case 'unicheck':
      currentStatusOptions = filterStatusChangeOptions(
        [
          'received',
          'sent',
          'processing',
          'covered',
          'missinginfo',
          'canceled',
        ],
        statusOptions
      );
      break;
    case 'received':
      currentStatusOptions = filterStatusChangeOptions(
        ['sent', 'unicheck', 'processing', 'covered', 'noteligible'],
        statusOptions
      );
      break;
    case 'sent':
      currentStatusOptions = filterStatusChangeOptions(
        ['unicheck', 'received'],
        statusOptions
      );
      break;
    case 'processing':
      currentStatusOptions = filterStatusChangeOptions(
        ['received', 'unicheck', 'sent'],
        statusOptions
      );
      break;
    case 'covered':
      currentStatusOptions = filterStatusChangeOptions(
        [
          'received',
          'unicheck',
          'sent',
          'processing',
          'missinginfo',
          'noteligible',
        ],
        statusOptions
      );
      break;
    case 'missinginfo':
      currentStatusOptions = filterStatusChangeOptions(
        ['received', 'unicheck', 'sent'],
        statusOptions
      );
      break;
    case 'noteligible':
      currentStatusOptions = filterStatusChangeOptions(
        ['received', 'unicheck', 'sent', 'covered', 'missinginfo', 'canceled'],
        statusOptions
      );
      break;
    case 'canceled':
      currentStatusOptions = filterStatusChangeOptions(
        [
          'received',
          'unicheck',
          'sent',
          'covered',
          'missinginfo',
          'noteligible',
        ],
        statusOptions
      );
      break;
  }

  const handleStatusChange = (option: SelectButtonOption) => {
    if (option.id === status.id) {
      return;
    }

    setNewStatus({
      id: option.id as InternalPublicHealthStatusId,
      text: option.label,
    });
    if (option.id === 'covered') {
      setFormWithFile(true);
      return;
    }

    if (
      option.id === 'processing' &&
      (currentStatus.id === 'canceled' || currentStatus.id === 'noteligible')
    ) {
      setFormWithIssue(true);
      return;
    }

    if (option.id === 'processing') {
      setFormWithOptionalIssue(true);
      return;
    }

    if (
      option.id === 'missinginfo' ||
      option.id === 'canceled' ||
      option.id === 'noteligible'
    ) {
      setFormWithIssue(true);
    }

    if (option.id === 'sent') {
      setFormWithOptionalIssue(true);
    }
  };

  const handleUpdateStatus = (
    variables: {
      status: string;
      category?: string;
      description?: string;
      isCustomerInformed?: boolean;
      file?: File;
      startDate?: string;
      issueIds?: string[];
    },
    formId: string
  ) => {
    updateStatus({
      variables: {
        id,
        fileType: 'CONFIRMATION_OF_COVERAGE_FROM_PROVIDER',
        newIssueStatus: 'resolved',
        ...variables,
      },
    })
      .catch(() => {})
      .then(() => {
        switch (formId) {
          case 'formWithFile':
            setFormWithFile(false);
            break;
          case 'formWithIssue':
            setFormWithIssue(false);
            break;
          case 'formWithOptionalIssue':
            setFormWithOptionalIssue(false);
        }
      });
  };

  return (
    <>
      {formWithIssue && (
        <ChangeStatusWithIssue
          open={formWithIssue}
          setOpen={setFormWithIssue}
          updateStatus={handleUpdateStatus}
          newStatus={newStatus}
          openIssues={openIssues}
          loading={loading}
        />
      )}
      {formWithFile && (
        <ChangeStatusWithFileUpload
          open={formWithFile}
          setOpen={setFormWithFile}
          updateStatus={handleUpdateStatus}
          newStatus={newStatus}
          defaultStartDate={defaultStartDate}
          confirmationOfCoverage={confirmationOfCoverage}
          openIssues={openIssues}
          loading={loading}
        />
      )}
      {formWithOptionalIssue && (
        <ChangeStatusWithOptionalIssue
          open={formWithOptionalIssue}
          setOpen={setFormWithOptionalIssue}
          updateStatus={handleUpdateStatus}
          newStatus={newStatus}
          loading={loading}
          descriptionText={
            newStatus?.id === 'sent'
              ? 'After you send the application to the provider, the status will change.'
              : undefined
          }
          title={newStatus?.id === 'sent' ? 'Send to provider?' : undefined}
          confirmText={newStatus?.id === 'sent' ? 'Send' : undefined}
          openIssues={openIssues}
        />
      )}
      <SelectButton
        withDot={true}
        options={currentStatusOptions}
        handleOnChange={handleStatusChange}
        selected={currentStatus}
        disabled={currentStatusOptions.length === 0 || loading}
        label="Status"
      />
      {(status.id === 'received' || status.id === 'unicheck') && (
        <>
          <Button
            buttonType="primary"
            onClick={() => handleStatusChange({ id: 'sent', label: 'Sent' })}
            className="ml-4"
          >
            Send to provider
          </Button>
        </>
      )}
    </>
  );
};

export default ChangeStatus;
