import { useReactiveVar } from '@apollo/client';
import {
  AlertBannerStatus,
  AlertBannerView,
} from 'components/AlertBanner/AlertBanner.view';
import { useEffect, useState } from 'react';
import { alertBanner } from 'shared/reactiveVariables';

export const AlertBanner = () => {
  const alertBannerState = useReactiveVar(alertBanner);

  const [alertBannerStatus, setAlerBannerStatus] =
    useState<AlertBannerStatus>('IDLE');

  useEffect(() => {
    if (!alertBannerState) {
      setAlerBannerStatus('IDLE');
    }

    if (alertBannerState) {
      setAlerBannerStatus('OPEN');
    }
  }, [alertBannerState]);

  useEffect(() => {
    if (alertBannerStatus === 'CLOSE') {
      const timeout = setTimeout(() => {
        alertBanner(null);
      }, 800);

      return () => clearTimeout(timeout);
    }

    if (alertBannerStatus === 'OPEN') {
      const timeout = setTimeout(() => {
        alertBannerState?.onClose?.();
        setAlerBannerStatus('CLOSE');
      }, 5_000);

      return () => clearTimeout(timeout);
    }
  }, [alertBannerStatus]);

  if (alertBannerStatus === 'IDLE') {
    return <></>;
  }

  return (
    <AlertBannerView
      message={alertBannerState?.message ?? ''}
      type={alertBannerState?.type ?? 'SUCCESS'}
      status={alertBannerStatus}
      onClose={() => {
        alertBannerState?.onClose?.();
        setAlerBannerStatus('CLOSE');
      }}
    />
  );
};
