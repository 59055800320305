import { gql } from '@apollo/client';

export const UPLOAD_CLAIM_DOCUMENT = gql`
  mutation UploadClaimDocument(
    $file: Upload!
    $claimId: String!
    $userId: String
    $type: String!
    $newFilename: String
  ) {
    uploadClaimDocument(
      uploadDocument: $file
      claimId: $claimId
      userId: $userId
      type: $type
      fileName: $newFilename
    ) {
      id
      url
      blobName
    }
  }
`;

export const DELETE_CLAIM_DOCUMENT = gql`
  mutation DeleteClaimDocument(
    $claimId: String!
    $id: String!
    $blobName: String!
    $documentType: String!
  ) {
    deleteClaimDocument(
      claimId: $claimId
      id: $id
      blobName: $blobName
      documentType: $documentType
    ) {
      id
      name
      type
      blobName
    }
  }
`;
