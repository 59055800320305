import { ExclamationIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import CalendarDatePicker from 'components/CalendarDatePicker';
import { CheckBox } from 'components/CheckBox';
import ComboBox, { ComboBoxOption } from 'components/ComboBox/ComboBox';
import FileDropzone from 'components/FileDropzone';
import Input from 'components/Input';
import { CurrencyInput } from 'components/Input/CurrencyInput';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import RadioButton from 'components/RadioButton';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import TextArea from 'components/TextArea';
import dayjs from 'dayjs';

import { ModalFormData, SubmitValidationError } from './models';
import * as styles from './styles';

interface Props<SubmittableForm extends Object, SelectOption> {
  title: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onChange: (input: Partial<SubmittableForm>) => void;
  answers: Partial<SubmittableForm>;
  handleSubmit: () => void;
  submissionDisabled: boolean;
  validationError: Partial<SubmitValidationError<SubmittableForm>> | null;
  loading: boolean;
  submitButtonLabel: string;
  formData: ModalFormData<SubmittableForm, SelectOption>[];
  bottomElement?: React.ReactNode;
  scrollable?: boolean;
}

export const ModalFormView = <
  SubmittableForm extends Object,
  SelectOption extends string
>({
  isOpen,
  setIsOpen,
  onChange,
  answers,
  handleSubmit,
  submissionDisabled,
  validationError,
  loading,
  title,
  submitButtonLabel,
  formData,
  bottomElement,
  scrollable,
}: Props<SubmittableForm, SelectOption>) => (
  <Modal
    title={title}
    open={isOpen}
    setOpen={setIsOpen}
    handleConfirm={handleSubmit}
    confirmButtonLabel={submitButtonLabel}
    disabled={submissionDisabled}
    loading={loading}
    scrollable={scrollable ?? formData.length > 5}
  >
    <>
      {formData.map(
        ({
          id,
          label,
          required,
          color,
          renderDetails,
          divider,
          loading: inputLoading,
          fieldDependencies,
        }) => {
          const { type } = renderDetails;
          const answer = answers[id];
          const validationErrorMessage = validationError?.[id];
          const displayField =
            !fieldDependencies ||
            fieldDependencies.every((dependency) => {
              const value =
                answers[dependency.fieldId as keyof SubmittableForm];
              return Boolean(value) === dependency.requiredValue;
            });

          if (!displayField) return;

          return (
            <div className={styles.inputContainer} key={String(id)}>
              <label className={styles.label} htmlFor={String(id)}>
                <div className={styles.labelTitle}>{label}</div>
                {!required && (
                  <span className={styles.labelOptional}>(optional)</span>
                )}
                {inputLoading && <Loader className={styles.loader} />}
              </label>

              {(type === 'text' || type === 'email') && (
                <Input
                  className={styles.input}
                  id={String(id)}
                  type={type}
                  required={required}
                  placeholder={renderDetails.placeholder}
                  color={color ?? 'gray'}
                  onChange={(e) => {
                    onChange({ ...answers, [id]: e.target.value });
                  }}
                  value={answer ? String(answer) : ''}
                  error={Boolean(validationErrorMessage)}
                  errorMessage={validationErrorMessage}
                />
              )}

              {type === 'textarea' && (
                <TextArea
                  className={classNames(styles.input, styles.textAreaInput)}
                  id={String(id)}
                  required={required}
                  placeholder={renderDetails.placeholder}
                  color={color ?? 'gray'}
                  onChange={(e) => {
                    onChange({ ...answers, [id]: e.target.value });
                  }}
                  value={answer ? String(answer) : ''}
                  error={Boolean(validationErrorMessage)}
                  errorMessage={validationErrorMessage}
                />
              )}

              {type === 'currency' && (
                <CurrencyInput
                  className={styles.input}
                  id={String(id)}
                  required={required}
                  placeholder={renderDetails.placeholder}
                  color={color ?? 'gray'}
                  onChange={(amount) => {
                    onChange({
                      ...answers,
                      [id]: amount,
                    });
                  }}
                  value={answer ? String(answer) : ''}
                  error={Boolean(validationErrorMessage)}
                  errorMessage={validationErrorMessage}
                />
              )}

              {type === 'select' && (
                <SelectMenu<SelectOption>
                  className={styles.input}
                  selected={renderDetails.options.find(
                    ({ id: optionId }) =>
                      (optionId as unknown as Partial<SubmittableForm>[keyof SubmittableForm]) ===
                      answer
                  )}
                  setSelected={({
                    id: optionId,
                  }: SelectMenuOption<SelectOption>) => {
                    onChange({ ...answers, [id]: optionId });
                    renderDetails.notifyOnChange?.(optionId);
                  }}
                  placeholder={renderDetails.placeholder}
                  options={renderDetails.options}
                  color="gray"
                  error={Boolean(validationErrorMessage)}
                  errorMessage={validationErrorMessage}
                  dataTestId={`${String(id)}-select-menu`}
                />
              )}

              {type === 'multiSelect' && (
                <SelectMenu<SelectOption>
                  className={styles.input}
                  selected={renderDetails.options.filter(({ id }) => {
                    if (answer) {
                      return (answer as unknown as SelectOption[]).includes(
                        id as SelectOption
                      );
                    }
                    return false;
                  })}
                  setSelected={(
                    selectedOptions: SelectMenuOption<SelectOption>[]
                  ) => {
                    onChange({
                      ...answers,
                      [id]: selectedOptions.map(({ id }) => id),
                    });
                  }}
                  placeholder={renderDetails.placeholder}
                  options={renderDetails.options}
                  color="gray"
                  multiple={true}
                  error={Boolean(validationErrorMessage)}
                  errorMessage={validationErrorMessage}
                  dataTestId={`${String(id)}-multi-select`}
                />
              )}

              {type === 'date' && (
                <CalendarDatePicker
                  className={classNames(styles.input, styles.dateInput)}
                  selectedDate={
                    answer ? new Date(answer as unknown as string) : undefined
                  }
                  onChange={(date) => {
                    onChange({
                      ...answers,
                      [id]: dayjs(date as Date).format('YYYY-MM-DD'),
                    });
                  }}
                  maxDate={new Date(renderDetails.maxDate)}
                />
              )}

              {type === 'autoSuggest' && (
                <ComboBox
                  className={styles.input}
                  selectedOption={renderDetails.options.find(
                    ({ id: optionId }) =>
                      (optionId as unknown as Partial<SubmittableForm>[keyof SubmittableForm]) ===
                      answer
                  )}
                  setSelectedOption={({ id: optionId }: ComboBoxOption) => {
                    onChange({ ...answers, [id]: optionId });
                  }}
                  placeholder={renderDetails.placeholder}
                  options={renderDetails.options}
                  color="gray"
                  useExternalQuery
                  setExternalQuery={renderDetails.setExternalQuery}
                  error={validationErrorMessage}
                  useUnfilteredOptions={renderDetails.useUnfilteredOptions}
                  dataTestId={`${String(id)}-auto-suggest`}
                />
              )}

              {type === 'upload' && (
                <div className={styles.input}>
                  <FileDropzone
                    uploadedFile={answer as unknown as File}
                    onFileSelect={(file?: File) => {
                      onChange({ ...answers, [id]: file });
                    }}
                  />
                  {validationErrorMessage && (
                    <div className={styles.uploadError}>
                      <div className={styles.uploadErrorContent}>
                        <div className={styles.iconWrapper}>
                          <ExclamationIcon
                            className={styles.icon}
                            aria-hidden="true"
                          />
                        </div>
                        <div className={styles.textWrapper}>
                          <p className={styles.text}>
                            {validationErrorMessage}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {type === 'checkbox' && (
                <div className="text-sm text-gray-900">
                  <CheckBox
                    checked={Boolean(answer)}
                    setChecked={(value) => {
                      onChange({
                        ...answers,
                        [id]: value,
                      });
                    }}
                    label={renderDetails.description}
                    small
                    className="!items-start space-x-2"
                  />
                </div>
              )}

              {type === 'radio' && (
                <div className="flex space-x-6 text-sm text-gray-900">
                  {renderDetails.options.map((option) => (
                    <RadioButton
                      value={option.label}
                      id={option.label}
                      name={String(id)}
                      onChange={() => {
                        onChange({
                          ...answers,
                          [id]: option.value,
                        });
                      }}
                      checked={option.value === answers?.[id]}
                    >
                      {option.label}
                    </RadioButton>
                  ))}
                </div>
              )}

              {type === 'description' && (
                <div className="text-sm text-gray-600">
                  <p>{renderDetails.text}</p>
                </div>
              )}

              {divider && (
                <div className="mt-6 w-full border-t border-gray-300" />
              )}
            </div>
          );
        }
      )}
      {bottomElement ?? null}
    </>
  </Modal>
);
