import {
  CheckCircleIcon,
  ExclamationIcon,
  XIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';

import * as styles from './styles';

export type AlertBannerStatus = 'IDLE' | 'OPEN' | 'CLOSE';

export type AlertBannerType = 'SUCCESS' | 'WARNING';

interface AlertBannerProps {
  message: string;
  type: AlertBannerType;
  status: AlertBannerStatus;
  onClose: () => void;
}

export const AlertBannerView = ({
  message,
  type,
  status,
  onClose,
}: AlertBannerProps) => {
  if (status === 'IDLE') {
    return <></>;
  }

  return (
    <div
      className={classNames(styles.alertBannerWrapper, {
        [styles.appearOut]: status === 'CLOSE',
      })}
    >
      <div
        className={classNames({
          [styles.alertCardSuccess]: type === 'SUCCESS',
          [styles.alertCardWarning]: type === 'WARNING',
        })}
      >
        <div className={styles.alertCardContent}>
          <div className={styles.iconWrapper}>
            {type === 'SUCCESS' && (
              <CheckCircleIcon
                className={styles.iconSuccess}
                aria-hidden="true"
              />
            )}
            {type === 'WARNING' && (
              <ExclamationIcon
                className={styles.iconWarning}
                aria-hidden="true"
              />
            )}
          </div>
          <div className={styles.textWrapper}>
            <p
              className={classNames({
                [styles.textSuccess]: type === 'SUCCESS',
                [styles.textWarning]: type === 'WARNING',
              })}
            >
              {message}
            </p>
          </div>
          <div className={styles.buttonWrapper}>
            <button
              type="button"
              className={classNames({
                [styles.buttonSuccess]: type === 'SUCCESS',
                [styles.buttonWarning]: type === 'WARNING',
              })}
              onClick={onClose}
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className={styles.buttonIcon} aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
