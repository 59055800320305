import CheckBadge from 'components/CheckBadge';

import TableCell, { TableCellProps } from '../TableCell';
import * as styles from './styles';

const TableCellWithCheck = ({
  cell,
  check,
  header,
  textColor,
  divider,
  link,
  badgeCaption,
  resourceOnClick,
}: TableCellProps) => {
  const textWithCheck = (
    <div className={styles.check}>
      <p>{cell}</p>
      {check && (
        <CheckBadge checkType={check ?? 'cross'} badgeCaption={badgeCaption} />
      )}
    </div>
  );
  return (
    <TableCell
      cell={textWithCheck}
      header={header}
      textColor={textColor}
      divider={divider}
      link={link}
      resourceOnClick={resourceOnClick}
    />
  );
};

export default TableCellWithCheck;
