import { TFunction } from '@getpopsure/i18n-react';
import { InformationSection } from 'components/InformationCard';
import TextInformation from 'components/InformationCard/TextInformation';
import { Section } from 'components/InformationCardV2/models';
import { SelectMenuOption } from 'components/SelectMenu';
import dayjs from 'dayjs';
import { retrieveClaimsOverviewPath } from 'pages/claims/template/utils/utils';
import { ExpatHealthPolicy } from 'pages/policies/expatHealth/models/ExpatHealthPolicy';
import { expatHealthCancelReasonMapper } from 'pages/policies/expatHealth/utils/mapCancelationReason';
import ChangeAccount from 'pages/policies/template/ChangeAccount/ChangeAccount';
import { mapExpatPlan } from 'shared/insurances/planMappers/expat';
import {
  expatBadgeColorMapper,
  expatStatusMapper,
} from 'shared/insurances/statusMappers/expat';
import { ExpatStatus } from 'shared/insurances/types';
import { mapGender } from 'shared/mapGender';
import { setQueryToUrl } from 'shared/utils/setQueryToUrl';
import { z } from 'zod';

import { GET_EXPAT_HEALTH_POLICY } from '../../graphql/policy';
import {
  ExpatHealthPolicyCancelReason,
  expatHealthPolicyCancelReasons,
} from '../../models';

export const getInsuredPersonInformation = (
  policy: ExpatHealthPolicy,
  t: TFunction
): Section[] => [
  {
    title: 'Insured person',
    rows: [
      {
        type: 'TEXT',
        label: 'First name',
        props: {
          initialValue: policy?.insuredPerson?.firstName ?? '',
        },
      },
      {
        type: 'TEXT',
        label: 'Last name',
        props: {
          initialValue: policy?.insuredPerson?.lastName ?? '',
        },
      },
      {
        type: 'DATE',
        label: 'Date of birth',
        props: {
          initialValue: policy?.insuredPerson?.dateOfBirth
            ? new Date(policy.insuredPerson.dateOfBirth)
            : undefined,
        },
      },
      {
        type: 'TEXT',
        label: 'Gender',
        props: {
          initialValue: policy?.insuredPerson?.gender
            ? mapGender(t)[policy?.insuredPerson?.gender]
            : '',
        },
      },
      {
        type: 'TEXT_WITH_DETAILS',
        label: 'Email',
        props: {
          initialValue: policy?.user?.email ?? '',
          detailsTitle: 'Change account',
          detailsLabel: 'Change account',
          renderModalDetails: (setOpen, isOpen) => (
            <ChangeAccount
              policyDetailsMutation={GET_EXPAT_HEALTH_POLICY}
              setOpen={setOpen}
              modalOpen={isOpen}
            />
          ),
        },
      },
    ],
  },
];

export const getPolicyInformation = (
  policy: ExpatHealthPolicy
): Section<PolicyInformationSchemaProperties>[] => [
  {
    title: 'Policy information',
    rows: [
      {
        type: 'TEXT',
        isEditable: true,
        propertyName: 'policyNumber',
        label: 'Policy number',
        props: {
          initialValue: policy?.policyNumber,
          placeholder: 'Enter policy number',
        },
      },
      {
        type: 'TEXT',
        label: 'Provider',
        props: {
          initialValue: policy?.providerId,
        },
      },
      {
        label: 'Status',
        type: 'STATUS',
        props: {
          initialValue: expatStatusMapper[policy.publicStatus as ExpatStatus],
          badgeColor: expatBadgeColorMapper[policy.publicStatus as ExpatStatus],
        },
      },
      {
        type: 'TEXT',
        label: 'Plan',
        props: {
          initialValue: mapExpatPlan(policy?.plan ?? ''),
        },
      },
      {
        type: 'DATE',
        label: 'Created on',
        props: {
          initialValue: policy?.createdAt
            ? new Date(policy?.createdAt)
            : undefined,
        },
      },
      {
        type: 'DATE',
        label: 'Start date',
        props: {
          initialValue: policy?.startDate
            ? new Date(policy?.startDate)
            : undefined,
        },
      },
      {
        type: 'DATE',
        label: 'Relocation date',
        props: {
          initialValue: policy?.arrivalDate
            ? new Date(policy?.arrivalDate)
            : undefined,
        },
      },
      {
        type: 'DATE',
        label: 'Waiting period',
        props: {
          initialValue: policy?.waitingPeriod
            ? new Date(policy?.waitingPeriod)
            : undefined,
        },
      },
      {
        type: 'DATE',
        label: 'End date',
        isEditable: true,
        propertyName: 'endDate',
        props: {
          initialValue: policy?.endDate ? new Date(policy?.endDate) : undefined,
        },
      },
      Number(policy?.numberOfClaims) > 0
        ? {
            type: 'LINK',
            label: 'Claims',
            props: {
              title: `${policy?.numberOfClaims} claims`,
              href: setQueryToUrl(retrieveClaimsOverviewPath('expat-health'), [
                { key: 'search', value: policy?.policyNumber ?? '' },
              ]),
              isExternalLink: true,
            },
          }
        : {
            label: 'Claims',
            type: 'TEXT',
            props: {
              initialValue: '',
            },
          },
      {
        type: 'TEXT',
        label: 'Source',
        isEditable: true,
        propertyName: 'source',
        props: {
          initialValue: policy?.source ?? '',
          placeholder: 'Enter source',
        },
      },
      {
        type: 'TEXT',
        label: 'Campaign',
        isEditable: true,
        propertyName: 'campaign',
        props: {
          initialValue: policy?.campaign ?? '',
          placeholder: 'Enter campaign',
        },
      },
      {
        type: 'TEXT',
        label: 'medium',
        isEditable: true,
        propertyName: 'medium',
        props: {
          initialValue: policy?.medium ?? '',
          placeholder: 'Enter medium',
        },
      },
      {
        type: 'TEXT',
        label: 'content',
        isEditable: true,
        propertyName: 'content',
        props: {
          initialValue: policy?.content ?? '',
          placeholder: 'Enter content',
        },
      },
      {
        type: 'TEXT',
        label: 'term',
        isEditable: true,
        propertyName: 'term',
        props: {
          initialValue: policy?.term ?? '',
          placeholder: 'Enter term',
        },
      },
    ],
  },
];

export const policyInformationZodSchema = z.object({
  policyNumber: z.string().nullish(),
  endDate: z.date().nullish(),
  source: z.string().nullish(),
  campaign: z.string().nullish(),
  medium: z.string().nullish(),
  content: z.string().nullish(),
  term: z.string().nullish(),
});

export type PolicyInformationSchemaProperties = z.infer<
  typeof policyInformationZodSchema
>;

export const getPolicyCancelationInformation = (
  policy: ExpatHealthPolicy
): Section<PolicyCancelationInformationSchemaProperties>[] => {
  const cancelationReasonOptions: SelectMenuOption<ExpatHealthPolicyCancelReason>[] =
    expatHealthPolicyCancelReasons.map((reason) => ({
      id: reason,
      label: expatHealthCancelReasonMapper[reason],
    }));

  return [
    {
      title: 'Policy cancelation',
      rows: [
        {
          type: 'DATE',
          label: 'Active until',
          propertyName: 'activeUntil',
          isEditable: true,
          props: {
            initialValue: policy?.activeUntilDate
              ? new Date(policy?.activeUntilDate)
              : undefined,
          },
        },
        {
          type: 'DROPDOWN',
          label: 'Cancelation reason',
          isEditable: true,
          propertyName: 'reason',
          props: {
            placeholder: 'Select a cancelation reason',
            options: cancelationReasonOptions,
            initialValue: policy?.cancelationRequest?.reason,
          },
        },
        {
          type: 'DATE',
          label: 'Requested by customer on',
          propertyName: 'createdAt',
          isEditable: true,
          props: {
            initialValue: policy?.cancelationRequest?.createdAt
              ? new Date(policy?.cancelationRequest?.createdAt)
              : undefined,
          },
        },
        {
          type: 'DATE',
          label: 'Stripe canceled on',
          propertyName: 'canceledOnStripeAt',
          isEditable: true,
          props: {
            initialValue: policy?.cancelationRequest?.canceledOnStripeAt
              ? new Date(policy?.cancelationRequest?.canceledOnStripeAt)
              : undefined,
          },
        },
        {
          type: 'DATE',
          label: 'Provider confirmed on',
          propertyName: 'cancelationConfirmedByProviderAt',
          isEditable: true,
          props: {
            initialValue: policy?.cancelationRequest
              ?.cancelationConfirmedByProviderAt
              ? new Date(
                  policy?.cancelationRequest?.cancelationConfirmedByProviderAt
                )
              : undefined,
          },
        },
      ],
    },
  ];
};

export const policyCancelationInformationZodSchema = z.object({
  activeUntil: z.date().nullish(),
  reason: z.enum(expatHealthPolicyCancelReasons).nullish(),
  createdAt: z.date().nullish(),
  canceledOnStripeAt: z.date().nullish(),
  cancelationConfirmedByProviderAt: z.date().nullish(),
});

export type PolicyCancelationInformationSchemaProperties = z.infer<
  typeof policyCancelationInformationZodSchema
>;

export const getFailedPolicyConfirmationEvents = (
  policy: ExpatHealthPolicy
): InformationSection[] => [
  {
    title: 'Failed policy confirmation events',
    rows: policy.failedPolicyConfirmationEvents.map((event) => ({
      type: 'TEXT',
      id: 'event',
      title: dayjs(event.createdAt).format('DD MMM YYYY HH:mm'),
      data:
        event.providerResponse?.Errors?.Error?.[0]?.$value ??
        JSON.stringify(event.providerResponse),
      component: TextInformation,
    })),
  },
];
