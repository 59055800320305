import { ApolloError } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import FileDropzone from 'components/FileDropzone';
import FileErrorCard from 'components/FileErrorCard';
import Input from 'components/Input';
import Modal from 'components/Modal';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import { ChangeEvent, useEffect, useState } from 'react';
import { retrieveZodErrorMessages } from 'shared/errorHandling/retrieveZodErrorMessages';

import { ZUploadableDocumentSchema } from './models';
import * as styles from './styles';

interface FileUploadFormProps {
  open: boolean;
  setOpen: (opem: boolean) => void;
  fileTypeOptions: SelectMenuOption[];
  uploadFile: (variables: {
    type: string;
    newFilename?: string;
    file: File;
  }) => void;
  loading: boolean;
  error?: ApolloError;
  reset: () => void;
}

const FileUploadForm = ({
  open,
  setOpen,
  fileTypeOptions,
  uploadFile,
  loading,
  error,
  reset,
}: FileUploadFormProps) => {
  const { t } = useTranslation();
  const [documentName, setDocumentName] = useState('');
  const [fileSelected, setFileSelected] = useState<File | undefined>(undefined);
  const [selectedType, setSelectedType] = useState<
    SelectMenuOption | undefined
  >(undefined);

  const [validationError, setValidationError] = useState<
    Partial<{ fileName: string; type: string; file: string }>
  >({});
  const [submitDisabled, setSubmitDisabled] = useState(false);

  useEffect(() => {
    setSubmitDisabled(false);
  }, [documentName, fileSelected, selectedType]);

  const handleChangeDocumentName = (e: ChangeEvent<HTMLInputElement>) => {
    setDocumentName(e.target.value);
  };

  const getCurrentFilename = () => {
    if (selectedType?.id === 'CONFIRMATION_OF_COVERAGE') {
      return 'Confirmation of coverage';
    }
    if (selectedType?.id === 'PRELIMINARY_CONFIRMATION_OF_COVERAGE') {
      return 'Preliminary confirmation of coverage';
    }
    return documentName;
  };

  const submitFile = () => {
    const filename = getCurrentFilename();

    const validation = ZUploadableDocumentSchema.safeParse({
      fileName: filename,
      type: selectedType?.id,
      file: fileSelected,
    });

    setValidationError({});

    if (!validation.success) {
      setValidationError({ ...retrieveZodErrorMessages(validation.error) });
      setSubmitDisabled(true);
    }

    if (validation.success) {
      const { type, fileName, file } = validation.data;

      uploadFile({
        type,
        newFilename: fileName,
        file,
      });
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      handleConfirm={submitFile}
      title={t('admin.documents.uploadmodal.title', 'Upload Document')}
      confirmButtonLabel={t('admin.documents.upload', 'Upload')}
      loading={loading}
      textLoading={t('admin.documents.uploading', 'Uploading...')}
      disabled={submitDisabled}
    >
      <>
        <div className={styles.section}>
          <SelectMenu
            selected={selectedType}
            setSelected={setSelectedType}
            placeholder={t(
              'admin.documents.uploadmodal.type.placeholder',
              'Select document type'
            )}
            options={fileTypeOptions}
            label={t('admin.documents.uploadmodal.type.header', 'Type')}
            disabled={loading}
            color="gray"
            error={Boolean(validationError.type)}
            errorMessage={validationError.type}
          />
        </div>
        {!(
          selectedType?.id === 'CONFIRMATION_OF_COVERAGE' ||
          selectedType?.id === 'PRELIMINARY_CONFIRMATION_OF_COVERAGE'
        ) && (
          <div className={styles.section}>
            <label htmlFor="email" className={styles.label}>
              {t('admin.documents.uploadmodal.name.header', 'Name')}
              <span className={styles.labelSubHeader}>
                {' '}
                ({t('admin.documents.uploadmodal.name.subHeader', 'Optional')})
              </span>
            </label>
            <Input
              id="documentName"
              type="text"
              required={true}
              placeholder={t(
                'admin.documents.uploadmodal.name.placeholder',
                'Your document name'
              )}
              onChange={handleChangeDocumentName}
              className={styles.input}
              disabled={loading}
              color="gray"
              error={Boolean(validationError.fileName)}
              errorMessage={validationError.fileName}
            />
          </div>
        )}
        <FileDropzone
          uploadedFile={fileSelected}
          onFileSelect={setFileSelected}
          disabled={loading}
        />
        <FileErrorCard
          open={!!error}
          title={t(
            'admin.documents.error.uploadfailed.title',
            'Upload failed. Please try again.'
          )}
          handleClose={() => reset()}
          errorType="ERROR"
        />
        {validationError.file && (
          <p className="mt-1 text-xs text-red-400">This field is required</p>
        )}
      </>
    </Modal>
  );
};

export default FileUploadForm;
