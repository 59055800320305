import { useMutation } from '@apollo/client';
import { TrashIcon } from '@heroicons/react/outline';
import { PlusCircleIcon } from '@heroicons/react/solid';
import { BADGE_COLORS } from 'components/Badge/Badge';
import { InformationSection } from 'components/InformationCard';
import ComboBoxInformation from 'components/InformationCard/ComboBoxInformation';
import DropdownInformation from 'components/InformationCard/DropdownInformation';
import EditableInformationCard from 'components/InformationCard/EditableInformationCard';
import MultipleLineInformation from 'components/InformationCard/MultipleLineInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { UPDATE_SD_CATEGORY } from 'pages/scannedDocuments/categories/sdCategories.mutations';
import { GET_SD_CATEGORY_BY_ID } from 'pages/scannedDocuments/categories/sdCategories.queries';
import {
  SD_CATEGORY_SPECIAL_FLAGS,
  SDCategory,
} from 'pages/scannedDocuments/categories/sdCategories.types';
import { UPDATE_CATEGORY_RELATIONS } from 'pages/scannedDocuments/scannedDocuments.mutations';
import { useEffect, useState } from 'react';
import { alertBanner } from 'shared/reactiveVariables';

type UpdateSdCategoryData = {
  updateSDCategory: boolean | null;
};

const SENDER_OPTIONS = ['All', 'Advigon', 'Barmenia', 'Hallesche'];

const strArrayToObj = (strArray: string[]): Record<string, string> => {
  return strArray.reduce(
    (acc, item, index) => ({
      ...acc,
      [String(index)]: item,
    }),
    {}
  );
};

export const SDCategoryInfo = ({ sdCategory }: { sdCategory: SDCategory }) => {
  const [updateCategoryRelations] = useMutation(UPDATE_CATEGORY_RELATIONS, {
    variables: {
      selectionType: 'active',
    },
    onCompleted: () => {
      alertBanner({
        type: 'SUCCESS',
        message: `Started categorization of all active documents in the background. This process may take up to several minutes.`,
      });
    },
    onError: () => {
      alertBanner({
        type: 'WARNING',
        message: `Categorizing active documents failed.`,
      });
    },
  });

  const [whitelistArray, setWhitelistArray] = useState<string[]>([]);
  const [blacklistArray, setBlacklistArray] = useState<string[]>([]);

  const whiteListObject = strArrayToObj(whitelistArray);
  const blacklistObject = strArrayToObj(blacklistArray);

  const editableData = {
    name: sdCategory.name,
    whitelist: whiteListObject,
    blacklist: blacklistObject,
    senderName: sdCategory.attributes.senderName ?? 'All',
    requiredActionCaption: sdCategory.attributes.requiredAction?.caption ?? '',
    requiredActionColor:
      sdCategory.attributes.requiredAction?.color ?? 'yellow',
    specialFlags: sdCategory.attributes.specialFlags?.join(', ') ?? '',
    customerIoEvent: sdCategory.attributes.customerIoEvent ?? '',
  };

  useEffect(() => {
    setWhitelistArray(sdCategory.whitelist);
    setBlacklistArray(sdCategory.blacklist);
  }, [sdCategory.whitelist, sdCategory.blacklist]);

  const whiteListMultipleLinesData = [
    ...Object.entries(whiteListObject).map(([key, value]) => ({
      data: `"${value}"`,
      label: (
        <div className="mr-4 flex w-full flex-grow justify-end">
          <button
            type="button"
            onClick={() => {
              const whitelistArrayWithoutEntry = whitelistArray.filter(
                (_, index) => String(index) !== key
              );
              setWhitelistArray(whitelistArrayWithoutEntry);
            }}
          >
            <div className="w-5">
              <TrashIcon color="#B4B4BA" />
            </div>
          </button>
        </div>
      ),
      id: key,
    })),
    {
      data: undefined,
      label: (
        <div className="mr-2 mt-4 flex w-full flex-grow justify-end">
          <button
            type="button"
            onClick={() => {
              setWhitelistArray([...whitelistArray, 'ENTER_TEXT']);
            }}
          >
            <div className="w-7">
              <PlusCircleIcon color="#8e8cee" />
            </div>
          </button>
        </div>
      ),
    },
  ];

  const blackListMultipleLinesData = [
    ...Object.entries(blacklistObject).map(([key, value]) => ({
      data: `"${value}"`,
      label: (
        <div className="mr-2 flex w-full flex-grow justify-end">
          <button
            type="button"
            onClick={() => {
              const blacklistArrayWithoutEntry = blacklistArray.filter(
                (_, index) => String(index) !== key
              );
              setBlacklistArray(blacklistArrayWithoutEntry);
            }}
          >
            <div className="w-5">
              <TrashIcon color="#B4B4BA" />
            </div>
          </button>
        </div>
      ),
      id: key,
    })),
    {
      data: undefined,
      label: (
        <div className="mr-2 flex w-full flex-grow justify-end">
          <button
            type="button"
            onClick={() => {
              setBlacklistArray([...blacklistArray, 'ENTER_TEXT']);
            }}
          >
            <div className="w-7">
              <PlusCircleIcon color="#8e8cee" />
            </div>
          </button>
        </div>
      ),
    },
  ];

  const infoSections: InformationSection[] = [
    {
      title: 'Category data',
      rows: [
        {
          id: 'name',
          title: 'Name',
          data: sdCategory.name,
          type: 'TEXT',
          component: TextInformation,
          editable: true,
        },
        {
          id: 'whitelist',
          title: 'Search terms',
          data: whiteListObject ?? {},
          multipleLinesData: whiteListMultipleLinesData,
          type: 'MULTIPLELINE',
          component: MultipleLineInformation,
          editable: true,
        },
        {
          id: 'blacklist',
          title: 'Blacklist',
          data: blacklistObject ?? {},
          multipleLinesData: blackListMultipleLinesData,
          type: 'MULTIPLELINE',
          component: MultipleLineInformation,
          editable: true,
        },
        {
          id: 'senderName',
          title: 'Sender',
          data: sdCategory.attributes.senderName ?? 'All',
          type: 'DROPDOWN',
          component: DropdownInformation,
          editable: true,
          dropdownOptions: SENDER_OPTIONS.map((option) => ({
            id: option,
            label: option,
          })),
          optionMapping: (id: string) => id,
          multipleOptions: false,
          placeholder: 'Select sender',
        },
        {
          id: 'requiredActionCaption',
          title: 'Required action caption',
          data: sdCategory.attributes.requiredAction?.caption ?? '',
          type: 'TEXT',
          component: TextInformation,
          editable: true,
          placeholder: 'Leave empty for no required action',
        },
        {
          id: 'requiredActionColor',
          title: 'Required action color',
          data: sdCategory.attributes.requiredAction?.color ?? 'yellow',
          type: 'DROPDOWN',
          component: DropdownInformation,
          editable: true,
          dropdownOptions: BADGE_COLORS.map((option) => ({
            id: option,
            label: option,
          })),
          optionMapping: (id: string) => id,
          multipleOptions: false,
          placeholder: 'Select color',
        },
        {
          id: 'customerIoEvent',
          title: 'Customer.io event',
          data: sdCategory.attributes.customerIoEvent ?? '',
          type: 'TEXT',
          component: TextInformation,
          editable: true,
        },
        {
          id: 'specialFlags',
          title: 'Special flags',
          data: sdCategory.attributes.specialFlags?.join(', ') ?? '',
          type: 'DROPDOWN',
          optionMapping: (flag: string) => flag,
          component: ComboBoxInformation,
          multipleOptions: true,
          editable: true,
          dropdownOptions: SD_CATEGORY_SPECIAL_FLAGS.map((flag) => ({
            id: flag,
            label: flag,
          })),
          placeholder: 'Select special flags',
        },
      ],
    },
  ];

  return (
    <EditableInformationCard
      sections={infoSections}
      editableData={editableData}
      mutation={UPDATE_SD_CATEGORY}
      resourceId={sdCategory.id}
      refetchQueries={[GET_SD_CATEGORY_BY_ID]}
      extraVariables={{}}
      successMessage="Successfully saved changes"
      onDiscard={() => setWhitelistArray(sdCategory.whitelist)}
      onCompleted={async (data: unknown) => {
        const needsCategoryRelationsUpdate = !!(data as UpdateSdCategoryData)
          ?.updateSDCategory;
        if (needsCategoryRelationsUpdate) {
          await updateCategoryRelations();
        }
      }}
    />
  );
};
