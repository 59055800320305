import classNames from 'classnames';

export const pageWrapper = classNames('flex', 'h-screen', 'w-screen');

export const tableWrapper = classNames('mt-[82px]', 'w-full', 'overflow-auto');

export const title = classNames(
  'mx-[40px]',
  'text-2xl',
  'font-bold',
  'text-gray-900'
);
