import {
  ApolloError,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { BadgeColor } from 'components/Badge/Badge';
import Button from 'components/Button';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import NavBar from 'components/NavBar';
import { SelectButtonOption } from 'components/SelectButton';
import Table, { HeaderInformation, TableData } from 'components/Table';
import ColumnHeader from 'components/Table/ColumnHeader';
import { Filter } from 'components/Table/Filters';
import SortableColumnHeader from 'components/Table/SortableColumnHeader';
import TableCell from 'components/Table/TableCell';
import { TableCellButton } from 'components/Table/TableCellButton';
import TableCellDate from 'components/Table/TableCellDate';
import TableCellDot from 'components/Table/TableCellDot';
import TableCellStatus from 'components/Table/TableCellStatus';
import TableCellStatusWithLink from 'components/Table/TableCellStatusWithLink/TableCellStatusWithLink';
import TableCellWithCheck from 'components/Table/TableCellTextWithCheck';
import { EmptyStateMessage } from 'components/Table/TableEmptyState';
import hashSum from 'hash-sum';
import { useOffset } from 'hooks/useOffset';
import useQueryParams from 'hooks/useQueryParams';
import useTableQueryParams from 'hooks/useTableQueryParams';
import Page403 from 'pages/errors/403';
import PageGenericError from 'pages/errors/genericError';
import FilterViewDropdown from 'pages/policies/publicHealth/components/FilterViewDropdown';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { generatePath, useHistory } from 'react-router-dom';
import { paths } from 'routes/definedPaths';
import {
  countries,
  countryIconMapper,
  countryNameMapper,
} from 'shared/countries/models';
import { checkUnauthenticatedErrors } from 'shared/errorHandling/unauthenticatedError';
import { checkUnauthorizedErrors } from 'shared/errorHandling/unauthorizedErrors';
import { getISODate } from 'shared/getISODate/getISODate';
import {
  dentalBadgeColorMapper,
  dentalStatusMapper,
} from 'shared/insurances/statusMappers/dental';
import {
  expatBadgeColorMapper,
  expatStatusMapper,
} from 'shared/insurances/statusMappers/expat';
import {
  expatSpainBadgeColorMapper,
  expatSpainStatusMapper,
} from 'shared/insurances/statusMappers/expatSpain';
import {
  genericBadgeColorMapper,
  genericStatusMapper,
} from 'shared/insurances/statusMappers/generic';
import {
  privateHealthBadgeColorMapper,
  privateHealthStatusMapper,
} from 'shared/insurances/statusMappers/privateHealth';
import {
  DentalStatus,
  ExpatSpainStatus,
  ExpatStatus,
  GenericStatus,
  InsuranceType,
  PrivateHealthStatus,
} from 'shared/insurances/types';

import { GET_SD_CATEGORIES } from '../categories/sdCategories.queries';
import { SDCategory } from '../categories/sdCategories.types';
import {
  getCategoryActionDotData,
  statusMapping,
} from '../scannedDocuments.mappings';
import { PROCESS_SCANNED_DOCUMENTS } from '../scannedDocuments.mutations';
import {
  GET_POLICIES_BY_POLICY_NUMBERS_FOR_LIST,
  GET_SCANNED_DOCUMENTS,
} from '../scannedDocuments.queries';
import {
  BasePolicyWithStatus,
  ScannedDocument,
} from '../scannedDocuments.types';
import { getPolicyDetailsBaseURLFromInsuranceType } from '../scannedDocuments.utils';
import { ArchiveDocumentBulkAction } from './BulkActions/ScannedDocuments.Archive';
import { CategorizeDocumentsAction } from './BulkActions/ScannedDocuments.Categorize';
import { ChangeStatusBulkAction } from './BulkActions/ScannedDocuments.ChangeStatus';
import { RenameDocumentBulkAction } from './BulkActions/ScannedDocuments.Rename';
import { ToggleVisibility } from './BulkActions/ScannedDocuments.ToggleVisibility';
import { TriggerCioEventBulkAction } from './BulkActions/ScannedDocuments.TriggerCioEvent';
import { UnprocessDocumentBulkAction } from './BulkActions/ScannedDocuments.Unprocess';
import FilterTabs, {
  TabOption,
} from './FilterTabs/ScannedDocuments.FilterTabs';
import * as styles from './ScannedDocuments.List.styles';

export const getCombinedPolicyStatusByInsuranceType =
  (insuranceType?: InsuranceType) =>
  (
    status: string
  ): {
    text: {
      id: string;
      text: string;
    };
    color: BadgeColor;
  } => {
    switch (insuranceType) {
      case 'PRIVATE_HEALTH':
        return {
          text: {
            id: status,
            text: privateHealthStatusMapper[status as PrivateHealthStatus],
          },
          color: privateHealthBadgeColorMapper[status as PrivateHealthStatus],
        };
      case 'INCOMING':
        return {
          text: {
            id: status,
            text: expatStatusMapper[status as ExpatStatus],
          },
          color: expatBadgeColorMapper[status as ExpatStatus],
        };
      case 'DENTAL':
        return {
          text: {
            id: status,
            text: dentalStatusMapper[status as DentalStatus],
          },
          color: dentalBadgeColorMapper[status as DentalStatus] as BadgeColor,
        };
      case 'INCOMING_ES':
        return {
          text: {
            id: status,
            text: expatSpainStatusMapper[status as GenericStatus],
          },
          color: expatSpainBadgeColorMapper[
            status as GenericStatus
          ] as BadgeColor,
        };
      case 'INCOMING_EU':
        return {
          text: {
            id: status,
            text: genericStatusMapper[status as ExpatSpainStatus],
          },
          color: genericBadgeColorMapper[
            status as ExpatSpainStatus
          ] as BadgeColor,
        };
      default:
        break;
    }
    return {
      text: {
        id: 'contactfeather',
        text: '',
      },
      color: 'gray',
    };
  };

const DEFAULT_NUMBER_OF_DOCUMENTS_PER_PAGE = 25;

type GetScannedDocumentsData = {
  scannedDocuments: ScannedDocument[];
  totalScannedDocuments: number;
  unprocessedScannedDocumentsCount: number;
  totalNewDocumentsCount: number;
  newAdvigonDocumentsCount: number;
  newBarmeniaDocumentsCount: number;
  newHallescheDocumentsCount: number;
};

type ProcessScannedDocumentsData = {
  processScannedDocuments: number;
};

type GetSDCategoriesData = {
  sdCategories: SDCategory[];
  totalSDCategoriesCount: number;
};

type GetPoliciesByPolicyNumbersData = {
  policiesByPolicyNumbers: BasePolicyWithStatus[] | null;
};

const filterStatusOptions: SelectButtonOption[] = [
  {
    title: 'New',
    label: 'New',
    id: 'NEW',
    color: 'pink',
  },
  {
    label: 'In progress',
    title: 'In progress',
    id: 'IN_PROGRESS',
    color: 'blue',
  },
  {
    title: 'Waiting',
    label: 'Waiting',
    id: 'WAITING',
    color: 'yellow',
  },
  {
    title: 'Done',
    label: 'Done',
    id: 'DONE',
    color: 'green',
  },
];
const filterViewOptions = [
  {
    id: 'all',
    label: 'All documents',
    title: 'All documents',
    show: true,
  },
  {
    id: 'actionRequired',
    label: 'Action required',
    title: 'Action required',
    show: true,
  },
  {
    id: 'archived',
    label: 'Archived',
    title: 'Archived',
    show: true,
  },
];

export const ScannedDocumentsListPage = () => {
  const querySearchParam = useQueryParams();
  const filterSender = querySearchParam.get('filterSender');
  const {
    loading: categoriesLoading,
    error: categoriesError,
    data: categoriesData,
  } = useQuery<GetSDCategoriesData>(GET_SD_CATEGORIES, {
    variables: {
      offset: 0,
      searchString: '',
      sortColumn: 'name',
      sortOrder: 'asc',
      filterSender,
    },
    notifyOnNetworkStatusChange: true,
  });

  const allCategories = categoriesData?.sdCategories;

  const filterCategoriesOptions: SelectButtonOption[] =
    allCategories?.map(({ name, id }) => ({
      title: name,
      label: name,
      id,
    })) ?? [];

  filterCategoriesOptions.push({
    title: '- UNCATEGORIZED -',
    label: '- UNCATEGORIZED -',
    id: 'OTHER',
  });

  return (
    <ScannedDocumentsListPageBase
      filterCategoriesOptions={filterCategoriesOptions}
      categoriesLoading={categoriesLoading}
      categoriesError={categoriesError}
      allCategories={allCategories}
    />
  );
};

export const ScannedDocumentsListPageBase = ({
  filterCategoriesOptions,
  categoriesLoading,
  categoriesError,
  allCategories,
}: {
  filterCategoriesOptions: SelectButtonOption[];
  categoriesLoading: boolean;
  categoriesError?: ApolloError;
  allCategories?: SDCategory[];
}) => {
  const querySearchParam = useQueryParams();
  const devMode = querySearchParam.get('devMode') === '1';

  const [selectedRows, setSelectedRows] = useState<Record<number, boolean>>({});

  const [rowsPerPage, setRowsPerPage] = useState(
    DEFAULT_NUMBER_OF_DOCUMENTS_PER_PAGE
  );
  const [activeModalId, setActiveModalId] = useState('');

  const history = useHistory();
  const { offset, setOffset } = useOffset();
  const {
    search,
    setSearch,
    sort,
    setSort,
    filter,
    setFilter,
    dateFilter,
    setDateFilter,
  } = useTableQueryParams([
    {
      options: filterStatusOptions,
      multiple: true,
      id: 'filterStatus',
    },
    {
      options: filterCategoriesOptions,
      multiple: true,
      id: 'filterCategories',
    },
    {
      dateRange: true,
      id: 'filterDocumentDate',
    },
    {
      options: filterViewOptions,
      multiple: false,
      id: 'filterView',
    },
  ]);

  const getFilterVariables = () => {
    return {
      filterStatus: Array.isArray(filter.filterStatus)
        ? filter.filterStatus.map((option) => option.id)
        : [],
      filterCategories: Array.isArray(filter.filterCategories)
        ? filter.filterCategories.map((option) => option.id)
        : [],
      filterView: !Array.isArray(filter.filterView)
        ? filter.filterView?.id ?? 'all'
        : 'all',
      filterSender: querySearchParam.get('filterSender') ?? 'all',
      ...(querySearchParam.get('filterSender') === 'advigonOnly' &&
      querySearchParam.get('filterPolicyType')
        ? {
            filterPolicyType: querySearchParam.get('filterPolicyType'),
          }
        : {}),
    };
  };

  const [processScannedDocuments, { loading: busyProcessingDocuments }] =
    useMutation<ProcessScannedDocumentsData>(PROCESS_SCANNED_DOCUMENTS, {
      refetchQueries: [GET_SCANNED_DOCUMENTS],
    });

  const {
    loading,
    error: documentsError,
    data,
    fetchMore,
  } = useQuery<GetScannedDocumentsData>(GET_SCANNED_DOCUMENTS, {
    skip: categoriesLoading,
    variables: {
      limit: rowsPerPage,
      offset,
      searchString: search,
      sortColumn: sort.column,
      sortOrder: sort.order,
      ...getFilterVariables(),
      documentDateRangeStart:
        getISODate(dateFilter.filterDocumentDate?.[0]) ?? undefined,
      documentDateRangeEnd:
        getISODate(dateFilter.filterDocumentDate?.[1]) ?? undefined,
    },
    notifyOnNetworkStatusChange: true,
    // TODO (KK): Manipulate cache manually after (bulk) mutations
    fetchPolicy: 'cache-and-network',
  });

  const policyNumbers = data?.scannedDocuments.flatMap(({ policyNumber }) =>
    policyNumber ? [policyNumber] : []
  );

  const [getPoliciesByPolicyNumbers, getPoliciesByPolicyNumbersResult] =
    useLazyQuery<GetPoliciesByPolicyNumbersData>(
      GET_POLICIES_BY_POLICY_NUMBERS_FOR_LIST,
      {
        variables: {
          policyNumbers,
        },
      }
    );

  const relatedPolicies =
    getPoliciesByPolicyNumbersResult.data?.policiesByPolicyNumbers;

  useEffect(() => {
    const fetchStuff = async () => {
      await getPoliciesByPolicyNumbers();
    };
    if (!categoriesLoading && !loading && !relatedPolicies) {
      fetchStuff();
    }
  }, [categoriesLoading, loading, hashSum(policyNumbers)]);

  const error = documentsError || categoriesError;

  if (error && checkUnauthenticatedErrors(error)) {
    history.push(paths.auth.path);
  }
  if (error && checkUnauthorizedErrors(error)) return <Page403 />;
  if (error) return <PageGenericError />;

  const defaultEmptyStateMessage: EmptyStateMessage = {
    title: 'There are no documents yet.',
    description: 'It looks like there are no scanned documents yet.',
  };

  const tableFilters: Filter[] = [
    {
      options: filterStatusOptions,
      filterType: 'MULTIPLE',
      selected: Array.isArray(filter.filterStatus) ? filter.filterStatus : [],
      label: 'Status',
      id: 'filterStatus',
      placeholder: 'Select status(es)',
    },
    {
      options: filterCategoriesOptions,
      filterType: 'MULTIPLE',
      selected: Array.isArray(filter.filterCategories)
        ? filter.filterCategories
        : [],
      label: 'Category',
      id: 'filterCategories',
      placeholder: 'Select categories',
    },
    {
      filterType: 'DATE_RANGE',
      selected: Array.isArray(dateFilter.filterDocumentDate)
        ? dateFilter.filterDocumentDate
        : undefined,
      label: 'Document date range',
      id: 'filterDocumentDate',
    },
  ];

  const tableHeaders: HeaderInformation[] = [
    {
      id: 'actionRequired',
      label: '',
      width: 'w-[40px]',
      minWidth: 'min-w-[40px]',
      sticky: false,
      threshold: 'left-0',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'recipientName',
      label: 'Recipient name',
      width: 'w-none',
      minWidth: 'min-w-[160px]',
      sticky: false,
      threshold: 'left-[40px]',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'special',
      label: '',
      width: 'w-none',
      minWidth: 'min-w-[0px]',
      sticky: false,
      threshold: 'left-[40px]',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'status',
      label: 'Status',
      width: 'w-none',
      minWidth: 'min-w-[80px]',
      sticky: false,
      threshold: 'left-[220px]',
      border: 'right',
      component: SortableColumnHeader,
    },
    {
      id: 'subject',
      label: 'Subject',
      width: 'w-none',
      minWidth: 'min-w-[160px]',
      sticky: false,
      threshold: 'left-0',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'senderName',
      label: 'Sender',
      width: 'w-none',
      minWidth: 'min-w-[120px]',
      sticky: false,
      threshold: 'left-0',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'fileLink',
      label: 'PDF',
      width: 'w-none',
      minWidth: 'min-w-[80px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'policyNumber',
      label: 'Policy number',
      width: 'w-none',
      minWidth: 'min-w-[151px]',
      sticky: false,
      threshold: 'left-[340px]',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'documentDate',
      label: 'Document date',
      width: 'w-none',
      minWidth: 'min-w-[120px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: SortableColumnHeader,
    },
    {
      id: 'categories',
      label: 'Category',
      width: 'w-none',
      minWidth: 'min-w-[140px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'policyStatus',
      label: 'Policy',
      width: 'w-none',
      minWidth: 'min-w-[20px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: ColumnHeader,
    },
    {
      id: 'visibleInUserAccount',
      label: 'Visible?',
      width: 'w-none',
      minWidth: 'min-w-[40px]',
      sticky: false,
      threshold: '',
      border: 'none',
      component: SortableColumnHeader,
    },
  ];

  const tableData: TableData = {
    numberOfRows: !data ? 0 : data.totalScannedDocuments,
    numberOfRowsPerPage: rowsPerPage,
    headers: tableHeaders,
    rows:
      !data || !data.scannedDocuments
        ? []
        : data.scannedDocuments.map((sDoc) => {
            const relatedPolicy = relatedPolicies?.find(
              (p) => sDoc.policyNumber && p.policyNumber === sDoc.policyNumber
            );

            const policyLink = getPolicyDetailsBaseURLFromInsuranceType(
              relatedPolicy?.insuranceType,
              relatedPolicy?.id
            );

            const customerIoEvents = sDoc.categories.flatMap((c) =>
              c.attributes.customerIoEvent ? [c.attributes.customerIoEvent] : []
            );
            const documentHasCustomerIoEvent = customerIoEvents.length > 0;
            const customerIoCaption = documentHasCustomerIoEvent
              ? `CIO: ${customerIoEvents.join(', ')}`
              : '';

            const sourceCaption = sDoc.meta?.source
              ? `Source: ${sDoc.meta.source}`
              : '';

            const specialCaption = [customerIoCaption, sourceCaption]
              .filter(Boolean)
              .join(' | ');

            const specialBadge = documentHasCustomerIoEvent
              ? 'chat'
              : sDoc.meta?.source;

            const hasMultipleCaptions = customerIoCaption && sourceCaption;

            return {
              resourceLink: generatePath(paths.scannedDocuments.details.path, {
                id: sDoc?.id,
              }),
              cells: [
                {
                  component: TableCellDot,
                  cellType: 'DOT',
                  textColor: 'dark',
                  ...getCategoryActionDotData({
                    allCategories: allCategories ?? [],
                    documentCategoryNames: sDoc.categories.map(
                      ({ name }) => name
                    ),
                    status: sDoc.status,
                  }),
                },
                {
                  component: TableCell,
                  cellType: 'TEXT',
                  data: sDoc.recipientName || '-',
                  textColor: 'light',
                },
                {
                  component: TableCellWithCheck,
                  cellType: 'TEXT_WITH_CHECK',
                  data: hasMultipleCaptions ? '*' : '',
                  textColor: 'light',
                  check: specialBadge,
                  badgeCaption: specialCaption,
                },
                {
                  component: TableCellStatus,
                  cellType: 'STATUS',
                  data: sDoc.status || '',
                  textColor: 'dark',
                  statusMapping,
                },
                {
                  component: TableCell,
                  cellType: 'TEXT',
                  data: sDoc.subject || '-',
                  textColor: 'light',
                },
                {
                  component: TableCell,
                  cellType: 'TEXT',
                  data: sDoc.senderName || '-',
                  textColor: 'light',
                },
                {
                  component: TableCellButton,
                  cellType: 'BUTTON',
                  title: 'View',
                  data: '',
                  onClick: () => {
                    const url = generatePath(
                      paths.scannedDocuments.details.path,
                      {
                        id: sDoc?.id,
                      }
                    );
                    window.open(`${url}#pdflink`, '_blank')?.focus();
                  },
                },
                {
                  component: TableCell,
                  cellType: 'TEXT',
                  data: sDoc.policyNumber || '-',
                  textColor: 'light',
                },
                {
                  component: TableCellDate,
                  cellType: 'DATE',
                  data: sDoc.documentDate || '',
                  textColor: 'light',
                },
                {
                  component: TableCell,
                  cellType: 'TEXT',
                  data:
                    sDoc.categories.length > 0
                      ? sDoc.categories.map((c) => c.name).join(', ')
                      : '-',
                  textColor: 'light',
                },
                {
                  component: TableCellStatusWithLink,
                  cellType: 'STATUS_WITH_LINK',
                  href: policyLink ?? '',
                  data: relatedPolicy?.publicStatus || '',
                  textColor: 'light',
                  statusMapping: getCombinedPolicyStatusByInsuranceType(
                    relatedPolicy?.insuranceType
                  ),
                },
                {
                  component: TableCellWithCheck,
                  cellType: 'TEXT_WITH_CHECK',
                  data: '',
                  textColor: 'light',
                  check:
                    sDoc.userPolicyId && sDoc.visibleInUserAccount
                      ? 'eyeOpen'
                      : undefined,
                  badgeCaption: 'Visible in user account',
                },
              ],
            };
          }),
  };

  const showLoading =
    loading ||
    categoriesLoading ||
    !data ||
    (data.scannedDocuments.length === 0 && data.totalScannedDocuments > 0);

  const getSelectedFilterViewOption = () => {
    if (!filter.filterView || Array.isArray(filter.filterView)) {
      return { ...filterViewOptions[0], show: true };
    }
    return { ...filter.filterView, show: true };
  };

  const expatEUCountries = countries
    .filter((country) => !['eu', 'de', 'es'].includes(country))
    .sort();

  const filterExpatEUSubOptions: TabOption[] = expatEUCountries.map(
    (country) => ({
      id: country.toUpperCase(),
      label: countryNameMapper[country],
      icon: countryIconMapper[country],
    })
  );

  const filterSenderTabOptions: TabOption[] = [
    {
      label: `All${
        data?.totalNewDocumentsCount ? ` (${data.totalNewDocumentsCount})` : ''
      }`,
      id: 'all',
    },
    {
      label: `Advigon${
        data?.newAdvigonDocumentsCount
          ? ` (${data.newAdvigonDocumentsCount})`
          : ''
      }`,
      id: 'advigonOnly',
    },
    {
      label: `Barmenia${
        data?.newBarmeniaDocumentsCount
          ? ` (${data.newBarmeniaDocumentsCount})`
          : ''
      }`,
      id: 'barmeniaOnly',
    },
    {
      label: `Hallesche${
        data?.newHallescheDocumentsCount
          ? ` (${data.newHallescheDocumentsCount})`
          : ''
      }`,
      id: 'hallescheOnly',
    },
  ];

  const filterPolicyTypeTabOptions: TabOption[] = [
    {
      id: 'ALL',
      label: 'All',
    },
    {
      id: 'INCOMING',
      label: countryNameMapper.de,
      icon: countryIconMapper.de,
    },
    {
      id: 'INCOMING_ES',
      label: countryNameMapper.es,
      icon: countryIconMapper.es,
    },
    {
      id: 'INCOMING_EU',
      label: countryNameMapper.eu,
      icon: countryIconMapper.eu,
      subFilterOptions: filterExpatEUSubOptions,
      subFilterPlaceholder: 'Select country',
    },
  ];

  const scannedDocuments = data?.scannedDocuments ?? [];

  const selectedIndices = Object.entries(selectedRows ?? {}).flatMap(
    ([key, value]) => (value ? [Number(key)] : [])
  );

  const selectedDocuments = selectedIndices.flatMap((index) => [
    scannedDocuments[index],
  ]);

  const documentIds = selectedDocuments.map(({ id }) => id);

  const isOnArchivedView = getSelectedFilterViewOption().id === 'archived';

  const hideDocumentsMode = selectedDocuments.some(
    ({ visibleInUserAccount }) => visibleInUserAccount
  );

  const showTriggerCioEventButton = selectedDocuments.some(({ categories }) =>
    categories.some((c) => c.attributes.customerIoEvent)
  );

  const currentFilterSender = querySearchParam.get('filterSender') ?? 'all';

  return (
    <>
      <Helmet>
        <title>Scanned documents - Feather Admin Panel</title>
      </Helmet>
      <div className={styles.pageWrapper}>
        <NavBar current="scannedDocuments" />
        <div className={styles.tableWrapper}>
          <ArchiveDocumentBulkAction
            ids={documentIds}
            modalIsShown={activeModalId === 'ARCHIVE'}
            closeModal={() => {
              setActiveModalId('');
              setSelectedRows({});
            }}
            unarchive={isOnArchivedView}
          />
          <ChangeStatusBulkAction
            ids={documentIds}
            modalIsShown={activeModalId === 'CHANGE_STATUS'}
            closeModal={() => {
              setActiveModalId('');
              setSelectedRows({});
            }}
          />
          <RenameDocumentBulkAction
            ids={documentIds}
            modalIsShown={activeModalId === 'RENAME'}
            closeModal={() => {
              setActiveModalId('');
              setSelectedRows({});
            }}
          />
          {!isOnArchivedView && showTriggerCioEventButton && (
            <TriggerCioEventBulkAction
              selectedDocuments={selectedDocuments}
              modalIsShown={activeModalId === 'TRIGGER_CIO_EVENT'}
              closeModal={() => {
                setActiveModalId('');
                setSelectedRows({});
              }}
            />
          )}
          {!isOnArchivedView && (
            <ToggleVisibility
              selectedDocuments={selectedDocuments}
              modalIsShown={activeModalId === 'TOGGLE_VISIBILITY'}
              closeModal={() => {
                setActiveModalId('');
                setSelectedRows({});
              }}
              hideDocumentsMode={hideDocumentsMode}
            />
          )}
          {devMode && (
            <UnprocessDocumentBulkAction
              ids={documentIds}
              modalIsShown={activeModalId === 'UNPROCESS'}
              closeModal={() => setActiveModalId('')}
            />
          )}
          <h1 className={styles.title}>Scanned documents</h1>
          <FilterTabs
            tabOptions={filterSenderTabOptions}
            setOffset={setOffset}
            filterName="filterSender"
            noFilterTabId="all"
          />
          {currentFilterSender === 'advigonOnly' && (
            <FilterTabs
              tabOptions={filterPolicyTypeTabOptions}
              setOffset={setOffset}
              filterName="filterPolicyType"
              noFilterTabId="ALL"
            />
          )}
          <Table
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            data={tableData}
            title=""
            fetchMore={fetchMore}
            setOffset={setOffset}
            offset={offset}
            limit={rowsPerPage}
            setLimit={setRowsPerPage}
            searchString={search}
            setSearchString={setSearch}
            searchPlaceholder="Search by name, subject, policy number or text content"
            sortKey={sort}
            setSortKey={setSort}
            loading={showLoading}
            emptyStateMessage={defaultEmptyStateMessage}
            filters={filter}
            filterDetails={tableFilters}
            setFilterOption={setFilter}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            actionButton={
              <div className="flex gap-4">
                {devMode && <CategorizeDocumentsAction />}
                {!!data?.unprocessedScannedDocumentsCount && (
                  <Button
                    buttonType="white"
                    loading={busyProcessingDocuments}
                    loadingText="Processing..."
                    onClick={async () => {
                      await processScannedDocuments();
                    }}
                  >
                    Process documents ({data?.unprocessedScannedDocumentsCount})
                  </Button>
                )}
                <Button
                  buttonType="transparent"
                  onClick={() =>
                    window
                      .open(paths.scannedDocuments.categories.path, '_blank')
                      ?.focus()
                  }
                >
                  Edit Categories
                </Button>
                <FilterViewDropdown
                  options={filterViewOptions}
                  selected={getSelectedFilterViewOption()}
                  setOffset={setOffset}
                />
                <DropdownButton
                  options={[
                    {
                      id: 'ARCHIVE',
                      label: isOnArchivedView ? 'Un-Archive' : 'Archive',
                      show: true,
                    },
                    {
                      id: 'CHANGE_STATUS',
                      label: 'Change status',
                      show: true,
                    },
                    {
                      id: 'RENAME',
                      label: 'Rename',
                      show: true,
                    },
                    {
                      id: 'TRIGGER_CIO_EVENT',
                      label: 'Trigger customer.io event',
                      show: showTriggerCioEventButton,
                    },
                    {
                      id: 'TOGGLE_VISIBILITY',
                      label: hideDocumentsMode
                        ? 'Hide from user account'
                        : 'Show in user account',
                      show: true,
                    },
                    {
                      id: 'UNPROCESS',
                      label: 'Un-process',
                      show: devMode,
                    },
                  ]}
                  onSelectOption={(s) => setActiveModalId(s.id)}
                  disabled={!selectedDocuments.length}
                >
                  Actions
                  {`${
                    selectedDocuments.length
                      ? ` (${selectedDocuments.length})`
                      : ''
                  }`}
                </DropdownButton>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};
