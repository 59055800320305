import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import QuestionnaireTab from 'pages/policies/publicHealth/components/QuestionnaireTab';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import SideSection from '../SideSection';

const QuestionnaireTabPublicProvider = ({
  policy,
}: {
  policy: PublicHealthPolicy;
}) => {
  const questionnaireTabRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => questionnaireTabRef.current,
  });

  return (
    <>
      <div ref={questionnaireTabRef} className="w-full">
        <QuestionnaireTab
          data={policy}
          providerView={true}
          handlePrint={handlePrint}
        />
      </div>
      <div className="w-full lg:ml-[20px] lg:w-auto">
        <SideSection policy={policy} />
      </div>
    </>
  );
};

export default QuestionnaireTabPublicProvider;
