import { gql } from '@apollo/client';

import { PUBLIC_QUESTIONNAIRE_FIELDS } from '../fragments/questionnaire';

export const INTERNAL_UPLOAD_PUBLIC_POLICY_FILE = gql`
  mutation UploadPublicHealthFile(
    $policyId: String!
    $type: String!
    $file: Upload!
    $newFilename: String
  ) {
    uploadPublicHealthPolicyFile(
      policyId: $policyId
      type: $type
      file: $file
      newFilename: $newFilename
    ) {
      id
      url
    }
  }
`;

export const UPDATE_STUDENT_CHECKLIST_IBAN = gql`
  mutation UpdateIBAN($id: String!, $iban: String!) {
    updateIBAN(studentChecklistId: $id, iban: $iban) {
      id
      iban
    }
  }
`;

export const UPDATE_HR_POLICY_INFORMATION = gql`
  mutation UpdateHrInformation(
    $email: String!
    $hrPersonName: String!
    $employerName: String!
    $id: String!
    $letterToEmployerSentAt: String
  ) {
    upsertHrInformation(
      email: $email
      hrPersonName: $hrPersonName
      employerName: $employerName
      policyId: $id
      letterToEmployerSentAt: $letterToEmployerSentAt
    ) {
      id
      employerName
      letterToEmployerSentAt
      hrPersonName
      email
    }
  }
`;

export const UPDATE_POLICY_INFORMATION = gql`
  mutation UpdatePublicHealthPolicyInformation(
    $id: String!
    $kvnr: String!
    $svnr: String!
    $startDate: String!
    $agentId: String!
    $source: String!
    $companyId: String
    $campaign: String
    $medium: String
    $term: String
    $content: String
  ) {
    updatePolicyInformation(
      id: $id
      kvnr: $kvnr
      svnr: $svnr
      startDate: $startDate
      agentId: $agentId
      source: $source
      companyId: $companyId
      campaign: $campaign
      medium: $medium
      content: $content
      term: $term
    ) {
      id
      kvnr
      source
      campaign
      medium
      content
      term
      providerAgent {
        id
        firstName
        lastName
        email
      }
      startDate
      user {
        svnr
      }
      company {
        id
        name
      }
    }
  }
`;

export const UPDATE_POLICY_STATUS = gql`
  mutation UpdatePublicHealthPolicyStatus(
    $id: String!
    $status: String!
    $fileType: String!
    $file: Upload
    $category: String
    $description: String
    $startDate: String
    $issueIds: [String]
    $newIssueStatus: String
  ) {
    updatePublicHealthPolicyStatus(
      id: $id
      status: $status
      startDate: $startDate
    ) {
      id
      publicStatus
      startDate
    }
    updateMultiplePolicyIssues(
      userPolicyId: $id
      issueIds: $issueIds
      newStatus: $newIssueStatus
    ) {
      id
      status
    }
    createPublicHealthIssue(
      userPolicyId: $id
      category: $category
      description: $description
    ) {
      id
      createdAt
      description
      category
      isCustomerInformed
      status
    }
    uploadPublicHealthPolicyFile(type: $fileType, policyId: $id, file: $file) {
      id
      url
    }
  }
`;

export const CREATE_NEW_ISSUE = gql`
  mutation CreatePublicHealthIssue(
    $userPolicyId: String!
    $category: String
    $description: String
    $documentId: String
  ) {
    createPublicHealthIssue(
      userPolicyId: $userPolicyId
      category: $category
      description: $description
      documentId: $documentId
    ) {
      id
      createdAt
      description
      category
      isCustomerInformed
      status
      document {
        id
        name
        url
      }
    }
  }
`;

export const UPDATE_PUBLIC_QUESTIONNAIRE = gql`
  ${PUBLIC_QUESTIONNAIRE_FIELDS}
  mutation UpdatePublicHealthQuestionnaire(
    $questionnaireId: String!
    $policyId: String!
    $answers: String!
  ) {
    updatePublicHealthQuestionnaire(
      questionnaireId: $questionnaireId
      policyId: $policyId
      answers: $answers
    ) {
      id
      answers {
        ...PublicQuestionnaireAnswerFields
      }
    }
  }
`;

export const RESET_COMMISSION_ENTITLEMENT = gql`
  mutation UpdateCommissionEntitlementStatus($policyId: String!) {
    resetCommissionEntitlementStatus(policyId: $policyId) {
      id
      commissionEntitlement
      commissionEntitlementAcceptedAt
    }
  }
`;

export const DELETE_PUBLIC_POLICY_FILE = gql`
  mutation DeletePublicHealthPolicyFile(
    $blobName: String!
    $documentType: String!
    $id: String!
  ) {
    deletePublicHealthPolicyFile(
      blobName: $blobName
      type: $documentType
      id: $id
    ) {
      id
    }
  }
`;

export const RUN_PREMODERATON = gql`
  mutation RunPublicPremoderation($policyId: String!) {
    runPremoderation(policyId: $policyId)
  }
`;
