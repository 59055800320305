import { DocumentNode, useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useTranslation } from '@getpopsure/i18n-react';
import Dropdown from 'components/Dropdown';
import { DropdownOption } from 'components/Dropdown/Dropdown';
import { INTERNAL_UPLOAD_PUBLIC_POLICY_FILE } from 'graphql/publicHealth/internal/mutations';
import { UPDATE_INTERNAL_ISSUE_STATUS } from 'graphql/shared/issues';
import { useState } from 'react';
import { IssueStatusId } from 'shared/mapIssueStatus';
import { alertBanner } from 'shared/reactiveVariables';

import ResolveIssueForm from './ResolveIssueForm';
import UnResolveIssueForm from './UnResolveIssueForm';

interface ChangeIssueStatusProps {
  status: IssueStatusId;
  id: string;
  policyId: string;
  providerView?: boolean;
  disabled?: boolean;
  issueStatusChangeMutation?: DocumentNode;
  policyQuery?: DocumentNode;
  uploadFileMutation?: DocumentNode;
  issueCategory?: string;
}

const ChangeIssueStatus = ({
  status,
  id,
  policyId,
  providerView = true,
  disabled = false,
  issueStatusChangeMutation,
  policyQuery,
  uploadFileMutation,
  issueCategory = 'OTHER',
}: ChangeIssueStatusProps) => {
  const { t } = useTranslation();

  const statusOptions: DropdownOption[] = [
    {
      label: t('admin.activity.issue.status.resolved', 'Resolved'),
      id: 'resolved',
      show: true,
    },
    {
      label: t('admin.activity.issue.status.unresolved', 'Unresolved'),
      id: 'unresolved',
      show: true,
    },
    {
      label: t(
        'admin.activity.issue.status.customercontacted',
        'Customer contacted'
      ),
      id: 'customercontacted',
      show: !providerView,
    },
  ];

  const [updateStatus, { loading }] = useMutation(
    issueStatusChangeMutation ?? UPDATE_INTERNAL_ISSUE_STATUS,
    {
      refetchQueries: policyQuery ? [policyQuery] : [],
      onCompleted: () => {
        alertBanner({
          type: 'SUCCESS',
          message: t(
            'admin.alert.issuestatus.success.header',
            'Issue status successfully updated.'
          ),
        });
      },
      onError: () => {
        alertBanner({
          type: 'WARNING',
          message: t(
            'admin.alert.issuestatus.warning.header',
            'Something went wrong. Please try again.'
          ),
        });
      },
    }
  );

  const [uploadFile, { loading: loadingFile }] = useMutation(
    uploadFileMutation ?? INTERNAL_UPLOAD_PUBLIC_POLICY_FILE,
    {
      onError: () => {
        alertBanner({
          type: 'WARNING',
          message: t(
            'admin.alert.issuestatus.warning.header',
            'Something went wrong. Please try again.'
          ),
        });
      },
    }
  );

  const [resolveForm, setResolveForm] = useState(false);
  const [unResolveForm, setUnResolveForm] = useState(false);
  const currentStatus =
    statusOptions.find((option) => option.id === status) ?? statusOptions[0];

  const handleStatusChange = (option: DropdownOption) => {
    if (option.id === status) {
      return;
    }

    if (option.id === 'resolved') {
      setResolveForm(true);
    }
    if (option.id === 'unresolved') {
      setUnResolveForm(true);
    }
    if (option.id === 'customercontacted') {
      handleUpdateStatus(
        {
          newStatus: 'customercontacted',
        },
        ''
      );
    }
  };

  const handleUpdateStatus = async (
    variables: {
      newStatus: string;
      newFilename?: string;
      comment?: string;
      file?: File;
    },
    formId: string
  ) => {
    if (variables.file) {
      uploadFile({
        variables: {
          policyId,
          type: issueCategory,
          ...variables,
        },
      })
        .then((result) => {
          let fileField = '';
          if (!uploadFileMutation) {
            return;
          }
          switch (getOperationName(uploadFileMutation)) {
            case 'UploadPublicHealthFile':
              fileField = 'uploadPublicHealthPolicyFile';
              break;
            case 'UploadPrivateHealthFile':
              fileField = 'uploadPrivateHealthPolicyFile';
              break;
            case 'UploadProviderPublicHealthPolicyFile':
              fileField = 'uploadProviderPublicHealthPolicyFile';
          }
          updateStatus({
            variables: {
              userPolicyId: policyId,
              issueId: id,
              documentId: result.data[fileField].id,
              ...variables,
            },
          })
            .catch(() => {})
            .then(() => {
              switch (formId) {
                case 'resolveForm':
                  setResolveForm(false);
                  break;
                case 'unResolveForm':
                  setUnResolveForm(false);
              }
            });
        })
        .catch(() => {});
    } else {
      updateStatus({
        variables: {
          userPolicyId: policyId,
          issueId: id,
          ...variables,
        },
      })
        .catch(() => {})
        .then(() => {
          switch (formId) {
            case 'resolveForm':
              setResolveForm(false);
              break;
            case 'unResolveForm':
              setUnResolveForm(false);
          }
        });
    }
  };

  return (
    <>
      {resolveForm && (
        <ResolveIssueForm
          open={resolveForm}
          setOpen={setResolveForm}
          updateStatus={handleUpdateStatus}
          loading={loading || loadingFile}
        />
      )}
      {unResolveForm && (
        <UnResolveIssueForm
          open={unResolveForm}
          setOpen={setUnResolveForm}
          updateStatus={handleUpdateStatus}
          loading={loading || loadingFile}
        />
      )}
      <Dropdown
        options={statusOptions}
        disabled={(status === 'customercontacted' && providerView) || disabled}
        selected={currentStatus}
        setSelected={handleStatusChange}
      />
    </>
  );
};

export default ChangeIssueStatus;
