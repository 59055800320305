import { ModalFormData } from 'components/ModalForm/models';
import { SelectMenuOption } from 'components/SelectMenu';
import { CreatePolicySchema } from 'pages/policies/template/CreatePolicy/CreatePolicy';
import { z } from 'zod';

import { statusOptions } from './statusData';

const providerOptions: SelectMenuOption<string>[] = [
  {
    id: 'ADVIGON',
    label: 'Advigon',
  },
  {
    id: 'ALTE_LEIPZIGER',
    label: 'Alte Leipziger',
  },
  {
    id: 'BARMENIA',
    label: 'Barmenia',
  },
  {
    id: 'HANSE_MERKUR',
    label: 'Hanse Merkur',
  },
  {
    id: 'SQUARE_LIFE',
    label: 'Square Life',
  },
];

const requiredError = 'This field is required.';

const baseSchema = {
  provider: z.string().min(1, { message: requiredError }),
  status: z.string().min(1, { message: requiredError }),
  startDate: z.string().optional(),
  policyNumber: z.string().optional(),
  price: z.string().optional(),
  filename: z.string().optional(),
  file: z.custom<File>().optional(),
};

export const createPolicySubmissionSchema = z.union([
  z.object({
    customerId: z.string(),
    newCustomer: z.literal(false).optional(),
    ...baseSchema,
  }),
  z.object({
    newCustomer: z.literal(true),
    firstName: z.string().min(1, { message: requiredError }),
    lastName: z.string().min(1, { message: requiredError }),
    email: z.string().min(1, { message: requiredError }),
    dateOfBirth: z.string().min(1, { message: requiredError }),
    ...baseSchema,
  }),
]);

export const createPolicyFormData = (
  customers: any[],
  setCustomersSearchQuery: (query: string) => void,
  customersLoading: boolean
): ModalFormData<CreatePolicySchema, string>[] => {
  return [
    {
      id: 'newCustomer',
      label: '',
      renderDetails: {
        type: 'radio',
        options: [
          {
            label: 'Existing customer',
            value: false,
          },
          {
            label: 'New customer',
            value: true,
          },
        ],
      },
      color: 'gray',
      required: true,
    },
    {
      id: 'customerId',
      label: 'Customer',
      renderDetails: {
        type: 'autoSuggest',
        options: customers.map(({ id, firstName, lastName, email }) => ({
          id,
          label: `${firstName} ${lastName}, ${email}`,
        })),
        placeholder: 'Enter name, email or policy number',
        setExternalQuery: (query: string) => {
          setCustomersSearchQuery(query);
        },
        useUnfilteredOptions: true,
      },
      color: 'gray',
      required: true,
      loading: customersLoading,
      fieldDependencies: [{ fieldId: 'newCustomer', requiredValue: false }],
    },
    {
      id: 'firstName',
      label: 'First name',
      renderDetails: {
        type: 'text',
        placeholder: "Customer's first name",
      },
      color: 'gray',
      required: true,
      fieldDependencies: [{ fieldId: 'newCustomer', requiredValue: true }],
    },
    {
      id: 'lastName',
      label: 'Last name',
      renderDetails: {
        type: 'text',
        placeholder: "Customer's last name",
      },
      color: 'gray',
      required: true,
      fieldDependencies: [{ fieldId: 'newCustomer', requiredValue: true }],
    },
    {
      id: 'dateOfBirth',
      label: 'Date of birth',
      renderDetails: {
        type: 'date',
        maxDate: new Date().toISOString(),
      },
      color: 'gray',
      required: true,
      fieldDependencies: [{ fieldId: 'newCustomer', requiredValue: true }],
    },
    {
      id: 'email',
      label: 'Email',
      renderDetails: {
        type: 'text',
        placeholder: "Enter customer's email",
      },
      color: 'gray',
      required: true,
      divider: true,
      fieldDependencies: [{ fieldId: 'newCustomer', requiredValue: true }],
    },
    {
      id: 'provider',
      label: 'Provider',
      renderDetails: {
        type: 'select',
        options: providerOptions,
        placeholder: 'Select provider',
      },
      required: true,
      color: 'gray',
    },
    {
      id: 'status',
      label: 'Status',
      renderDetails: {
        type: 'select',
        options: statusOptions,
        placeholder: 'Select status',
      },
      required: true,
      color: 'gray',
    },
    {
      id: 'startDate',
      label: 'Start date',
      renderDetails: {
        type: 'date',
        maxDate: '',
      },
      color: 'gray',
    },
    {
      id: 'policyNumber',
      label: 'Policy Number',
      renderDetails: {
        type: 'text',
        placeholder: 'Enter policy number',
      },
      color: 'gray',
    },
    {
      id: 'price',
      label: 'Price',
      renderDetails: {
        type: 'currency',
        placeholder: '0.00',
      },
      color: 'gray',
    },
    {
      id: 'fileName',
      label: 'Document filename',
      renderDetails: {
        type: 'text',
        placeholder: "Enter document's filename",
      },
      color: 'gray',
    },
    {
      id: 'file',
      label: 'Upload document',
      renderDetails: {
        type: 'upload',
      },
      color: 'gray',
    },
  ];
};
