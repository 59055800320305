import { useTranslation } from '@getpopsure/i18n-react';
import DocumentsCard, { DocumentsSectionData } from 'components/DocumentsCard';
import { SelectMenuOption } from 'components/SelectMenu';
import {
  DELETE_PROVIDER_POLICY_FILE,
  UPLOAD_PROVIDER_POLICY_FILE,
} from 'graphql/publicHealth/provider/mutations';
import { GET_PUBLIC_PROVIDER_POLICY } from 'graphql/publicHealth/provider/queries';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';

interface DocumentsSectionProps {
  data: PublicHealthPolicy;
}

const DocumentsSection = ({ data }: DocumentsSectionProps) => {
  const { t } = useTranslation();
  const documents: DocumentsSectionData<string>[] = [
    {
      title: t('admin.documents.category.policy.title', 'Policy documents'),
      documents: [
        {
          fileLink:
            data.mergedConfirmationOfCoverageUrl ??
            data.confirmationOfCoverageFromProviderUrl ??
            data.confirmationOfCoverageUrl,
          fileName: t(
            'admin.documents.type.finalconfirmation',
            'Final confirmation of coverage'
          ),
        },
        {
          fileLink: data.powerOfAttorneyUrl,
          fileName: t(
            'admin.documents.type.powerofattorney',
            'Power of attorney'
          ),
        },
      ],
    },
    {
      title: t('admin.documents.category.customer.title', 'Customer uploads'),
      documents: [
        {
          fileLink: data.healthCard?.photoUrl,
          fileName: t(
            'admin.documents.type.healthcardphoto',
            'Health card photo'
          ),
        },
        {
          fileLink:
            data.occupation === 'UNIVERSITY_STUDENT'
              ? data.studentChecklist?.uniEnrollmentCertificateUrl
              : undefined,
          fileName: t(
            'admin.documents.type.enrollmentcertificate',
            'Enrollment certificate'
          ),
        },
        {
          fileLink:
            data.occupation === 'UNIVERSITY_STUDENT'
              ? data.sepaMandateUrl
              : undefined,
          fileName: t('admin.documents.type.sepamandate', 'SEPA mandate'),
        },
        {
          fileLink:
            data.occupation === 'UNIVERSITY_STUDENT'
              ? data.confirmationOfPermanentResidenceDocumentUrl
              : undefined,
          fileName: t(
            'admin.documents.type.confirmationpermanentresidence',
            'Confirmation of permanent residence'
          ),
        },
        {
          fileLink:
            data.occupation === 'UNIVERSITY_STUDENT'
              ? data.studentChecklist?.acceptanceLetterUrl
              : undefined,
          fileName: 'Acceptance letter',
        },
        {
          fileLink: data.applicationDocumentUrl,
          fileName: t(
            'admin.documents.type.applicationdoc',
            'Application document'
          ),
        },
        {
          fileLink:
            data.occupation === 'SELF_EMPLOYED' || data.occupation === 'OTHER'
              ? data.signedTKApplicationUrl
              : undefined,
          fileName: t(
            'admin.documents.type.signedtkapplication',
            'Signed TK application'
          ),
        },
      ],
    },
    {
      title: t('admin.documents.category.feather.title', 'Feather uploads'),
      documents: [],
    },
    {
      title: t('admin.documents.category.provider.title', 'Provider uploads'),
      documents: [
        {
          fileLink: data.preliminaryConfirmationOfCoverage?.url,
          fileName: t(
            'admin.documents.type.prelimconfirmation',
            'Prelim. confirmation of coverage'
          ),
          id: data.preliminaryConfirmationOfCoverage?.id,
          blobName: data.preliminaryConfirmationOfCoverage?.blobName,
          canDelete: true,
          documentType: 'PRELIMINARY_CONFIRMATION_OF_COVERAGE',
        },
      ],
    },
  ];

  data.otherDocumentsFromProvider.forEach((document) => {
    documents[3].documents.push({
      fileLink: document.url,
      fileName: document?.name ?? 'Untitled document',
      canDelete: true,
      id: document.id,
      blobName: document.blobName,
      documentType: 'OTHER_INTERNAL',
    });
  });

  data.otherDocumentsFromFeather.forEach((document) => {
    documents[2].documents.push({
      fileLink: document.url,
      fileName: document?.name ?? 'Untitled document',
    });
  });

  const fileTypeOptions: SelectMenuOption[] = [
    {
      id: 'CONFIRMATION_OF_COVERAGE',
      label: t(
        'admin.documents.type.finalconfirmation',
        'Final confirmation of coverage'
      ),
    },
    {
      id: 'PRELIMINARY_CONFIRMATION_OF_COVERAGE',
      label: t(
        'admin.documents.type.prelimconfirmation',
        'Prelim. confirmation of coverage'
      ),
    },
    {
      id: 'OTHER',
      label: t('admin.documents.type.other.label', 'Other'),
    },
  ];

  return (
    <>
      <DocumentsCard
        sections={documents}
        fileTypeOptions={fileTypeOptions}
        mutation={UPLOAD_PROVIDER_POLICY_FILE}
        getPolicyMutation={GET_PUBLIC_PROVIDER_POLICY}
        policyId={data.id}
        deleteMutation={DELETE_PROVIDER_POLICY_FILE}
      />
    </>
  );
};

export default DocumentsSection;
