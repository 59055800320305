import { getFixedT } from '@getpopsure/i18n-react';

export const mapHealthInsuranceType = (type: string): string => {
  const t = getFixedT();
  switch (type) {
    case 'PUBLIC_HEALTH':
      return t(
        'admin.provider.policy.public.questionaire.healthinsurancetype.publichealth.label',
        'Public Health'
      );
    case 'PRIVATE_HEALTH':
      return t(
        'admin.provider.policy.public.questionaire.healthinsurancetype.privatehealth.label',
        'Private Health'
      );
    case 'TRAVEL_INSURANCE':
      return t(
        'admin.provider.policy.public.questionaire.healthinsurancetype.travelinsurance.label',
        'Travel Insurance'
      );
    case 'EXPAT_INSURANCE':
      return t(
        'admin.provider.policy.public.questionaire.healthinsurancetype.expatinsurance.label',
        'Expat Insurance'
      );
    default:
      return t(
        'admin.provider.policy.public.questionaire.healthinsurancetype.other.label',
        'Other'
      );
  }
};
