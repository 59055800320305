import { useMutation } from '@apollo/client';
import Button from 'components/Button';
import dayjs from 'dayjs';
import { useState } from 'react';
import { alertBanner } from 'shared/reactiveVariables';
import { downloadBlob } from 'shared/utils/downloadBlob';
import { getMimeType } from 'shared/utils/getMimeType';

import { EXPORT_QONTO_TRANSFERS_CSV } from '../../graphql/exportCSVFile';
import { GET_QONTO_TRANSACTIONS } from '../../graphql/transactions';
import ExportConfirmationModal from '../ExportConfirmationModal';

const ExportButton = ({ insuranceType }: { insuranceType: string }) => {
  const [openModal, setOpenModal] = useState(false);
  const [exportCSV, { loading }] = useMutation(EXPORT_QONTO_TRANSFERS_CSV, {
    refetchQueries: [GET_QONTO_TRANSACTIONS],
    onCompleted: () => {
      alertBanner({
        type: 'SUCCESS',
        message: 'Transactions successfully exported as CSV file.',
      });
      setOpenModal(false);
    },
    onError: () => {
      alertBanner({
        type: 'WARNING',
        message: 'Something went wrong. Please try again.',
      });
      setOpenModal(false);
    },
  });

  const fetchBlob = async (url: string) => {
    const response = await fetch(url ?? '', {
      method: 'GET',
      headers: {
        'Content-Type': getMimeType('export.csv'),
      },
    });
    let blob = await response.blob();
    if (!blob.type) {
      blob = new Blob([blob], {
        type: getMimeType('export.csv'),
      });
    }

    return blob;
  };

  const handleClickOnExport = () => {
    exportCSV({
      variables: {
        insuranceType,
      },
    }).then(async (result) => {
      const fileUrl = result.data.exportTransfersCSV;
      const blob = await fetchBlob(fileUrl);
      const insuranceTypeName =
        insuranceType === 'INCOMING' ? 'expat' : insuranceType.toLowerCase();
      downloadBlob(
        blob,
        `${insuranceTypeName} claims qonto export - ${dayjs().format(
          'DD/MM/YYYY'
        )}.csv`
      );
    });
  };

  return (
    <>
      <Button
        buttonType="primary"
        className="w-32"
        onClick={() => setOpenModal(true)}
      >
        Export as CSV
      </Button>
      {openModal && (
        <ExportConfirmationModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          handleClickOnExport={handleClickOnExport}
          loadingExport={loading}
          insuranceType={insuranceType}
        />
      )}
    </>
  );
};

export default ExportButton;
