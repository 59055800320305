import { DocumentNode } from '@apollo/client';
import { ExternalLink, InternalLink } from 'components/DetailsPage/models';
import { InformationSection } from 'components/InformationCard';
import { ConfirmationModalProps } from 'components/InformationCard/EditableInformationCard';
import { ModalFormData } from 'components/ModalForm/models';
import { SelectButtonOption } from 'components/SelectButton';
import { StatusUpdateFormQuestion } from 'components/StatusUpdateModal/models';
import { HeaderInformation, TableData } from 'components/Table';
import { Filter } from 'components/Table/Filters';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import {
  HeaderConfig,
  RowConfig,
  ViewConfig,
} from 'components/TableRefactor/types';
import { FilterDetails } from 'hooks/useTableQueryParams';
import { InsuranceType } from 'shared/insurances/types';
import { z, ZodRawShape } from 'zod';

import { BikeClaimsData } from '../bike/modules/overview/models';
import { DentalClaimsData } from '../dental/modules/overview/models';
import { DogLiabilityClaimsData } from '../dogLiability/modules/overview/models';
import { ExpatHealthClaimsData } from '../expatHealth/modules/overview/models';
import { GenericClaimsData } from '../generic/modules/overview/models';
import { HouseholdClaimsData } from '../household/modules/overview/models';
import { LegalClaimsData } from '../legal/modules/overview/models';
import { LiabilityClaimsData } from '../liability/modules/overview/models';
import { PrivateHealthClaimsData } from '../privateHealth/modules/overview/models';
import { SubmittableClaim } from './components/CreateClaimModal/models';

export const NUMBER_OF_CLAIMS_PER_PAGE = 12;

export const insurancePathNames = [
  'private-health',
  'expat-health',
  'dental',
  'liability',
  'household',
  'travel',
  'pet-health',
  'company-health',
  'company-life',
  'disability',
  'bike',
  'legal',
  'dog-liability',
  'expat-health-es',
] as const;
export type InsurancePathName = typeof insurancePathNames[number];

export type CombinedClaimsData =
  | PrivateHealthClaimsData
  | ExpatHealthClaimsData
  | DentalClaimsData
  | LiabilityClaimsData
  | HouseholdClaimsData
  | GenericClaimsData
  | BikeClaimsData
  | LegalClaimsData
  | DogLiabilityClaimsData;

export interface ClaimsTemplateData<
  Status extends string,
  Type extends string
> {
  insuranceType: InsuranceType;
  queries: {
    fetchAllClaims: DocumentNode;
    fetchSingleClaim: DocumentNode;
    fetchAllPolicies: DocumentNode;
  };
  mutations: {
    createClaim?: DocumentNode;
    updateClaim: DocumentNode;
    updateClaimStatus: DocumentNode;
    submitClaim?: DocumentNode;
  };
  overview: {
    headers: HeaderConfig[];
    filterConfig: FilterConfig[];
    rowConfig: RowConfig;
    viewConfig?: ViewConfig;
  };
  claimCreation?: ClaimCreationData<Status, Type>;
  claimsDetails?: ClaimsDetails;
  policyInfo: PolicyInfo;
  claimInfo: ClaimInfo;
  statusUpdate: StatusUpdate<Status>;
}

export interface OverviewData<ClaimsData> {
  filter: {
    details: FilterDetails[];
    tableFilters: (
      filter: Record<
        string,
        SelectButtonOption | SelectButtonOption[] | undefined
      >,
      dateFilter: Record<
        string,
        [Date | undefined, Date | undefined] | undefined
      >
    ) => Filter[];
  };
  tableHeaders: HeaderInformation[];
  retrieveTableData: (
    data: ClaimsData,
    { generateClaimNumber }: { generateClaimNumber?: (claimId: string) => void }
  ) => TableData;
}

export interface ClaimCreationData<Status extends string, Type extends string> {
  createClaimSubmissionSchema: z.ZodObject<ZodRawShape>;
  retrieveFormData: (
    // TODO: [KONG] Update type
    policies: any[],
    setPolicySearchQuery: (query: string) => void,
    policiesLoading: boolean
  ) => ModalFormData<SubmittableClaim<Status, Type>, string>[];
}

export interface ClaimsDetails {
  externalLinks?: ExternalLink[];
  internalLinks?: InternalLink[];
}

export interface PolicyInfo {
  // TODO: [KONG] Update arg type
  retrievePolicyInfoRows: (policyInfo: any) => InformationSection[];
}

export interface ClaimInfo {
  retrieveClaimInfoRows: (
    claimInfo: any,
    { generateClaimNumber }: { generateClaimNumber?: (claimId: string) => void }
  ) => InformationSection[];
  retrieveAdditionalClaimInfoRows?: (claimInfo: any) => InformationSection[];
  ClaimInfoConfirmationModal?: React.ElementType<ConfirmationModalProps>;
  retrieveClaimInfoOldConfirmationValue?: (
    claimInfo: any
  ) => { id: string; value: string | number }[];
}

export interface StatusData {
  footerText?: string;
  form: StatusUpdateFormQuestion[];
}

export interface StatusUpdate<Status> {
  statusOptions: SelectButtonOption<Status>[];
  dataByStatus: (status: Status) => StatusData;
}
