import { useMutation } from '@apollo/client';
import { AlertBannerView } from 'components/AlertBanner/AlertBanner.view';
import Badge from 'components/Badge';
import Button from 'components/Button';
import Modal from 'components/Modal';
import TextArea from 'components/TextArea';
import { DocumentNode } from 'graphql';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SubmitClaim = ({
  claimId,
  mutation,
}: {
  claimId: string;
  mutation: DocumentNode;
}) => {
  const { t } = useTranslation();
  const [submitClaim, { loading }] = useMutation(mutation);
  const [comment, setComment] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  const executeMutation = () => {
    submitClaim({
      variables: { claimId, comment },
      onCompleted: () => setShowBanner(true),
    });
    setShowModal(false);
    setComment('');
  };

  return (
    <>
      <Button
        buttonType="primary"
        className="ml-4"
        loading={loading}
        onClick={() => setShowModal(true)}
        data-testid="send-to-provider-button"
      >
        Send to provider
      </Button>
      {showBanner && (
        <AlertBannerView
          message="Claim is being submitted to provider in background. It may take a few minutes."
          onClose={() => setShowBanner(false)}
          status="OPEN"
          type="SUCCESS"
        />
      )}
      <Modal
        title={t(
          'component.claims.template.sendToProviderModal.title',
          'Send to provider'
        )}
        open={showModal}
        setOpen={setShowModal}
        handleConfirm={executeMutation}
      >
        <>
          <p className="mt-[12px] text-sm text-gray-600">
            {t(
              'component.claims.template.sendToProviderModal.statusNotice',
              'After you send the claim to the provider, the status will change.'
            )}
          </p>
          <h2 className="mt-[24px] text-sm font-bold text-gray-900">
            {t(
              'component.claims.template.sendToProviderModal.label.newStatus',
              'New status'
            )}
          </h2>
          <Badge className="mt-[8px]" color="blue" badgeType="full">
            Sent
          </Badge>

          <h2 className="mt-[24px] text-sm font-bold text-gray-900">
            {t(
              'component.claims.template.sendToProviderModal.label.messageToProvider',
              'Message to provider'
            )}
            <span className="text-gray-500">
              {t(
                'component.claims.template.sendToProviderModal.label.messageToProvider.optional',
                ' (optional)'
              )}
            </span>
          </h2>
          <TextArea
            className="mt-2"
            color="gray"
            placeholder="Start typing..."
            value={comment}
            onChange={(ev) => setComment(ev.target.value)}
          />
        </>
      </Modal>
    </>
  );
};
