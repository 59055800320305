import { DocumentNode, useMutation, useQuery } from '@apollo/client';
import { ModalForm } from 'components/ModalForm';
import { useDebounce } from 'hooks/useDebounce';
import { useState } from 'react';
import { InsuranceType } from 'shared/insurances/types';
import { alertBanner } from 'shared/reactiveVariables';

import { ClaimCreationData } from '../../models';
import { SubmittableClaim } from './models';
import * as styles from './styles';
import { retrievePoliciesData } from './utils';

interface Props<Status extends string, Type extends string> {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  claimCreation: ClaimCreationData<Status, Type>;
  insuranceType: InsuranceType;
  createClaimMutation: DocumentNode;
  refetchQueries: DocumentNode[];
  fetchPoliciesQuery: DocumentNode;
}

export const CreateClaimModal = <Status extends string, Type extends string>({
  isOpen,
  setIsOpen,
  claimCreation: { createClaimSubmissionSchema, retrieveFormData },
  insuranceType,
  createClaimMutation,
  refetchQueries,
  fetchPoliciesQuery,
}: Props<Status, Type>) => {
  const [policySearchQuery, setPolicySearchQuery] = useState('');
  const debouncedValue = useDebounce(policySearchQuery, 1_000);

  const { loading: policiesLoading, data: policiesData } = useQuery(
    fetchPoliciesQuery,
    {
      variables: {
        searchString: debouncedValue,
        /**
         * TODO: [KONG] Remove variables when they are updated to optional fields
         * Below values are empty or default to fetch policies
         */
        offset: 0,
        limit: 20,
        sortColumn: '',
        sortOrder: '',
        filterStatus: [],
        applicationSent: 'any',
        filterOccupation: [],
        insuranceType,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const onSubmitSuccess = () => {
    setIsOpen(false);
    alertBanner({
      type: 'SUCCESS',
      message: 'New claim was successfully created.',
    });
  };

  const onSubmitError = () => {
    setIsOpen(false);
    alertBanner({
      type: 'WARNING',
      message: 'Something went wrong. Please try again.',
    });
  };

  const [createClaim, { loading: createClaimLoading }] = useMutation(
    createClaimMutation,
    {
      refetchQueries,
      notifyOnNetworkStatusChange: true,
      onCompleted: onSubmitSuccess,
      onError: onSubmitError,
    }
  );

  const policies = retrievePoliciesData(insuranceType, policiesData);

  const onSubmit = async (answers: Partial<SubmittableClaim<Status, Type>>) => {
    const userId = (
      policies as Array<{ id: string; user: { id: string } }>
    )?.find(({ id }: { id: string }) => id === answers.policyId)?.user?.id;

    const policyNumber = (
      policies as Array<{ id: string; policyNumber: string }>
    )?.find(({ id }: { id: string }) => id === answers.policyId)?.policyNumber;

    if (userId) {
      createClaim({
        variables: {
          ...answers,
          userId,
          insuranceType,
          ...(policyNumber && { policyNumber }),
          ...(answers.amount ? { amount: Number(answers.amount) } : {}),
        },
      });
    }
  };

  const loading = policiesLoading || createClaimLoading;

  const formData = retrieveFormData(
    policies ?? [],
    setPolicySearchQuery,
    policiesLoading
  );

  return (
    <ModalForm<SubmittableClaim<Status, Type>, string>
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={loading}
      submitButtonLabel="Create"
      title="New claim"
      formData={formData}
      onSubmit={onSubmit}
      submitValidationSchema={createClaimSubmissionSchema}
      bottomElement={
        <div className={styles.bottomText}>
          Once confirmed, a new claim will be created, and the customer will be
          able to view it on their claims dashboard.
        </div>
      }
    />
  );
};
