import { useLazyQuery, useMutation } from '@apollo/client';
import Modal from 'components/Modal';
import {
  BATCH_PUBLISH_CUSTOMER_IO_EVENTS,
  UPDATE_MANY_SCANNED_DOCUMENTS,
} from 'pages/scannedDocuments/scannedDocuments.mutations';
import {
  GET_POLICIES_BY_POLICY_NUMBERS,
  GET_SCANNED_DOCUMENTS,
} from 'pages/scannedDocuments/scannedDocuments.queries';
import {
  BasePolicy,
  CustomerIoTrackPayload,
  ScannedDocument,
} from 'pages/scannedDocuments/scannedDocuments.types';
import { useEffect } from 'react';

type TriggerCioEventBulkActionProps = {
  selectedDocuments: ScannedDocument[];
  modalIsShown: boolean;
  closeModal: () => void;
};

type GetPoliciesByPolicyNumbersData = {
  policiesByPolicyNumbers: BasePolicy[] | null;
};

export const TriggerCioEventBulkAction = ({
  selectedDocuments,
  modalIsShown,
  closeModal,
}: TriggerCioEventBulkActionProps) => {
  const newDocuments = selectedDocuments.filter(
    (sDoc) => sDoc.status === 'NEW'
  );

  const newDocumentsWithCustomerIoEvent = newDocuments.flatMap((sDoc) =>
    sDoc.categories.some((c) => c.attributes.customerIoEvent) ? [sDoc] : []
  );

  const documentPolicyNumbers = newDocumentsWithCustomerIoEvent
    .map(({ policyNumber }) => policyNumber)
    .filter(Boolean);

  const [publishEvents, { loading: busyPublishingEvents }] = useMutation(
    BATCH_PUBLISH_CUSTOMER_IO_EVENTS
  );

  const [updateScannedDocuments, { loading: busyUpdatingDocs }] = useMutation(
    UPDATE_MANY_SCANNED_DOCUMENTS,
    {
      refetchQueries: [GET_SCANNED_DOCUMENTS],
    }
  );

  const [getPoliciesByPolicyNumbers, getPoliciesByPolicyNumbersResult] =
    useLazyQuery<GetPoliciesByPolicyNumbersData>(
      GET_POLICIES_BY_POLICY_NUMBERS,
      {
        variables: {
          policyNumbers: documentPolicyNumbers,
        },
      }
    );

  const matchedPolicies =
    getPoliciesByPolicyNumbersResult.data?.policiesByPolicyNumbers;

  const documentAndPolicyPairs = selectedDocuments.flatMap(
    (scannedDocument) => {
      const matchingPolicy = matchedPolicies?.find(
        (policy) => policy.policyNumber === scannedDocument.policyNumber
      );
      if (matchingPolicy) {
        return {
          scannedDocument,
          policy: matchingPolicy,
        };
      }
      return [];
    }
  );

  const customerIoPayloads: CustomerIoTrackPayload[] =
    documentAndPolicyPairs.flatMap(({ scannedDocument, policy }) => {
      const payloads = scannedDocument.categories.flatMap((category) =>
        category.attributes.customerIoEvent
          ? [
              {
                customerId: policy.user.id,
                requestData: {
                  name: category.attributes.customerIoEvent,
                  data: {
                    policyId: policy.id,
                    categoryId: category.id,
                  },
                },
              },
            ]
          : []
      );

      return payloads;
    });

  const idsOfDocsToBeFlippedDone = documentAndPolicyPairs.flatMap(
    ({ scannedDocument }) =>
      scannedDocument.categories.some(
        (category) => !!category.attributes.customerIoEvent
      )
        ? [scannedDocument.id]
        : []
  );

  const uniqueEvents = Array.from(
    new Set(customerIoPayloads.map(({ requestData }) => requestData.name))
  );

  const eventAndCountPair = uniqueEvents.map((eventName) => ({
    eventName,
    count: customerIoPayloads.filter(
      ({ requestData: { name } }) => name === eventName
    ).length,
  }));

  const totalEventsCount = customerIoPayloads.length;

  const handleConfirm = async () => {
    let updateDocumentsResult;
    const publishEventsResult = await publishEvents({
      variables: {
        payloads: customerIoPayloads,
      },
    });

    if (!publishEventsResult.errors) {
      updateDocumentsResult = await updateScannedDocuments({
        variables: {
          ids: idsOfDocsToBeFlippedDone,
          status: 'DONE',
        },
      });
    }

    if (!publishEventsResult.errors && !updateDocumentsResult?.errors) {
      closeModal();
    }
  };

  useEffect(() => {
    const fetchStuff = async () => {
      await getPoliciesByPolicyNumbers();
    };
    if (modalIsShown) {
      fetchStuff();
    }
  }, [modalIsShown]);

  return (
    <>
      {modalIsShown && (
        <Modal
          open={modalIsShown}
          setOpen={closeModal}
          title="Trigger customer.io event(s)"
          confirmButtonLabel="Confirm"
          handleConfirm={handleConfirm}
          loading={busyPublishingEvents || busyUpdatingDocs}
        >
          <div className="text-sm text-gray-600">
            <p className="mb-3">
              Do you really want to trigger the following{' '}
              <span className="font-bold">{totalEventsCount}</span> customer.io{' '}
              event(s)?
            </p>
            {eventAndCountPair.map(({ eventName, count }) => (
              <p className="text-xs" key={eventName}>
                <span className="font-bold">{count}x</span> {eventName}
              </p>
            ))}
            <p className="mt-2">
              This will also switch the corresponding documents to
              &quot;DONE&quot;.
            </p>
          </div>
        </Modal>
      )}
    </>
  );
};
