import { useTranslation } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import InformationCard, {
  InformationSection,
} from 'components/InformationCard';
import DateInformation from 'components/InformationCard/DateInformation';
import MultipleLineInformation from 'components/InformationCard/MultipleLineInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import { OtherIncome } from 'models/OtherIcomeType';
import { PublicHealthPolicy } from 'models/publicHealthPolicy';
import { mapGender } from 'shared/mapGender';
import { mapUserOccupation } from 'shared/mapUserOccupation';

import { mapHealthInsuranceType } from './mapHealthInsuranceType';
import { mapOtherIncomeType } from './mapOtherIncomeType';
import { mapOtherSituation } from './mapOtherSituation';
import * as styles from './styles';

interface QuestionnaireTabProps {
  data: PublicHealthPolicy;
  providerView: boolean;
  additionalAction?: React.ReactNode;
  handlePrint?: () => void;
}

const QuestionnaireTab = ({
  data,
  providerView,
  additionalAction,
  handlePrint,
}: QuestionnaireTabProps) => {
  const { t } = useTranslation();
  let nationality: string;

  const unknownKeys = data.questionnaire?.answers?.unknownKeys as object;

  if (Array.isArray(data.questionnaire?.answers?.nationality)) {
    nationality =
      data.questionnaire?.answers?.nationality.reduce(
        (previousValue, currentValue) => {
          return `${previousValue ? `${previousValue},` : ''} ${
            currentValue.name
          } ${currentValue.code ? `(${currentValue.code})` : ''}`;
        },
        ''
      ) ?? '';
  } else if (
    typeof data.questionnaire?.answers?.nationality === 'object' &&
    data.questionnaire?.answers.nationality !== null
  ) {
    nationality = `${data.questionnaire.answers?.nationality.name} (${data.questionnaire.answers?.nationality.code})`;
  } else {
    nationality = data.questionnaire?.answers?.nationality ?? '';
  }

  const personalInformation: InformationSection[] = [
    {
      title: t(
        'admin.provider.policy.public.questionnaire.personalinfo.title',
        'Personal information'
      ),
      rows: [
        {
          id: 'firstName',
          title: t(
            'admin.provider.policy.public.questionnaire.personalinfo.firstname.title',
            'First name'
          ),
          data: data.questionnaire?.answers?.firstName ?? '',
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'lastName',
          title: t(
            'admin.provider.policy.public.questionnaire.personalinfo.lastname.title',
            'Last name'
          ),
          data: data.questionnaire?.answers?.lastName ?? '',
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'lastNameAtBirth',
          title: t(
            'admin.provider.policy.public.questionnaire.personalinfo.lastnameatbirth.title',
            'Last name at birth'
          ),
          data: data.questionnaire?.answers?.lastNameAtBirth ?? '',
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'gender',
          title: t(
            'admin.provider.policy.public.questionnaire.personalinfo.gender.title',
            'Gender'
          ),
          data: mapGender(t)[data.questionnaire?.answers?.gender ?? 'OTHER'],
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'dateOfBirth',
          title: t(
            'admin.provider.policy.public.questionnaire.personalinfo.dob.title',
            'Date of birth'
          ),
          data: data.questionnaire?.answers?.dateOfBirth ?? '',
          type: 'DATE',
          component: DateInformation,
        },
        {
          id: 'placeOfBirth',
          title: t(
            'admin.provider.policy.public.questionnaire.personalinfo.placeofbirth.title',
            'Place of birth'
          ),
          multipleLinesData: [
            {
              label: t(
                'admin.provider.policy.public.questionnaire.personalinfo.placeofbirth.place.title',
                'Place'
              ),
              data:
                data.questionnaire?.answers?.placeOfBirth === null
                  ? ''
                  : typeof data.questionnaire?.answers?.placeOfBirth ===
                    'object'
                  ? data.questionnaire?.answers?.placeOfBirth.place
                  : data.questionnaire?.answers?.placeOfBirth ?? '',
            },
            {
              label: t(
                'admin.provider.policy.public.questionnaire.personalinfo.placeofbirth.country.title',
                'Country'
              ),
              data:
                data.questionnaire?.answers?.placeOfBirth === null
                  ? ''
                  : typeof data.questionnaire?.answers?.placeOfBirth ===
                    'object'
                  ? typeof data.questionnaire?.answers?.placeOfBirth.country ===
                    'object'
                    ? `${data.questionnaire.answers?.placeOfBirth.country.name} (${data.questionnaire.answers?.placeOfBirth.country?.code})`
                    : data.questionnaire.answers?.placeOfBirth.country
                  : data.questionnaire?.answers?.placeOfBirth ?? '',
            },
          ],
          type: 'MULTIPLELINE',
          component: MultipleLineInformation,
        },
        {
          id: 'nationality',
          title: t(
            'admin.provider.policy.public.questionnaire.personalinfo.nationality.title',
            'Nationality'
          ),
          data: nationality,
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'email',
          title: t(
            'admin.provider.policy.public.questionnaire.personalinfo.email.title',
            'Email'
          ),
          data: data.questionnaire?.answers?.email ?? '',
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'addressInGermany',
          title: t(
            'admin.provider.policy.public.questionnaire.personalinfo.addressgermany.title',
            'Address in Germany?'
          ),
          data: data.questionnaire?.answers?.hasAddressInGermany
            ? t('admin.provider.policy.public.questionnaire.yes.label', 'Yes')
            : t('admin.provider.policy.public.questionnaire.no.label', 'No'),
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'address',
          title: t(
            'admin.provider.policy.public.questionnaire.personalinfo.address.title',
            'Address'
          ),
          multipleLinesData: [
            {
              label: t(
                'admin.provider.policy.public.questionnaire.personalinfo.address.housenumber.title',
                'House number'
              ),
              data: data.questionnaire?.answers?.address?.houseNumber ?? '',
            },
            {
              label: t(
                'admin.provider.policy.public.questionnaire.personalinfo.address.street.title',
                'Street'
              ),
              data: data.questionnaire?.answers?.address?.street ?? '',
            },
            {
              label: t(
                'admin.provider.policy.public.questionnaire.personalinfo.address.city.title',
                'City'
              ),
              data: data.questionnaire?.answers?.address?.city ?? '',
            },
            {
              label: t(
                'admin.provider.policy.public.questionnaire.personalinfo.address.addinfo.title',
                'Additional information'
              ),
              data:
                data.questionnaire?.answers?.address?.additionalInformation ??
                '',
            },
            {
              label: t(
                'admin.provider.policy.public.questionnaire.personalinfo.address.postalcode.title',
                'Postal code'
              ),
              data: data.questionnaire?.answers?.address?.postcode ?? '',
            },
            {
              label: t(
                'admin.provider.policy.public.questionnaire.personalinfo.address.country.title',
                'Country'
              ),
              data: data.questionnaire?.answers?.address?.country ?? '',
            },
          ],
          type: 'MULTIPLELINE',
          component: MultipleLineInformation,
        },
        {
          id: 'employerName',
          title: t(
            'policy.publicHealth.questionnaire.personalInfo.employerName.title',
            'Employer name'
          ),
          data: data.questionnaire?.answers?.employerName ?? '',
          type: 'TEXT',
          component: TextInformation,
        },
      ],
    },
  ];

  const insuranceInformation: InformationSection[] = [
    {
      title: t(
        'admin.provider.policy.public.questionnaire.insuranceinfo.title',
        'Insurance situation'
      ),
      rows: [
        {
          id: 'receivesPension',
          title: t(
            'admin.provider.policy.public.questionnaire.insuranceinfo.receivespension.title',
            'Receives pension'
          ),
          data: data.questionnaire?.answers?.receivesPension
            ? t('admin.provider.policy.public.questionnaire.yes.label', 'Yes')
            : t('admin.provider.policy.public.questionnaire.no.label', 'No'),
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'hasDependentChildren',
          title: t(
            'admin.provider.policy.public.questionnaire.insuranceinfo.hasdependentchildren.title',
            'Has dependent children?'
          ),
          data: data.questionnaire?.answers?.hasDependentChildren
            ? t('admin.provider.policy.public.questionnaire.yes.label', 'Yes')
            : t('admin.provider.policy.public.questionnaire.no.label', 'No'),
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'hasDependentsToCover',
          title: t(
            'admin.provider.policy.public.questionnaire.insuranceinfo.hasdependent.title',
            'Family insurance'
          ),
          data: data.questionnaire?.answers?.hasDependentsToCover
            ? t('admin.provider.policy.public.questionnaire.yes.label', 'Yes')
            : t('admin.provider.policy.public.questionnaire.no.label', 'No'),
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'exemptedFromPensionInsurance',
          title: t(
            'admin.provider.policy.public.questionnaire.insuranceinfo.exemptedpension.title',
            'Exempted from pension insurance?'
          ),
          data: data.questionnaire?.answers?.exemptedFromPensionInsurance
            ? t('admin.provider.policy.public.questionnaire.yes.label', 'Yes')
            : t('admin.provider.policy.public.questionnaire.no.label', 'No'),
          type: 'TEXT',
          component: TextInformation,
        },
        {
          id: 'exemptedFromLongTermSocialInsurance',
          title: t(
            'admin.provider.policy.public.questionnaire.insuranceinfo.exemptedlongterm.title',
            'Exempted from long term social insurance?'
          ),
          data: data.questionnaire?.answers?.exemptedFromLongTermSocialInsurance
            ? t('admin.provider.policy.public.questionnaire.yes.label', 'Yes')
            : t('admin.provider.policy.public.questionnaire.no.label', 'No'),
          type: 'TEXT',
          component: TextInformation,
        },
      ],
    },
  ];

  if (data.occupation !== 'EMPLOYED') {
    insuranceInformation[0].rows.splice(0, 0, {
      id: 'previousCountryOfInsurance',
      title: t(
        'admin.provider.policy.public.questionnaire.insuranceinfo.previouscountryinsurance.title',
        'Previous country of insurance'
      ),
      data: data.questionnaire?.answers?.previousCountryOfInsurance
        ? typeof data.questionnaire?.answers?.previousCountryOfInsurance ===
          'string'
          ? data.questionnaire?.answers?.previousCountryOfInsurance
          : `${data.questionnaire?.answers?.previousCountryOfInsurance?.name} (${data.questionnaire?.answers?.previousCountryOfInsurance?.code})`
        : '',
      type: 'TEXT' as const,
      component: TextInformation,
    });
  }

  if (data.occupation === 'SELF_EMPLOYED') {
    insuranceInformation[0].rows.splice(1, 0, {
      id: 'previousHealthInsuranceType',
      data: mapHealthInsuranceType(
        data.questionnaire?.answers?.previousHealthInsuranceType ?? ''
      ),
      title: t(
        'admin.provider.policy.public.questionnaire.insuranceinfo.previousinsurancetype.title',
        'Previous health insurane type'
      ),
      type: 'TEXT' as const,
      component: TextInformation,
    });
    insuranceInformation[0].rows.splice(2, 0, {
      id: 'coverageStartDate',
      data: data.questionnaire?.answers?.coverageStartDate ?? '',
      title: t(
        'admin.provider.policy.public.questionnaire.insuranceinfo.coveragestartdate.title',
        'Coverage start date'
      ),
      type: 'TEXT',
      component: DateInformation,
    });
  }

  if (data.occupation === 'EMPLOYED') {
    insuranceInformation[0].rows.splice(0, 0, {
      id: 'previousTypeOfGermanInsurance',
      title: t(
        'admin.provider.policy.public.questionnaire.insuranceinfo.previoustypeofgermaninsurance.title',
        'Previous type of german insurance'
      ),
      data: mapHealthInsuranceType(
        data.questionnaire?.answers?.typeOfPreviousGermanHealthInsurance ?? ''
      ),
      type: 'TEXT' as const,
      component: TextInformation,
    });
  }

  insuranceInformation[0].rows.splice(1, 0, {
    id: 'previousInsurer',
    title: t(
      'admin.provider.policy.public.questionnaire.insuranceinfo.previousinsurer.title',
      'Previous insurer'
    ),
    data: data.questionnaire?.answers?.previousInsurer ?? '',
    type: 'TEXT' as const,
    component: TextInformation,
  });

  let employmentSituation: InformationSection[] = [];

  switch (data.occupation) {
    case 'EMPLOYED':
      employmentSituation = [
        {
          title: t(
            'admin.provider.policy.public.questionnaire.employmentsituation.title',
            'Employment situation'
          ),
          rows: [
            {
              id: 'occupation',
              title: t(
                'admin.provider.policy.public.questionnaire.employmentsituation.occupation.title',
                'Occupation'
              ),
              data: mapUserOccupation(
                data.questionnaire?.answers?.occupation ?? ''
              ),
              type: 'TEXT',
              component: TextInformation,
            },
            {
              id: 'firstTimeEmployedInGermany',
              title: t(
                'admin.provider.policy.public.questionnaire.employmentsituation.firsttimeemployed.title',
                'First time employed in Germany?'
              ),
              data: data.questionnaire?.answers?.firstTimeEmployedInGermany
                ? t(
                    'admin.provider.policy.public.questionnaire.yes.label',
                    'Yes'
                  )
                : t(
                    'admin.provider.policy.public.questionnaire.no.label',
                    'No'
                  ),
              type: 'TEXT',
              component: TextInformation,
            },
            {
              id: 'salary',
              title: t(
                'admin.provider.policy.public.questionnaire.employmentsituation.salary.title',
                'Salary'
              ),
              data: data.questionnaire?.answers?.salary ?? '',
              type: 'TEXT',
              component: TextInformation,
            },
            {
              id: 'jobTitle',
              title: t(
                'admin.provider.policy.public.questionnaire.employmentsituation.jobtitle.title',
                'Job title'
              ),
              data: data.questionnaire?.answers?.jobTitle ?? '',
              type: 'TEXT',
              component: TextInformation,
            },
            {
              id: 'jobStartDate',
              title: t(
                'admin.provider.policy.public.questionnaire.employmentsituation.jobstartdate.title',
                'Job start date'
              ),
              data: data.questionnaire?.answers?.jobStartDate ?? '',
              type: 'DATE',
              component: DateInformation,
            },
            {
              id: 'employerName',
              title: t(
                'admin.provider.policy.public.questionnaire.employmentsituation.employername.title',
                'Employer name'
              ),
              data: data.questionnaire?.answers?.employerName ?? '',
              type: 'TEXT',
              component: TextInformation,
            },
            {
              id: 'employerAddress',
              title: t(
                'admin.provider.policy.public.questionnaire.employmentsituation.employeraddress.title',
                'Employer address'
              ),
              multipleLinesData: [
                {
                  label: t(
                    'admin.provider.policy.public.questionnaire.employmentsituation.employeraddress.housenumber.title',
                    'House number'
                  ),
                  data:
                    data.questionnaire?.answers?.companyAddress?.houseNumber ??
                    '',
                },
                {
                  label: t(
                    'admin.provider.policy.public.questionnaire.employmentsituation.employeraddress.street.title',
                    'Street'
                  ),
                  data:
                    data.questionnaire?.answers?.companyAddress?.street ?? '',
                },
                {
                  label: t(
                    'admin.provider.policy.public.questionnaire.employmentsituation.employeraddress.city.title',
                    'City'
                  ),
                  data: data.questionnaire?.answers?.companyAddress?.city ?? '',
                },
                {
                  label: t(
                    'admin.provider.policy.public.questionnaire.employmentsituation.employeraddress.addinfo.title',
                    'Additional information'
                  ),
                  data:
                    data.questionnaire?.answers?.companyAddress
                      ?.additionalInformation ?? '',
                },
                {
                  label: t(
                    'admin.provider.policy.public.questionnaire.employmentsituation.employeraddress.postalcode.title',
                    'Postal code'
                  ),
                  data:
                    data.questionnaire?.answers?.companyAddress?.postcode ?? '',
                },
                {
                  label: t(
                    'admin.provider.policy.public.questionnaire.employmentsituation.employeraddress.country.title',
                    'Country'
                  ),
                  data:
                    data.questionnaire?.answers?.companyAddress?.country ?? '',
                },
              ],
              type: 'MULTIPLELINE',
              component: MultipleLineInformation,
            },
          ],
        },
      ];
      break;
    case 'UNIVERSITY_STUDENT':
      employmentSituation = [
        {
          title: t(
            'admin.provider.policy.public.questionnaire.studentsituation.title',
            'Student situation'
          ),
          rows: [
            {
              id: 'occupation',
              title: t(
                'admin.provider.policy.public.questionnaire.studentsituation.occupation.title',
                'Occupation'
              ),
              data: mapUserOccupation(
                data.questionnaire?.answers?.occupation ?? ''
              ),
              type: 'TEXT',
              component: TextInformation,
            },
            {
              id: 'universityInGermany',
              title: t(
                'admin.provider.policy.public.questionnaire.studentsituation.germanuniversity.title',
                'University in Germany?'
              ),
              data: data.questionnaire?.answers?.universityInGermany
                ? t(
                    'admin.provider.policy.public.questionnaire.yes.label',
                    'Yes'
                  )
                : t(
                    'admin.provider.policy.public.questionnaire.no.label',
                    'No'
                  ),
              type: 'TEXT',
              component: TextInformation,
            },
            {
              id: 'university',
              title: t(
                'admin.provider.policy.public.questionnaire.studentsituation.universityname.title',
                'University'
              ),
              data: data.questionnaire?.answers?.university?.name ?? '',
              type: 'TEXT',
              component: TextInformation,
            },
            {
              id: 'universityStartDate',
              title: t(
                'admin.provider.policy.public.questionnaire.studentsituation.universitystartdate.title',
                'University start date'
              ),
              data: data.questionnaire?.answers?.universityStartDate ?? '',
              type: 'DATE',
              component: DateInformation,
            },
          ],
        },
      ];
      break;
    case 'SELF_EMPLOYED':
      employmentSituation = [
        {
          title: t(
            'admin.provider.policy.public.questionnaire.selfemployedsituation.title',
            'Self-employment situation'
          ),
          rows: [
            {
              id: 'occupation',
              title: t(
                'admin.provider.policy.public.questionnaire.selfemployedsituation.occupation.title',
                'Occupation'
              ),
              data: mapUserOccupation(
                data.questionnaire?.answers?.occupation ?? ''
              ),
              type: 'TEXT',
              component: TextInformation,
            },
            {
              id: 'selfEmploymentStartDate',
              title: t(
                'admin.provider.policy.public.questionnaire.selfemployedsituation.selfemploymentstartdate.title',
                'Self-employment start date'
              ),
              data: data.questionnaire?.answers?.selfEmploymentStartDate ?? '',
              type: 'DATE',
              component: DateInformation,
            },
            {
              id: 'hasTaxNumber',
              title: t(
                'admin.provider.policy.public.questionnaire.selfemployedsituation.taxnumber.title',
                'Tax number'
              ),
              data: data.questionnaire?.answers?.hasTaxNumber
                ? t(
                    'admin.provider.policy.public.questionnaire.yes.label',
                    'Yes'
                  )
                : t(
                    'admin.provider.policy.public.questionnaire.no.label',
                    'No'
                  ),
              type: 'TEXT',
              component: TextInformation,
            },
            {
              id: 'monthlyIncomeForNextYear',
              title: t(
                'admin.provider.policy.public.questionnaire.selfemployedsituation.monthlyincome.title',
                'Monthly income'
              ),
              data: data.questionnaire?.answers?.monthlyIncomeForNextYear ?? '',
              type: 'TEXT',
              component: TextInformation,
            },
            {
              id: 'hasOtherIncome',
              title: t(
                'admin.provider.policy.public.questionnaire.selfemployedsituation.otherincome.title',
                'Other income'
              ),
              data: data.questionnaire?.answers?.hasOtherIncome
                ? t(
                    'admin.provider.policy.public.questionnaire.yes.label',
                    'Yes'
                  )
                : t(
                    'admin.provider.policy.public.questionnaire.no.label',
                    'No'
                  ),
              type: 'TEXT',
              component: TextInformation,
            },
            {
              id: 'otherIncomeType',
              title: t(
                'admin.provider.policy.public.questionnaire.selfemployedsituation.formofotherincome.title',
                'Form of other income'
              ),
              data:
                data.questionnaire?.answers?.otherIncome
                  ?.map((type) => mapOtherIncomeType(type as OtherIncome))
                  .join(', ') ?? '',
              type: 'TEXT',
              component: TextInformation,
            },
            {
              id: 'otherIncomeValue',
              title: t(
                'admin.provider.policy.public.questionnaire.selfemployedsituation.otherincomevalue.title',
                'Other income'
              ),
              data:
                data.questionnaire?.answers?.otherIncomeMonthlyRevenue ?? '',
              type: 'TEXT',
              component: TextInformation,
            },
            {
              id: 'agenturFurArbeitBenefits',
              title: t(
                'admin.provider.policy.public.questionnaire.selfemployedsituation.arbeitsbenefits.title',
                'Agentur fur arbeits benefits'
              ),
              data:
                data.questionnaire?.answers?.agenturFurArbeitBenefits === 'NONE'
                  ? t(
                      'admin.provider.policy.public.questionnaire.no.label',
                      'No'
                    )
                  : t(
                      'admin.provider.policy.public.questionnaire.yes.label',
                      'Yes'
                    ),
              type: 'TEXT',
              component: TextInformation,
            },
          ],
        },
      ];
      break;
    case 'OTHER':
      employmentSituation = [
        {
          title: t(
            'admin.provider.policy.public.questionnaire.selfemployedsituation.otherSituation.title',
            'Other situation'
          ),
          rows: [
            {
              id: 'otherSituation',
              title: t(
                'admin.provider.policy.public.questionnaire.selfemployedsituation.otherSituation.title',
                'Other situation'
              ),
              data:
                mapOtherSituation(
                  data.questionnaire?.answers?.otherSituation
                ) ?? '',
              type: 'TEXT',
              component: TextInformation,
            },
            ...(data.questionnaire?.answers?.otherSituation === 'APPRENTICE' ||
            data.questionnaire?.answers?.otherSituation === 'FSJ'
              ? [
                  {
                    id: 'jobTitle',
                    title: t(
                      'admin.provider.policy.public.questionnaire.employmentsituation.jobtitle.title',
                      'Job title'
                    ),
                    data: data.questionnaire?.answers?.jobTitle ?? '',
                    type: 'TEXT' as const,
                    component: TextInformation,
                  },
                  {
                    id: 'jobStartDate',
                    title: t(
                      'admin.provider.policy.public.questionnaire.employmentsituation.jobstartdate.title',
                      'Job start date'
                    ),
                    data: data.questionnaire?.answers?.jobStartDate ?? '',
                    type: 'DATE' as const,
                    component: DateInformation,
                  },
                  {
                    id: 'employerName',
                    title: t(
                      'admin.provider.policy.public.questionnaire.employmentsituation.employername.title',
                      'Employer name'
                    ),
                    data: data.questionnaire?.answers?.employerName ?? '',
                    type: 'TEXT' as const,
                    component: TextInformation,
                  },
                  {
                    id: 'employerAddress',
                    title: t(
                      'admin.provider.policy.public.questionnaire.employmentsituation.employeraddress.title',
                      'Employer address'
                    ),
                    multipleLinesData: [
                      {
                        label: t(
                          'admin.provider.policy.public.questionnaire.employmentsituation.employeraddress.housenumber.title',
                          'House number'
                        ),
                        data:
                          data.questionnaire?.answers?.companyAddress
                            ?.houseNumber ?? '',
                      },
                      {
                        label: t(
                          'admin.provider.policy.public.questionnaire.employmentsituation.employeraddress.street.title',
                          'Street'
                        ),
                        data:
                          data.questionnaire?.answers?.companyAddress?.street ??
                          '',
                      },
                      {
                        label: t(
                          'admin.provider.policy.public.questionnaire.employmentsituation.employeraddress.city.title',
                          'City'
                        ),
                        data:
                          data.questionnaire?.answers?.companyAddress?.city ??
                          '',
                      },
                      {
                        label: t(
                          'admin.provider.policy.public.questionnaire.employmentsituation.employeraddress.addinfo.title',
                          'Additional information'
                        ),
                        data:
                          data.questionnaire?.answers?.companyAddress
                            ?.additionalInformation ?? '',
                      },
                      {
                        label: t(
                          'admin.provider.policy.public.questionnaire.employmentsituation.employeraddress.postalcode.title',
                          'Postal code'
                        ),
                        data:
                          data.questionnaire?.answers?.companyAddress
                            ?.postcode ?? '',
                      },
                      {
                        label: t(
                          'admin.provider.policy.public.questionnaire.employmentsituation.employeraddress.country.title',
                          'Country'
                        ),
                        data:
                          data.questionnaire?.answers?.companyAddress
                            ?.country ?? '',
                      },
                    ],
                    type: 'MULTIPLELINE' as const,
                    component: MultipleLineInformation,
                  },
                ]
              : []),
          ],
        },
      ];
      break;
  }

  const unknownKeysSection: InformationSection[] = [
    {
      title: 'Other',
      rows: [
        ...(providerView && data.providerId === 'BARMER'
          ? [
              {
                id: 'partnerNumber',
                title: 'Partnernummer',
                data: '131308217',
                type: 'TEXT' as const,
                component: TextInformation,
              },
            ]
          : []),
        ...(unknownKeys
          ? Object.entries(unknownKeys).map(([key, value]) => ({
              id: key,
              title: key,
              data: JSON.stringify(value),
              type: 'TEXT' as const,
              component: TextInformation,
              required: false,
              editable: false,
            }))
          : []),
      ],
    },
  ];

  let questionnaireSections = personalInformation.concat(insuranceInformation);
  if (data.questionnaire) {
    questionnaireSections = questionnaireSections.concat(employmentSituation);
  }
  if (unknownKeysSection[0].rows.length !== 0) {
    questionnaireSections = questionnaireSections.concat(unknownKeysSection);
  }

  const printButton = (
    <Button
      buttonType="secondary"
      onClick={handlePrint}
      className="w-[88px] print:hidden"
    >
      {t('admin.provider.policy.public.questionnaire.print.label', 'Print')}
    </Button>
  );

  return (
    <div className={styles.tabWrapper}>
      <InformationCard
        sections={questionnaireSections}
        additionalActions={
          !providerView ? (
            <div className="flex space-x-2">{additionalAction}</div>
          ) : (
            <div className="flex space-x-2">
              {printButton} {additionalAction}
            </div>
          )
        }
      />
    </div>
  );
};

export default QuestionnaireTab;
