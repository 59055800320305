import { OperationVariables } from '@apollo/client';
import { useFlag } from '@unleash/proxy-client-react';
import { RecentActivity } from 'components/ActivityLog/RecentActivity';
import DocumentsCard, { DocumentsSectionData } from 'components/DocumentsCard';
import { PinnedNotes } from 'components/Notes/PinnedNotes';
import { SelectMenuOption } from 'components/SelectMenu';
import { DocumentNode } from 'graphql';
import { Note } from 'models/Note';
import { Policy } from 'models/policy';
import { PolicyActivity } from 'models/policyActivity';
import { GET_POLICY_ACTIVITY } from 'shared/activityLog/queries';

import { PolicyTemplateInsurancePath } from '../models/InsuranceTypes';
import { OtherPoliciesCard } from '../OtherPoliciesCard';

interface SideSectionProps {
  activity: PolicyActivity[];
  id: string;
  userId: string;
  insuranceType: PolicyTemplateInsurancePath;
  documentsData: {
    sections: DocumentsSectionData[];
    options: SelectMenuOption[];
    uploadMutation?: DocumentNode;
    deleteFileMutation?: DocumentNode;
    getPolicyQuery: DocumentNode;
    additionalQueries?: OperationVariables;
  };
  policies?: Policy[];
  pinnedNotes?: Note[];
  refetchQueries?: DocumentNode[];
  hrPage?: boolean;
}

// TODO: [KONG] Refactor component for insurance type
const SideSection = ({
  activity,
  id,
  userId,
  insuranceType,
  documentsData,
  policies,
  refetchQueries,
  pinnedNotes,
  hrPage = false,
}: SideSectionProps) => {
  const isNotesTabVisible = useFlag('admin_panel_notes_v1_policies');

  // Removes policy that user is currently on
  const filteredPolicies = policies?.filter(
    ({ id: otherPolicyId }) => otherPolicyId !== id
  );

  return (
    <div className="w-full lg:ml-[20px] lg:w-auto">
      {isNotesTabVisible && !hrPage && (
        <PinnedNotes
          notes={pinnedNotes ?? []}
          refetchQueries={refetchQueries}
        />
      )}
      {id && !hrPage && (
        <RecentActivity
          query={GET_POLICY_ACTIVITY}
          variables={{ policyId: id, userId, pageSize: 3 }}
        />
      )}
      {!hrPage && filteredPolicies && filteredPolicies.length > 0 && (
        <OtherPoliciesCard
          policies={filteredPolicies}
          title={`Other ${insuranceType} policies`}
          /**
           * Generic policies will return "generic" here from prop.
           * Type needs to be replaced
           */
          insuranceType={insuranceType}
        />
      )}
      {documentsData && documentsData.uploadMutation && (
        <DocumentsCard
          sections={documentsData.sections}
          fileTypeOptions={documentsData.options}
          mutation={documentsData.uploadMutation}
          getPolicyMutation={documentsData.getPolicyQuery}
          policyId={id}
          extraVariables={documentsData.additionalQueries}
          deleteMutation={documentsData.deleteFileMutation}
        />
      )}
    </div>
  );
};

export default SideSection;
