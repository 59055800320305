import { BriefcaseIcon } from '@heroicons/react/outline';
import { retrievePoliciesOverviewPath } from 'pages/policies/template/utils';
import { generatePath } from 'react-router';
import { paths } from 'routes/definedPaths';
import { Country } from 'shared/countries/models';

import { NavigationLink } from '../NavBar';

const healthPoliciesLinks = [
  {
    name: 'Public health',
    href: paths.policies.publicHealth.internal.path,
    id: 'policies-public',
  },
  {
    name: 'Private health',
    href: paths.policies.privateHealth.internal.path,
    id: 'policies-private',
  },
  {
    name: 'Expat health',
    href: paths.policies.expatHealth.internal.path,
    id: 'policies-expat',
  },
];

const nonHealthPoliciesLinks = [
  {
    name: 'Dental',
    href: retrievePoliciesOverviewPath('dental'),
    id: 'policies-dental',
  },
  {
    name: 'Household',
    href: retrievePoliciesOverviewPath('household'),
    id: 'policies-household',
  },
  {
    name: 'Liability',
    href: retrievePoliciesOverviewPath('liability'),
    id: 'policies-liability',
  },
  {
    name: 'Legal',
    href: retrievePoliciesOverviewPath('legal'),
    id: 'policies-legal',
  },
  {
    name: 'Car',
    href: generatePath(paths.policies.internal.path, {
      insuranceType: 'car',
      country: 'de',
    }),
    id: 'policies-car',
  },
  {
    name: 'Disability',
    href: generatePath(paths.policies.internal.path, {
      insuranceType: 'disability',
      country: 'de',
    }),
    id: 'policies-disability',
  },
  {
    name: 'Pet health',
    href: generatePath(paths.policies.internal.path, {
      insuranceType: 'pet-health',
      country: 'de',
    }),
    id: 'policies-pet-health',
  },
  {
    name: 'Travel',
    href: generatePath(paths.policies.internal.path, {
      insuranceType: 'travel',
      country: 'de',
    }),
    id: 'policies-travel',
  },
  {
    name: 'Company life',
    href: generatePath(paths.policies.internal.path, {
      insuranceType: 'company-life',
      country: 'de',
    }),
    id: 'policies-company-life',
  },
  {
    name: 'Company health',
    href: generatePath(paths.policies.internal.path, {
      insuranceType: 'company-health',
      country: 'de',
    }),
    id: 'policies-company-health',
  },
  {
    name: 'Pension',
    href: generatePath(paths.policies.internal.path, {
      insuranceType: 'pension',
      country: 'de',
    }),
    id: 'policies-pension',
  },
  {
    name: 'Basis pension',
    href: generatePath(paths.policies.internal.path, {
      insuranceType: 'basis-pension',
      country: 'de',
    }),
    id: 'policies-basis-pension',
  },
  {
    name: 'Life',
    href: retrievePoliciesOverviewPath('life'),
    id: 'policies-life',
  },
  {
    name: 'Bike',
    href: retrievePoliciesOverviewPath('bike'),
    id: 'policies-bike',
  },
  {
    name: 'Dog liability',
    href: retrievePoliciesOverviewPath('dog-liability'),
    id: 'policies-dog-liability',
  },
  {
    name: 'Generic',
    href: retrievePoliciesOverviewPath('generic'),
    id: 'policies-generic',
  },
  {
    name: 'Company pension',
    href: retrievePoliciesOverviewPath('company-pension'),
    id: 'policies-company-pension',
  },
];

export const germanyPoliciesLinks: NavigationLink = {
  name: 'Policies',
  icon: BriefcaseIcon,
  id: 'policies-de',
  children: [
    ...healthPoliciesLinks,
    ...nonHealthPoliciesLinks.sort((a, b) => a.name.localeCompare(b.name)),
  ],
};

export const spainPoliciesLinks: NavigationLink = {
  name: 'Policies',
  icon: BriefcaseIcon,
  id: 'policies-es',
  children: [
    {
      name: 'Expat health',
      href: retrievePoliciesOverviewPath('expat-health-es', 'es'),
      id: 'policies-expat-health-es',
    },
  ],
};

export const francePoliciesLinks: NavigationLink = {
  name: 'Policies',
  icon: BriefcaseIcon,
  id: 'policies-fr',
  children: [
    {
      name: 'Travel',
      href: retrievePoliciesOverviewPath('travel-fr', 'fr'),
      id: 'policies-travel-fr',
    },
  ],
};

export const euPoliciesLinks: NavigationLink = {
  name: 'Policies',
  icon: BriefcaseIcon,
  id: 'policies-eu',
  children: [
    {
      name: 'Expat health',
      href: retrievePoliciesOverviewPath('expat-health-eu', 'eu'),
      id: 'policies-expat-health-eu-eu',
    },
  ],
};

export const nlPoliciesLinks: NavigationLink = {
  name: 'Policies',
  icon: BriefcaseIcon,
  id: 'policies-nl',
  children: [
    {
      name: 'Expat health',
      href: retrievePoliciesOverviewPath('expat-health-eu', 'nl'),
      id: 'policies-expat-health-eu-nl',
    },
  ],
};

export const itPoliciesLinks: NavigationLink = {
  name: 'Policies',
  icon: BriefcaseIcon,
  id: 'policies-it',
  children: [
    {
      name: 'Expat health',
      href: retrievePoliciesOverviewPath('expat-health-eu', 'it'),
      id: 'policies-expat-health-eu-it',
    },
  ],
};

export const bePoliciesLinks: NavigationLink = {
  name: 'Policies',
  icon: BriefcaseIcon,
  id: 'policies-be',
  children: [
    {
      name: 'Expat health',
      href: retrievePoliciesOverviewPath('expat-health-eu', 'be'),
      id: 'policies-expat-health-eu-be',
    },
  ],
};

export const sePoliciesLinks: NavigationLink = {
  name: 'Policies',
  icon: BriefcaseIcon,
  id: 'policies-se',
  children: [
    {
      name: 'Expat health',
      href: retrievePoliciesOverviewPath('expat-health-eu', 'se'),
      id: 'policies-expat-health-eu-se',
    },
  ],
};

export const mtPoliciesLinks: NavigationLink = {
  name: 'Policies',
  icon: BriefcaseIcon,
  id: 'policies-mt',
  children: [
    {
      name: 'Expat health',
      href: retrievePoliciesOverviewPath('expat-health-eu', 'mt'),
      id: 'policies-expat-health-eu-mt',
    },
  ],
};

export const atPoliciesLinks: NavigationLink = {
  name: 'Policies',
  icon: BriefcaseIcon,
  id: 'policies-at',
  children: [
    {
      name: 'Expat health',
      href: retrievePoliciesOverviewPath('expat-health-eu', 'at'),
      id: 'policies-expat-health-eu-at',
    },
  ],
};

export const fiPoliciesLinks: NavigationLink = {
  name: 'Policies',
  icon: BriefcaseIcon,
  id: 'policies-fi',
  children: [
    {
      name: 'Expat health',
      href: retrievePoliciesOverviewPath('expat-health-eu', 'fi'),
      id: 'policies-expat-health-eu-fi',
    },
  ],
};

export const cyPoliciesLinks: NavigationLink = {
  name: 'Policies',
  icon: BriefcaseIcon,
  id: 'policies-cy',
  children: [
    {
      name: 'Expat health',
      href: retrievePoliciesOverviewPath('expat-health-eu', 'cy'),
      id: 'policies-expat-health-eu-cy',
    },
  ],
};

export const grPoliciesLinks: NavigationLink = {
  name: 'Policies',
  icon: BriefcaseIcon,
  id: 'policies-gr',
  children: [
    {
      name: 'Expat health',
      href: retrievePoliciesOverviewPath('expat-health-eu', 'gr'),
      id: 'policies-expat-health-eu-gr',
    },
  ],
};

export const eePoliciesLinks: NavigationLink = {
  name: 'Policies',
  icon: BriefcaseIcon,
  id: 'policies-ee',
  children: [
    {
      name: 'Expat health',
      href: retrievePoliciesOverviewPath('expat-health-eu', 'ee'),
      id: 'policies-expat-health-eu-ee',
    },
  ],
};

export const ptPoliciesLinks: NavigationLink = {
  name: 'Policies',
  icon: BriefcaseIcon,
  id: 'policies-pt',
  children: [
    {
      name: 'Expat health',
      href: retrievePoliciesOverviewPath('expat-health-eu', 'pt'),
      id: 'policies-expat-health-eu-pt',
    },
  ],
};

export const ltPoliciesLinks: NavigationLink = {
  name: 'Policies',
  icon: BriefcaseIcon,
  id: 'policies-lt',
  children: [
    {
      name: 'Expat health',
      href: retrievePoliciesOverviewPath('expat-health-eu', 'lt'),
      id: 'policies-expat-health-eu-lt',
    },
  ],
};

export const hrPoliciesLinks: NavigationLink = {
  name: 'Policies',
  icon: BriefcaseIcon,
  id: 'policies-hr',
  children: [
    {
      name: 'Expat health',
      href: retrievePoliciesOverviewPath('expat-health-eu', 'hr'),
      id: 'policies-expat-health-eu-hr',
    },
  ],
};

export const lvPoliciesLinks: NavigationLink = {
  name: 'Policies',
  icon: BriefcaseIcon,
  id: 'policies-lv',
  children: [
    {
      name: 'Expat health',
      href: retrievePoliciesOverviewPath('expat-health-eu', 'lv'),
      id: 'policies-expat-health-eu-lv',
    },
  ],
};

export const mcPoliciesLinks: NavigationLink = {
  name: 'Policies',
  icon: BriefcaseIcon,
  id: 'policies-mc',
  children: [
    {
      name: 'Expat health',
      href: retrievePoliciesOverviewPath('expat-health-eu', 'mc'),
      id: 'policies-expat-health-eu-mc',
    },
  ],
};

export const regionPolicyLinksMapper: Record<Country, NavigationLink> = {
  at: atPoliciesLinks,
  de: germanyPoliciesLinks,
  es: spainPoliciesLinks,
  fr: francePoliciesLinks,
  eu: euPoliciesLinks,
  be: bePoliciesLinks,
  hr: hrPoliciesLinks,
  cy: cyPoliciesLinks,
  ee: eePoliciesLinks,
  fi: fiPoliciesLinks,
  gr: grPoliciesLinks,
  it: itPoliciesLinks,
  lv: lvPoliciesLinks,
  lt: ltPoliciesLinks,
  mt: mtPoliciesLinks,
  mc: mcPoliciesLinks,
  nl: nlPoliciesLinks,
  pt: ptPoliciesLinks,
  se: sePoliciesLinks,
};
