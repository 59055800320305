import { SelectButtonOption } from 'components/SelectButton';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import {
  GenericClaimStatus,
  genericClaimStatuses,
  GenericClaimType,
} from 'shared/claims/generic/models';
import { policyMappers } from 'shared/insurances';

const statusOptions: SelectButtonOption<GenericClaimStatus>[] =
  genericClaimStatuses.map((status) => ({
    id: status,
    label: policyMappers.GENERIC?.claim?.claimStatusMapper[status] ?? '',
    title: policyMappers.GENERIC?.claim?.claimStatusMapper[status] ?? '',
  }));

const claimTypeOptions = (
  claimTypes: GenericClaimType[]
): SelectButtonOption<GenericClaimType>[] => {
  return claimTypes.map((type) => ({
    id: type,
    label: policyMappers.GENERIC?.claim?.typeNameMapper[type] ?? '',
    title: policyMappers.GENERIC?.claim?.typeNameMapper[type] ?? '',
  }));
};

export const filters = (claimTypes: GenericClaimType[]): FilterConfig[] => [
  {
    options: statusOptions,
    filterType: 'MULTIPLE',
    label: 'Status',
    id: 'filterStatus',
    placeholder: 'Status',
  },
  {
    options: claimTypeOptions(claimTypes),
    filterType: 'MULTIPLE',
    label: 'Claim type',
    id: 'filterClaimType',
    placeholder: 'Claim type',
  },
  {
    filterType: 'DATE_RANGE',
    label: 'Created on',
    id: 'createdAt',
  },
];
