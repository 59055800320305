import Page404 from 'pages/errors/404';
import { useParams } from 'react-router-dom';
import { Country } from 'shared/countries/models';

import { ClaimsOverview } from './components/ClaimsOverview';
import { claimsTemplateDataMapper } from './data';
import { InsurancePathName } from './models';
import { getInsurancePathByCountry, isInsurancePathName } from './utils/utils';

export const ClaimsTemplate = () => {
  const { insurancePathName, country } = useParams<{
    insurancePathName: InsurancePathName;
    country: Country;
  }>();
  if (!isInsurancePathName(insurancePathName)) {
    return <Page404 />;
  }

  if (getInsurancePathByCountry()[insurancePathName] !== country) {
    return <Page404 />;
  }

  const claimsData = claimsTemplateDataMapper()[insurancePathName];

  return (
    <ClaimsOverview data={claimsData} insurancePathName={insurancePathName} />
  );
};
