import { RowConfig } from 'components/TableRefactor/types';
import { BikeClaim } from 'pages/claims/bike/models';
import { retrieveReadableClaimTypes } from 'pages/claims/template/components/ClaimsDetails/utils';
import { statusMapping } from 'pages/claims/template/utils/utils';
import { claimDetailsRouteMapper } from 'shared/claims/claimRouteMapper';
import { policyMappers } from 'shared/insurances';

const statusMapper = policyMappers.BIKE?.claim?.claimStatusMapper;
const badgeColorMapper = policyMappers.BIKE?.claim?.badgeColorMapper;
const typeNameMapper = policyMappers.BIKE?.claim?.typeNameMapper;

export const rowConfig: RowConfig = {
  getResourceLink: (data: BikeClaim) =>
    claimDetailsRouteMapper.BIKE(data.id) ?? '',
  cells: [
    {
      type: 'TEXT',
      textColor: 'dark',
      props: { getText: (data: BikeClaim) => data.user.firstName ?? '' },
    },
    {
      type: 'TEXT',
      textColor: 'dark',
      props: { getText: (data: BikeClaim) => data.user.lastName ?? '' },
    },
    {
      type: 'STATUS',
      textColor: 'dark',
      props: {
        getStatus: ({ status }: BikeClaim) =>
          statusMapping(
            status && statusMapper ? statusMapper[status] : '',
            status && badgeColorMapper ? badgeColorMapper[status] : 'blue'
          ),
      },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: {
        getText: (data: BikeClaim) =>
          data.claimType?.length
            ? retrieveReadableClaimTypes(
                data.claimType.length,
                typeNameMapper ? typeNameMapper[data.claimType[0].type] : ''
              )
            : '',
      },
    },
    {
      type: 'DATE',
      textColor: 'light',
      props: { getDate: (data: BikeClaim) => data.createdAt },
    },
    {
      type: 'TEXT',
      textColor: 'light',
      props: { getText: (data: BikeClaim) => data.claimNumber ?? '' },
    },
  ],
};
