import { CurrencyInformation } from 'components/InformationCard/CurrencyInformation';
import DateInformation from 'components/InformationCard/DateInformation';
import LinkInformation from 'components/InformationCard/LinkInformation';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import TextWithDetailsInformation from 'components/InformationCard/TextWithDetailsInformation';
import { retrieveClaimsOverviewPath } from 'pages/claims/template/utils/utils';
import ChangeAccount from 'pages/policies/template/ChangeAccount/ChangeAccount';
import {
  InformationCardDetails,
  InformationCardEditableDetails,
} from 'pages/policies/template/models/PolicyTemplateData';
import { UPDATE_POLICY_CANCELATION_REQUEST } from 'pages/policies/template/mutations';
import { dentalPlanMapper } from 'shared/insurances/planMappers/dental';
import {
  dentalBadgeColorMapper,
  dentalStatusMapper,
} from 'shared/insurances/statusMappers/dental';
import { DentalStatus } from 'shared/insurances/types';
import { setQueryToUrl } from 'shared/utils/setQueryToUrl';

import { GET_DENTAL_POLICY } from '../graphql/singlePolicy';
import { UPDATE_DENTAL_POLICY } from '../graphql/updatePolicy';
import { DentalPolicyData } from '../models/DentalPolicy';

const getInsuredPersonInfo = (
  data?: DentalPolicyData
): InformationCardDetails => ({
  editable: false,
  cards: [
    {
      title: 'Insured person',
      rows: [
        {
          id: 'firstName',
          title: 'First name',
          data:
            data?.dentalPolicy?.insuredPerson?.firstName ??
            data?.dentalPolicy?.user?.firstName ??
            '',
          component: TextInformation,
          type: 'TEXT',
        },
        {
          id: 'lastName',
          title: 'Last name',
          data:
            data?.dentalPolicy?.insuredPerson?.lastName ??
            data?.dentalPolicy?.user?.lastName ??
            '',
          component: TextInformation,
          type: 'TEXT',
        },
        {
          id: 'dateOfBirth',
          title: 'Date of birth',
          data: data?.dentalPolicy?.insuredPerson?.dateOfBirth ?? '',
          component: TextInformation,
          type: 'TEXT',
        },
        {
          id: 'gender',
          title: 'Gender',
          data: data?.dentalPolicy?.insuredPerson?.gender ?? '',
          component: TextInformation,
          type: 'TEXT',
        },
        {
          id: 'email',
          title: 'Email',
          data: data?.dentalPolicy?.user?.email ?? '',
          component: TextWithDetailsInformation,
          type: 'TEXT_WITH_DETAILS',
          detailsTitle: 'Change account',
          detailsLabel: 'Change account',
          renderModalDetails: (setOpen, modalOpen) => (
            <ChangeAccount
              policyDetailsMutation={GET_DENTAL_POLICY}
              setOpen={setOpen}
              modalOpen={modalOpen}
            />
          ),
        },
      ],
    },
  ],
});

const policyInformation = (
  data?: DentalPolicyData
): InformationCardEditableDetails<DentalPolicyData> => {
  const policy = data?.dentalPolicy;

  return {
    editable: true,
    mutation: UPDATE_DENTAL_POLICY,
    resourceId: policy?.id ?? '',
    editabledData: {
      policyNumber: policy?.policyNumber ?? '',
      price: policy?.price ?? 0,
      startDate: policy?.startDate ?? '',
      activeUntil: policy?.activeUntil ?? '',
      paymentReceivedAt: policy?.paymentReceivedAt ?? '',
      source: policy?.source ?? '',
      campaign: policy?.campaign ?? '',
      medium: policy?.medium ?? '',
      content: policy?.content ?? '',
      term: policy?.term ?? '',
    },
    cards: [
      {
        title: 'Policy information',
        rows: [
          {
            id: 'policyNumber',
            title: 'Policy number',
            data: policy?.policyNumber ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter policy number',
          },
          {
            id: 'providerId',
            title: 'Provider',
            data: policy?.providerId ?? '',
            component: TextInformation,
            type: 'TEXT',
          },
          {
            id: 'status',
            title: 'Status',
            data: policy?.publicStatus ?? '',
            type: 'STATUS',
            component: StatusInformation,
            statusMapping: (status: string) => {
              return {
                text: {
                  id: status,
                  text: dentalStatusMapper[status as DentalStatus] ?? '',
                },
                color: dentalBadgeColorMapper[status as DentalStatus] ?? 'gray',
              };
            },
          },
          {
            id: 'startDate',
            title: 'Start date',
            data: policy?.startDate ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'activeUntil',
            title: 'Active until',
            data: policy?.activeUntil ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'endDate',
            title: 'End date',
            data: policy?.endDate ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: false,
          },
          {
            id: 'plan',
            title: 'Plan',
            data: policy?.plan ? dentalPlanMapper[policy.plan] : '',
            component: TextInformation,
            type: 'TEXT',
          },
          ...(policy?.plan === 'ADVANCED'
            ? [
                {
                  id: 'toothReplacementCoverage',
                  title: 'Tooth replacement coverage',
                  data: policy?.quote?.toothReplacementCoverage ?? '',
                  component: TextInformation,
                  type: 'TEXT' as const,
                },
              ]
            : []),
          {
            id: 'price',
            title: 'Price',
            data: policy?.price ?? 0,
            component: CurrencyInformation,
            type: 'CURRENCY',
            editable: true,
            placeholder: '€ 0.00',
          },
          {
            id: 'paymentReceivedAt',
            title: 'Payment received on',
            data: policy?.createdAt ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          ...(policy?.numberOfClaims && policy.numberOfClaims > 0
            ? [
                {
                  id: 'claims',
                  title: 'Claims',
                  data: `${policy?.numberOfClaims} claims`,
                  href: setQueryToUrl(retrieveClaimsOverviewPath('dental'), [
                    { key: 'search', value: policy?.policyNumber ?? '' },
                  ]),
                  externalLink: true,
                  type: 'LINK' as const,
                  component: LinkInformation,
                },
              ]
            : [
                {
                  id: 'claims',
                  title: 'Claims',
                  data: '',
                  type: 'TEXT' as const,
                  component: LinkInformation,
                },
              ]),
          {
            id: 'createdAt',
            title: 'Created on',
            data: policy?.createdAt ?? '',
            component: DateInformation,
            type: 'DATE',
          },
          {
            id: 'source',
            title: 'Source',
            data: policy?.source ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter source',
          },
          {
            id: 'campaign',
            title: 'Campaign',
            data: policy?.campaign ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter campaign',
          },
          {
            id: 'medium',
            title: 'Medium',
            data: policy?.medium ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter medium',
          },
          {
            id: 'content',
            title: 'Content',
            type: 'TEXT',
            component: TextInformation,
            data: policy?.content ?? '',
            editable: true,
            placeholder: 'Enter content',
          },
          {
            id: 'term',
            title: 'Term',
            data: policy?.term ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter term',
          },
        ],
      },
    ],
  };
};

// TODO: [KONG] Move inside template
const policyCancellationInformation = (
  data?: DentalPolicyData
): InformationCardEditableDetails<DentalPolicyData> => {
  const policy = data?.dentalPolicy;

  return {
    editable: true,
    mutation: UPDATE_POLICY_CANCELATION_REQUEST,
    resourceId: policy?.policyCancelationRequest?.id ?? '',
    editabledData: {
      activeUntil: policy?.activeUntil ?? '',
      createdAt: policy?.policyCancelationRequest?.createdAt ?? '',
      canceledOnStripeAt:
        policy?.policyCancelationRequest?.canceledOnStripeAt ?? '',
      requestSentToProviderAt:
        policy?.policyCancelationRequest?.requestSentToProviderAt ?? '',
      cancelationConfirmedByProviderAt:
        policy?.policyCancelationRequest?.cancelationConfirmedByProviderAt ??
        '',
    },
    extraVariables: {
      insuranceType: 'DENTAL',
      policyId: policy?.id ?? '',
    },
    cards: [
      {
        title: 'Policy cancellation',
        rows: [
          {
            id: 'activeUntil',
            title: 'Active until',
            data: policy?.activeUntil ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'createdAt',
            title: 'Requested by customer on',
            data: policy?.policyCancelationRequest?.createdAt ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'canceledOnStripeAt',
            title: 'Stripe canceled on',
            data: policy?.policyCancelationRequest?.canceledOnStripeAt ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'requestSentToProviderAt',
            title: 'Request sent to provider on',
            data:
              policy?.policyCancelationRequest?.requestSentToProviderAt ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'cancelationConfirmedByProviderAt',
            title: 'Provider confirmed on',
            data:
              policy?.policyCancelationRequest
                ?.cancelationConfirmedByProviderAt ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
        ],
      },
    ],
  };
};

export const getOverviewTabCards = (
  data?: DentalPolicyData
): (
  | InformationCardDetails
  | InformationCardEditableDetails<DentalPolicyData>
)[] => [
  getInsuredPersonInfo(data),
  policyInformation(data),
  ...(data?.dentalPolicy.publicStatus === 'CANCELED' ||
  data?.dentalPolicy.publicStatus === 'DROPPED_OUT'
    ? [policyCancellationInformation(data)]
    : []),
];
