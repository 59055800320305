import { useMutation } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import FileUpload from 'components/FileUpload';
import { SelectMenuOption } from 'components/SelectMenu';
import { DocumentNode } from 'graphql';
import { useState } from 'react';
import { alertBanner } from 'shared/reactiveVariables';

import DocumentsCardView, { DeletableDocument } from './DocumentsCardBase';

export interface Document<DocumentType> {
  id?: string;
  fileName: string;
  fileLink?: string;
  resizedUrl?: string;
  blobName?: string;
  isRegeneratable?: boolean;
  canDelete?: boolean;
  documentType?: DocumentType;
  disabled?: boolean;
}

export interface DocumentsSectionData<DocumentType = void> {
  documents: Document<DocumentType>[];
  title: string;
}

/**
 * TODO: [KONG] Update field names for non-policy pages
 */
interface DocumentsCardProps<DocumentType> {
  sections: DocumentsSectionData<DocumentType>[];
  fileTypeOptions: SelectMenuOption[];
  mutation: DocumentNode;
  // TODO: [KONG] Rename prop to refetchQueries
  getPolicyMutation: DocumentNode;
  policyId?: string;
  extraVariables?: Record<string, string>;
  deleteMutation?: DocumentNode;
  regenerateMutation?: DocumentNode;
}

const DocumentsCard = <DocumentType extends string | void>({
  sections,
  fileTypeOptions,
  mutation,
  policyId,
  getPolicyMutation,
  extraVariables,
  deleteMutation,
  regenerateMutation,
}: DocumentsCardProps<DocumentType>) => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [deletableFile, setDeletableFile] = useState<
    Partial<DeletableDocument<DocumentType>>
  >({});

  const onDeleteComplete = () => {
    setOpenModal(false);

    alertBanner({
      type: 'SUCCESS',
      message: t(
        'alertBanner.document.delete.success.message',
        'Document successfully deleted'
      ),
    });
  };

  const onRegenerateComplete = () => {
    alertBanner({
      type: 'SUCCESS',
      message: t(
        'alertBanner.document.regenerate.success.message',
        `Regenerating the document in the background. It may take a few seconds.`
      ),
    });
  };

  /**
   * TODO: [KONG] Remove fallback mutation from hooks and make this mandatory
   * This is a temporary solution to not having a conditional hooks
   * This will be resolved once all features have a delete mutation
   */

  const [deleteFile, { loading }] = useMutation(deleteMutation ?? mutation, {
    variables: {
      ...deletableFile,
      ...(extraVariables ?? {}),
    },
    refetchQueries: [getPolicyMutation],
    onCompleted: onDeleteComplete,
  });

  const [regenerateFile] = useMutation(regenerateMutation ?? mutation, {
    variables: {
      policyId,
    },
    refetchQueries: [getPolicyMutation],
    onCompleted: onRegenerateComplete,
  });

  const handleRegenerateFile = (documentType?: string) => {
    regenerateFile({
      variables: {
        policyId,
        ...(documentType && { type: documentType }),
      },
    });
  };

  const fileUploadComponent = mutation && (
    <FileUpload
      fileTypeOptions={fileTypeOptions}
      mutation={mutation}
      policyId={policyId}
      getPolicyMutation={getPolicyMutation}
      extraVariables={extraVariables}
    />
  );

  return (
    <DocumentsCardView
      sections={sections}
      policyId={policyId}
      deleteMutation={deleteMutation}
      regenerateMutation={regenerateMutation}
      deleteFile={deleteFile}
      loadingMutation={loading}
      deletableFile={deletableFile}
      setDeletableFile={setDeletableFile}
      fileUploadComponent={fileUploadComponent}
      handleRegenerateFile={handleRegenerateFile}
      openModal={openModal}
      setOpenModal={setOpenModal}
    />
  );
};

export default DocumentsCard;
