import { useMutation } from '@apollo/client';
import Button from 'components/Button';
import FileDropzone from 'components/FileDropzone';
import FileErrorCard from 'components/FileErrorCard';
import { FormInputLabel } from 'components/FormInputLabel';
import Input from 'components/Input';
import Modal from 'components/Modal';
import RadioButton from 'components/RadioButton';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import TextArea from 'components/TextArea';
import { DocumentNode } from 'graphql';
import { FormEvent, useState } from 'react';
import { requiredMessage } from 'shared/errorHandling/constants';
import { retrieveZodErrorMessages } from 'shared/errorHandling/retrieveZodErrorMessages';
import { IssueCategoryCombined } from 'shared/issues/shared/models';
import { alertBanner } from 'shared/reactiveVariables';
import { z } from 'zod';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;

  createIssueMutation: DocumentNode;

  policyOrClaimId?: string;

  issueCategories: IssueCategoryCombined[];
  issueCategoryMapper: Record<IssueCategoryCombined, string>;

  refetchQueries?: DocumentNode[];
}

interface Answers<IssueCategory> {
  category: IssueCategory;
  title?: string;
  description: string;
  document?: File;
  documentName?: string;
}

export const CreateIssueModal = ({
  isOpen,
  setIsOpen,
  createIssueMutation,
  policyOrClaimId,
  issueCategories,
  issueCategoryMapper,
  refetchQueries,
}: Props) => {
  const [answers, setAnswers] = useState<
    Partial<Answers<IssueCategoryCombined>>
  >({});
  const [shouldUploadDocument, setShouldUploadDocument] = useState<
    'YES' | 'NO'
  >('NO');
  const [errors, setErrors] = useState<
    Partial<Record<keyof Answers<IssueCategoryCombined>, string>>
  >({});

  const [createIssue, { loading: isIssueBeingSubmitted }] = useMutation(
    createIssueMutation,
    {
      refetchQueries,

      onCompleted: () => {
        onReset();
        alertBanner({
          type: 'SUCCESS',
          message: 'Issue successfully created.',
        });
      },

      onError: () => {
        alertBanner({
          type: 'WARNING',
          message: 'Something went wrong. Please try again.',
        });
      },
    }
  );

  const onReset = () => {
    setAnswers({});
    setErrors({});
    setIsOpen(false);
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setErrors({});

    const zodSchemaWithRequiredDocument = z.object({
      category: z.enum(issueCategories as [string, ...string[]]),
      title: z.string().optional(),
      description: z.string().min(1, { message: requiredMessage() }),
      document: z.instanceof(File),
      documentName: z.string().optional(),
    });

    const zodSchemaWithOptionalDocument = zodSchemaWithRequiredDocument.extend({
      document: z.instanceof(File).optional(),
    });

    const zodSchema =
      shouldUploadDocument === 'YES'
        ? zodSchemaWithRequiredDocument
        : zodSchemaWithOptionalDocument;

    const validation = await zodSchema.safeParseAsync(answers);

    if (!validation.success) {
      const errors = retrieveZodErrorMessages(validation.error);
      setErrors(errors);

      return;
    }

    await createIssue({
      variables: {
        ...validation.data,
        policyOrClaimId,
      },
    });
  };

  const categoryOptions = issueCategories.map((category) => ({
    id: category,
    label: issueCategoryMapper[category],
  }));

  return (
    <Modal
      hideActions
      open={isOpen}
      title="Create issue"
      setOpen={setIsOpen}
      scrollable
    >
      <form onSubmit={onSubmit}>
        {/* Category */}
        <div className="mt-[16px]">
          <FormInputLabel title="Type" />
          <div className="mt-[8px]">
            <SelectMenu<IssueCategoryCombined>
              selected={categoryOptions.find(
                (option) => option.id === answers.category
              )}
              color="gray"
              options={categoryOptions}
              placeholder="Select a type"
              setSelected={(
                option: SelectMenuOption<IssueCategoryCombined>
              ) => {
                setAnswers({
                  ...answers,
                  category: option.id,
                });
              }}
              error={!!errors.category}
              errorMessage={errors.category}
            />
          </div>
        </div>

        {/* Title */}
        <div className="mt-[24px]">
          <FormInputLabel title="Title" optional />
          <div className="mt-[8px]">
            <Input
              color="gray"
              placeholder="Enter issue title"
              value={answers.title ?? ''}
              onChange={(e) => {
                setAnswers({ ...answers, title: e.target.value });
              }}
              error={!!errors.title}
              errorMessage={errors.title}
            />
          </div>
        </div>

        {/* Description */}
        <div className="mt-[24px]">
          <FormInputLabel title="Description" />
          <div className="mt-[8px]">
            <TextArea
              className="h-[60px] max-h-[120px] min-h-[60px]"
              value={answers?.description ?? ''}
              onChange={(e) => {
                setAnswers({ ...answers, description: e.target.value });
              }}
              color="gray"
              placeholder="Enter issue description"
              error={!!errors.description}
              errorMessage={errors.description}
            />
          </div>
        </div>

        <div className="mt-[24px] h-[1px] w-full bg-gray-300" />

        {/* Upload document question */}
        <div className="mt-[24px]">
          <FormInputLabel title="Upload a document?" />
          <div className="mt-[8px] flex items-center space-x-[32px]">
            <RadioButton
              value="YES"
              id="YES"
              name="YES"
              onChange={() => {
                setShouldUploadDocument('YES');
              }}
              checked={shouldUploadDocument === 'YES'}
              error={false}
            >
              Yes
            </RadioButton>
            <RadioButton
              value="NO"
              id="NO"
              name="NO"
              onChange={() => {
                setShouldUploadDocument('NO');
              }}
              checked={shouldUploadDocument === 'NO'}
              error={false}
            >
              No
            </RadioButton>
          </div>
        </div>

        {/* Document upload */}
        {shouldUploadDocument === 'YES' && (
          <div className="mt-[24px]">
            <FormInputLabel title="Document name" optional />
            <div className="mt-[8px]">
              <Input
                value={answers?.documentName ?? ''}
                onChange={(e) => {
                  setAnswers({ ...answers, documentName: e.target.value });
                }}
                color="gray"
                placeholder="Enter document name"
                error={!!errors.documentName}
                errorMessage={errors.documentName}
              />
              <div className="mt-[8px]">
                <FileDropzone
                  uploadedFile={answers.document}
                  onFileSelect={(file) => {
                    setAnswers({ ...answers, document: file });
                  }}
                />
                {errors.document && (
                  <FileErrorCard
                    open={!!errors.document}
                    title="Upload failed. Please try again."
                    handleClose={() => {}}
                    errorType="ERROR"
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <div className="mt-[24px] flex justify-end">
          <Button
            className="w-[80px]"
            buttonType="secondary"
            type="button"
            onClick={onReset}
          >
            Cancel
          </Button>
          <Button
            className="ml-[8px] w-[140px]"
            buttonType="primary"
            type="submit"
            loading={isIssueBeingSubmitted}
          >
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};
