import DocumentsCard, { DocumentsSectionData } from 'components/DocumentsCard';
import { SelectMenuOption } from 'components/SelectMenu';
import { DocumentNode } from 'graphql';
import { ClaimDocuments, ClaimFileType } from 'models/Claim';
import { Document } from 'models/Document';
import { retrieveFileFriendlyName } from 'shared/utils/retrieveFileFriendlyName';

import {
  DELETE_CLAIM_DOCUMENT,
  UPLOAD_CLAIM_DOCUMENT,
} from './graphql/mutations';

interface DocumentsSectionProps {
  claimId: string;
  userId: string;
  documents: ClaimDocuments;
  refetchQueries: DocumentNode;
}

const mapDocuments =
  (documentType: ClaimFileType) =>
  ({ url, blobName, resizedUrl, id }: Document) => ({
    fileName: retrieveFileFriendlyName(blobName),
    fileLink: url,
    resizedUrl,
    id,
    blobName,
    documentType,
    canDelete: documentType !== 'SCANNED_DOCUMENT',
  });

export const Documents = ({
  claimId,
  userId,
  documents,
  refetchQueries,
}: DocumentsSectionProps) => {
  const attachments = documents.attachments.map(
    mapDocuments('CLAIM_ATTACHMENTS')
  );
  const customerUploads = documents.userUploads.map(
    mapDocuments('CUSTOMER_UPLOADS')
  );
  const providerAttachments = documents.providerAttachments.map(
    mapDocuments('PROVIDER_UPLOADS')
  );
  const scannedDocuments = documents.scannedDocuments.map(
    mapDocuments('SCANNED_DOCUMENT')
  );
  const internalDocuments = documents.internalDocuments.map(
    mapDocuments('INTERNAL_DOCUMENT')
  );

  const mappedDocuments: DocumentsSectionData<ClaimFileType>[] = [
    {
      title: 'Customer uploads',
      documents: customerUploads,
    },
    {
      title: 'Provider uploads',
      documents: providerAttachments,
    },
    {
      title: 'Claim attachments',
      documents: attachments,
    },
    {
      title: 'Scanned documents',
      documents: scannedDocuments,
    },
    {
      title: 'Internal documents',
      documents: internalDocuments,
    },
  ];

  const fileTypeOptions: SelectMenuOption<ClaimFileType>[] = [
    {
      id: 'CUSTOMER_UPLOADS',
      label: 'Customer uploads',
    },
    {
      id: 'PROVIDER_UPLOADS',
      label: 'Provider uploads',
    },
    {
      id: 'CLAIM_ATTACHMENTS',
      label: 'Claim attachments',
    },
    {
      id: 'INTERNAL_DOCUMENT',
      label: 'Internal documents',
    },
  ];

  return (
    <DocumentsCard
      sections={mappedDocuments}
      fileTypeOptions={fileTypeOptions}
      mutation={UPLOAD_CLAIM_DOCUMENT}
      getPolicyMutation={refetchQueries}
      extraVariables={{ claimId, userId }}
      deleteMutation={DELETE_CLAIM_DOCUMENT}
    />
  );
};
