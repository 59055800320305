import { useQuery } from '@apollo/client';
import { capitalizeName } from '@getpopsure/public-utility';
import { BriefcaseIcon, InboxInIcon, UserIcon } from '@heroicons/react/solid';
import { useFlag } from '@unleash/proxy-client-react';
import { ActivityLog } from 'components/ActivityLog/ActivityLog';
import { DetailsPage } from 'components/DetailsPage';
import {
  ExternalLink,
  HeaderInfoText,
  InternalLink,
} from 'components/DetailsPage/models';
import { NotesTab } from 'components/Notes/NotesTab';
import { TabInformation } from 'components/Tabs';
import { PrivateHealthPolicy } from 'models/privateHealthPolicy';
import { generatePath, RouteComponentProps } from 'react-router';
import { paths } from 'routes/definedPaths';
import { privateHealthIssueCategoryMapper } from 'shared/issues/privateHealth/issueCategoryMapper';
import { privateHealthIssueCategories } from 'shared/issues/privateHealth/models';
import { mapUserOccupation } from 'shared/mapUserOccupation';
import { getIntercomUrl } from 'shared/utils/getIntercomUrl';
import { getStripeUrl } from 'shared/utils/getStripeUrl';
import { shouldDisplayIssueActivityDot } from 'shared/utils/shouldDisplayIssueActivityDot';

import { IssuesTab } from '../../../../components/Issues/IssuesTab';
import DependentsTab from '../components/DependentsTab';
import OverviewTab from '../components/OverviewTab';
import PolicyActions from '../components/PolicyActions/PolicyActions';
import QuestionnaireTab from '../components/QuestionnaireTab';
import {
  CREATE_PRIVATE_HEALTH_ISSUE,
  RE_OPEN_PRIVATE_HEALTH_ISSUE,
  RESOLVE_PRIVATE_HEALTH_ISSUE,
} from '../graphql/issues';
import { GET_PRIVATE_POLICY } from '../graphql/queries';

type PrivateHealthPolicyPageParams = {
  id: string;
};

interface Data {
  privateHealthPolicy: PrivateHealthPolicy;
}

const InternalPrivateHealthPolicyPage = ({
  match,
}: RouteComponentProps<PrivateHealthPolicyPageParams>) => {
  const isNotesTabVisible = useFlag('admin_panel_notes_v1_policies');

  const { loading, error, data } = useQuery<Data>(GET_PRIVATE_POLICY, {
    variables: {
      id: match.params.id,
    },
  });

  const policy = data?.privateHealthPolicy;

  const hasRelatedScannedDocuments =
    !!policy?.totalRelatedScannedDocumentsCount;
  const numberOfNewRelatedDocs = policy?.newRelatedScannedDocumentsCount;

  const shouldShowActivityDot = shouldDisplayIssueActivityDot(
    data?.privateHealthPolicy.issues
  );

  const tabs: TabInformation[] = [
    {
      name: 'Overview',
      href: '#overview',
      withDot: false,
    },
    {
      name: 'Questionnaire',
      href: '#questionnaire',
      withDot: false,
    },
    {
      name: 'Dependents',
      href: '#dependents',
      withDot: false,
    },
    {
      name: 'Activity',
      href: '#activity',
      withDot: false,
    },
    {
      name: 'Issues',
      href: '#issues',
      withDot: shouldShowActivityDot,
    },
    ...(isNotesTabVisible
      ? [
          {
            name: 'Notes',
            href: '#notes',
            withDot: false,
          },
        ]
      : []),
  ];

  const tabComponents = [
    {
      id: 'overview',
      component: OverviewTab,
      props: { policy },
    },
    {
      id: 'questionnaire',
      component: QuestionnaireTab,
      props: { policy },
    },
    {
      id: 'dependents',
      component: DependentsTab,
      props: { policy },
    },
    {
      id: 'activity',
      component: ActivityLog,
      props: {
        where: [
          { table: 'user_policies', field: 'id', value: policy?.id },
          { table: 'user', field: 'id', value: policy?.user.id },
          ...(policy?.questionnaire?.id
            ? [
                {
                  table: 'questionnaires',
                  field: 'id',
                  value: policy.questionnaire.id,
                },
              ]
            : []),
          {
            table: 'user_policy_issues',
            field: 'user_policy_id',
            value: policy?.id,
            returnFields: ['title', 'category', 'reasonForReopening'],
          },
          ...(policy?.issues
            ? policy.issues.map(({ id }) => ({
                table: 'tasks',
                field: 'user_policy_issue_id',
                value: id,
                returnFields: ['description'],
              }))
            : []),
        ],
      },
    },

    {
      id: 'issues',
      component: IssuesTab,
      props: {
        issues: policy?.issues,
        refetchQueries: [GET_PRIVATE_POLICY],
        issueCategories: privateHealthIssueCategories,
        issueCategoryMapper: privateHealthIssueCategoryMapper,

        createIssueMutation: CREATE_PRIVATE_HEALTH_ISSUE,
        resolveIssueMutation: RESOLVE_PRIVATE_HEALTH_ISSUE,
        reOpenIssueMutation: RE_OPEN_PRIVATE_HEALTH_ISSUE,

        userId: policy?.user.id,
        taskContext: 'Private health',
        insuranceType: 'PRIVATE_HEALTH',
      },
    },

    ...(isNotesTabVisible
      ? [
          {
            id: 'notes',
            component: NotesTab,
            props: {
              type: 'POLICY',
              userOrPolicyOrClaimId: policy?.id,
              refetchQueries: [GET_PRIVATE_POLICY],
            },
          },
        ]
      : []),
  ];

  const externalLinks: ExternalLink[] = [
    {
      title: 'Intercom',
      href: getIntercomUrl(policy?.user.intercomEUId) ?? '',
    },
    {
      title: 'Stripe',
      href: getStripeUrl(policy?.user.intercomEUId) ?? '',
    },
  ];

  const internalLinks: InternalLink[] = [
    {
      title: `Account: ${policy?.user.firstName} ${policy?.user.lastName}`,
      icon: UserIcon,
      href: data
        ? generatePath(paths.customers.details.path, {
            id: data.privateHealthPolicy?.user?.id,
          })
        : '',
    },
    ...(hasRelatedScannedDocuments
      ? [
          {
            title: `Scanned documents${
              numberOfNewRelatedDocs ? ` (${numberOfNewRelatedDocs})` : ''
            }`,
            icon: InboxInIcon,
            href: `${paths.scannedDocuments.path}?offset=0&search=${policy.policyNumber}`,
          },
        ]
      : []),
  ];

  const headerInfos: HeaderInfoText[] = [
    {
      title: mapUserOccupation(policy?.insuredPerson?.occupation ?? ''),
      icon: <BriefcaseIcon />,
    },
  ];

  const name =
    data?.privateHealthPolicy.insuredPerson?.firstName &&
    data?.privateHealthPolicy.insuredPerson.lastName
      ? capitalizeName({
          firstName: data.privateHealthPolicy.insuredPerson.firstName,
          lastName: data.privateHealthPolicy.insuredPerson.lastName,
        }) ?? 'No name'
      : data?.privateHealthPolicy.user.firstName &&
        data.privateHealthPolicy.user.lastName
      ? capitalizeName({
          firstName: data.privateHealthPolicy.user.firstName,
          lastName: data.privateHealthPolicy.user.lastName,
        }) ?? 'No name'
      : 'No name';

  return (
    <DetailsPage
      windowTitle="Private health policy - Feather Admin Panel"
      pageTitle="Private health"
      pageSubtitle={name}
      backLink={{
        text: 'Back',
      }}
      loading={loading}
      tabs={tabs}
      tabComponents={tabComponents}
      internalLinks={internalLinks}
      externalLinks={externalLinks}
      headerInfoTexts={headerInfos}
      error={error}
      headerActions={<PolicyActions policy={policy} />}
      current="policies-private"
    />
  );
};

export default InternalPrivateHealthPolicyPage;
