import { gql } from '@apollo/client';

import { POLICY_ACTIVITY } from './activity';

export const GET_EXPAT_HEALTH_POLICY = gql`
  ${POLICY_ACTIVITY}
  query GetExpatHeathPolicy($id: String!) {
    expatHealthPolicy(id: $id) {
      price
      activeUntilDate
      arrivalDate
      createdAt
      endDate
      id
      insuredPerson {
        occupation
        firstName
        lastName
        gender
        dateOfBirth
      }
      startDate

      campaign
      medium
      content
      term
      publicStatus
      providerId
      policyNumber
      archivedAt
      plan
      numberOfClaims
      waitingPeriod
      user {
        id
        email
        firstName
        lastName
        gender
        dateOfBirth
        stripeToken
        intercomEUId
        isDelinquent
        isFraudulent
        fraudulentAdditionalInfo
        duplicateHealthPolicies {
          providerId
          publicStatus
          id
          insuranceType
          createdAt
        }
        expatHealthPolicies {
          id
          publicStatus
          insuredPerson {
            firstName
            lastName
          }
        }
      }
      ...PolicyActivity
      questionnaire {
        id
        answers
        questionnaireType
      }
      expatHealthDocuments {
        url
        name
        id
        blobName
        isRegeneratable
        resizedUrl
      }
      otherDocuments {
        url
        id
        name
        blobName
        isRegeneratable
        resizedUrl
      }
      questionnaireDownloadDocuments {
        id
        url
        blobName
      }
      cancelationRequest {
        id
        cancelationConfirmedByProviderAt
        canceledOnStripeAt
        createdAt
        reason
      }
      failedPolicyConfirmationEvents {
        id
        status
        providerResponse
        createdAt
      }

      pinnedNotes {
        id
        body
        createdAt
        adminUser {
          firstName
          lastName
        }
        updatedAt
      }
    }
  }
`;
