import { CurrencyInformation } from 'components/InformationCard/CurrencyInformation';
import DateInformation from 'components/InformationCard/DateInformation';
import DropdownInformation from 'components/InformationCard/DropdownInformation';
import StatusInformation from 'components/InformationCard/StatusInformation';
import TextInformation from 'components/InformationCard/TextInformation';
import TextWithDetailsInformation from 'components/InformationCard/TextWithDetailsInformation';
import { SelectMenuOption } from 'components/SelectMenu';
import ChangeAccount from 'pages/policies/template/ChangeAccount/ChangeAccount';
import {
  InformationCardDetails,
  InformationCardEditableDetails,
} from 'pages/policies/template/models/PolicyTemplateData';
import { UPDATE_POLICY_CANCELATION_REQUEST } from 'pages/policies/template/mutations';
import { expatSpainPlanMapper } from 'shared/insurances/planMappers/expatSpain';
import {
  expatSpainBadgeColorMapper,
  expatSpainStatusMapper,
} from 'shared/insurances/statusMappers/expatSpain';
import { ExpatSpainStatus } from 'shared/insurances/types';

import { GET_EXPAT_SPAIN_POLICY } from '../graphql/singlePolicy';
import { UPDATE_EXPAT_SPAIN_POLICY } from '../graphql/updatePolicyInformation';
import {
  expatSpainCancelReasonMapper,
  ExpatSpainPolicyCancelReason,
  expatSpainPolicyCancelReasons,
} from '../models/ExpatSpainCancelation';
import { ExpatSpainPolicyData } from '../models/ExpatSpainPolicy';

const getInsuredPersonInfo = (
  data?: ExpatSpainPolicyData
): InformationCardDetails => ({
  editable: false,
  cards: [
    {
      title: 'Insured person',
      rows: [
        {
          id: 'firstName',
          title: 'First name',
          data: data?.expatSpainPolicy?.insuredPerson?.firstName ?? '',
          component: TextInformation,
          type: 'TEXT',
        },
        {
          id: 'lastName',
          title: 'Last name',
          data: data?.expatSpainPolicy?.insuredPerson?.lastName ?? '',
          component: TextInformation,
          type: 'TEXT',
        },
        {
          id: 'dateOfBirth',
          title: 'Date of birth',
          data: data?.expatSpainPolicy?.insuredPerson?.dateOfBirth ?? '',
          component: TextInformation,
          type: 'TEXT',
        },
        {
          id: 'gender',
          title: 'Gender',
          data: data?.expatSpainPolicy?.insuredPerson?.gender ?? '',
          component: TextInformation,
          type: 'TEXT',
        },
        {
          id: 'email',
          title: 'Email',
          data: data?.expatSpainPolicy?.user?.email ?? '',
          component: TextWithDetailsInformation,
          type: 'TEXT_WITH_DETAILS',
          detailsTitle: 'Change account',
          detailsLabel: 'Change account',
          renderModalDetails: (setOpen, modalOpen) => (
            <ChangeAccount
              modalOpen={modalOpen}
              setOpen={setOpen}
              policyDetailsMutation={GET_EXPAT_SPAIN_POLICY}
            />
          ),
        },
      ],
    },
  ],
});

const policyInformation = (
  data?: ExpatSpainPolicyData
): InformationCardEditableDetails<ExpatSpainPolicyData> => {
  const policy = data?.expatSpainPolicy;

  return {
    editable: true,
    mutation: UPDATE_EXPAT_SPAIN_POLICY,
    resourceId: policy?.id ?? '',
    extraVariables: {
      insuranceType: 'INCOMING_ES',
    },
    editabledData: {
      policyNumber: policy?.policyNumber ?? '',
      price: policy?.price ?? 0,
      startDate: policy?.startDate ?? '',
      arrivalDate: policy?.arrivalDate ?? '',
      source: policy?.source ?? '',
      campaign: policy?.campaign ?? '',
      content: policy?.content ?? '',
      term: policy?.term ?? '',
      medium: policy?.medium ?? '',
    },
    cards: [
      {
        title: 'Policy information',
        rows: [
          {
            id: 'policyNumber',
            title: 'Policy number',
            data: policy?.policyNumber ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter policy number',
          },
          {
            id: 'status',
            title: 'Status',
            data: policy?.publicStatus ?? '',
            type: 'STATUS',
            component: StatusInformation,
            statusMapping: (status: string) => {
              return {
                text: {
                  id: status,
                  text:
                    expatSpainStatusMapper[status as ExpatSpainStatus] ?? '',
                },
                color:
                  expatSpainBadgeColorMapper[status as ExpatSpainStatus] ??
                  'gray',
              };
            },
          },
          {
            id: 'startDate',
            title: 'Start date',
            data: policy?.startDate ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'arrivalDate',
            title: 'Arrival date',
            data: policy?.arrivalDate ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'plan',
            title: 'Plan',
            data: policy?.quote?.plan
              ? expatSpainPlanMapper[policy.quote.plan]
              : '',
            component: TextInformation,
            type: 'TEXT',
          },
          {
            id: 'price',
            title: 'Price',
            data: policy?.price ?? 0,
            component: CurrencyInformation,
            type: 'CURRENCY',
            editable: true,
            placeholder: '€ 0.00',
          },
          {
            id: 'createdAt',
            title: 'Created on',
            data: policy?.createdAt ?? '',
            component: DateInformation,
            type: 'DATE',
          },
          {
            id: 'source',
            title: 'Source',
            data: policy?.source ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter source',
          },
          {
            id: 'campaign',
            title: 'Campaign',
            data: policy?.campaign ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter campaign',
          },
          {
            id: 'medium',
            title: 'Medium',
            data: policy?.medium ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter medium',
          },
          {
            id: 'content',
            title: 'Content',
            type: 'TEXT',
            component: TextInformation,
            data: policy?.content ?? '',
            editable: true,
            placeholder: 'Enter content',
          },
          {
            id: 'term',
            title: 'Term',
            data: policy?.term ?? '',
            component: TextInformation,
            type: 'TEXT',
            editable: true,
            placeholder: 'Enter term',
          },
        ],
      },
    ],
  };
};

const policyCancellationInformation = (
  data?: ExpatSpainPolicyData
): InformationCardEditableDetails<ExpatSpainPolicyData> => {
  const policy = data?.expatSpainPolicy;

  const cancelationReasonOptions: SelectMenuOption<ExpatSpainPolicyCancelReason>[] =
    expatSpainPolicyCancelReasons.map((reason) => ({
      id: reason,
      label: expatSpainCancelReasonMapper[reason],
    }));

  return {
    editable: true,
    mutation: UPDATE_POLICY_CANCELATION_REQUEST,
    resourceId: policy?.policyCancelationRequest?.id ?? '',
    editabledData: {
      activeUntil: policy?.activeUntil ?? '',
      createdAt: policy?.policyCancelationRequest?.createdAt ?? '',
      canceledOnStripeAt:
        policy?.policyCancelationRequest?.canceledOnStripeAt ?? '',
      reason: policy?.policyCancelationRequest?.reason ?? '',
      cancelationConfirmedByProviderAt:
        policy?.policyCancelationRequest?.cancelationConfirmedByProviderAt ??
        '',
    },
    extraVariables: {
      insuranceType: 'INCOMING_ES',
      policyId: policy?.id ?? '',
    },
    cards: [
      {
        title: 'Policy cancellation',
        rows: [
          {
            id: 'activeUntil',
            title: 'Active until',
            data: policy?.activeUntil ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'createdAt',
            title: 'Requested by customer on',
            data: policy?.policyCancelationRequest?.createdAt ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'canceledOnStripeAt',
            title: 'Stripe canceled on',
            data: policy?.policyCancelationRequest?.canceledOnStripeAt ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
          {
            id: 'reason',
            title: 'Cancelation reason',
            data: policy?.policyCancelationRequest?.reason ?? '',
            type: 'DROPDOWN',
            component: DropdownInformation,
            editable: true,
            optionMapping: (option: string) => {
              return (
                cancelationReasonOptions.find((type) => type.id === option)
                  ?.label ?? ''
              );
            },
            dropdownOptions: cancelationReasonOptions,
            multipleOptions: false,
            placeholder: 'Select cancelation reason',
          },
          {
            id: 'cancelationConfirmedByProviderAt',
            title: 'Provider confirmed on',
            data:
              policy?.policyCancelationRequest
                ?.cancelationConfirmedByProviderAt ?? '',
            component: DateInformation,
            type: 'DATE',
            editable: true,
          },
        ],
      },
    ],
  };
};

export const getOverviewTabCards = (
  data?: ExpatSpainPolicyData
): (
  | InformationCardDetails
  | InformationCardEditableDetails<ExpatSpainPolicyData>
)[] => [
  getInsuredPersonInfo(data),
  policyInformation(data),
  ...(data?.expatSpainPolicy.publicStatus === 'CANCELED' ||
  data?.expatSpainPolicy.publicStatus === 'DROPPED_OUT'
    ? [policyCancellationInformation(data)]
    : []),
];
